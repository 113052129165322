import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { component } from 'picoapp';
import calendar from 'calendar-js';
import {
  addDays,
  addHours,
  dateToDmy,
  dmyToDate,
  formatDateForBlvd,
  formatDayForDisplay,
  getDayOfWeek,
  loader,
  scrollUp,
  trackEvent,
  trackHeapEvent,
} from '@/util/utils';
import { getAvailableDates } from '@/util/boulevard';

const getBookingRangeStart = (bookingRangeStart, serviceTypeOverride, bypassRange) => {
  if (bypassRange) {
    return 0;
  }

  if (serviceTypeOverride) {
    return parseInt(serviceTypeOverride?.bookingRangeStart, 10);
  }

  return parseInt(bookingRangeStart, 10) || 72;
};

const getBookingRange = (bookingRange, serviceTypeOverride, bypassRange) => {
  if (bypassRange) {
    return 112;
  }

  if (serviceTypeOverride) {
    return parseInt(serviceTypeOverride?.bookingRange, 10);
  }

  return parseInt(bookingRange, 10);
};

export default component((node, ctx) => {
  const timeTaken = choozy(ctx.getState().node).timeTaken;
  const dateHeader = ctx.getState().node.dataset.dateHeader;
  const timeHeader = ctx.getState().node.dataset.timeHeader;
  const dateImageURL = ctx.getState().node.dataset.dateImageUrl;
  const locationSettings = choozy(ctx.getState().node).locationSetting;
  const enableMobileCalendarToggle = ctx.getState().node.dataset.enableMobileCalendarToggle === 'true';
  const fullMobileCalendar = ctx.getState().node.dataset.fullMobileCalendar === 'true';
  const dateRescheduleHeader = ctx.getState().node.dataset.dateRescheduleHeader;
  const dateRescheduleSubheader = ctx.getState().node.dataset.dateRescheduleSubheader;
  const bypassRange = ctx.getState().node.dataset.bypassRange === 'true';

  let firstAvailableDate, startTime, startDate, endDate, noTime, lastCallEnabled, lastCallVisualEnabled, bookingRangeEnabled, bookingRangeStart, bookingRange, calendarVisible;

  const state = ctx.getState();
  const serviceName = state?.service?.name;
  const tz = state.location.tz;
  const locationSettingEl = locationSettings.find(location => JSON.parse(location.dataset.locationSetting).location.slug === state.location.externalId);
  const locationSetting = JSON.parse(locationSettingEl?.dataset?.locationSetting);
  const studioClosedDays = locationSetting?.studioClosedDays?.map((day) => day.value) || [];
  const discountCalendarKey = locationSetting.discountCalendarKey;
  const discountDays = locationSetting?.discountDays?.map((day) => day.value) || [];
  const discountDayLabel = locationSetting.discountDayLabel;
  const serviceTypeOverride = locationSetting?.serviceTypeBookingRangeOverride?.find((range) => range?.serviceTypes?.find(serviceType => serviceType.label === serviceName));

  noTime = choozy(locationSettingEl).locationSettingNoTime;
  lastCallEnabled = locationSetting.enableLastCall;
  lastCallVisualEnabled = locationSetting.enableLastCallVisual;
  bookingRangeEnabled = locationSetting.enableBookingRange;
  bookingRangeStart = getBookingRangeStart(locationSetting.bookingRangeStart, serviceTypeOverride, bypassRange);
  bookingRange = getBookingRange(locationSetting.bookingRange, serviceTypeOverride, bypassRange);

  if (bypassRange || (bookingRangeEnabled && bookingRange)) {
    const startDateObject = addHours(new Date(), bookingRangeStart).toISOString();
    startTime = startDateObject;
    startDate = startDateObject.slice(0, 10);
    endDate = addDays(new Date(), bookingRange).toISOString().slice(0, 10);
  } else {
    startDate = locationSetting.startDate;
    endDate = locationSetting.endDate;
  }

  const toggleCalendarOpenText = `EXPAND`;
  const toggleCalendarClosedText = `COLLAPSE`;
  calendarVisible = fullMobileCalendar;

  const toggleCalendar = (e) => {
    e.preventDefault();
    trackEvent('booking', `week`, `click mobile calendar toggle`);
    calendarVisible = !calendarVisible;
    ctx.emit('bookingUpdateWeek');
  };
  
  const checkBookingInterval = () => {
    const endDateWeekEnd = dmyToDate(endDate);
    endDateWeekEnd.setDate(endDateWeekEnd.getDate() + (7 - 1));
    const today = new Date();

    today.setHours(23, 59, 59, 0);
    endDateWeekEnd.setHours(23, 59, 59, 0);
    ctx.hydrate({ isBookingOpen: endDateWeekEnd - today > 0 });
  };

  const updateWeekPrevious = async (e) => {
    if (e) e.preventDefault();
    const previousWeek = getPreviousWeek();
    const previousWeekFormatted = formatDateForBlvd(previousWeek);
    trackEvent('booking', `week`, `click week previous`);
    trackHeapEvent(`Clicked - Book-Now - Calendar Week Back Button`);
    await updateDay(previousWeekFormatted);
  };

  const updateWeekNext = async (e) => {
    if (e) e.preventDefault();
    const nextWeek = getNextWeek();
    const nextWeekFormatted = formatDateForBlvd(nextWeek);
    trackEvent('booking', `week`, `click week next`);
    trackHeapEvent(`Clicked - Book-Now - Calendar Week Forward Button`);
    await updateDay(nextWeekFormatted);
  };

  const updateMonthPrevious = async (e) => {
    if (e) e.preventDefault();
    const previousMonth = getPreviousMonth();
    const previousMonthFormatted = dateToDmy(previousMonth);
    trackEvent('booking', `week`, `click month previous`);
    trackHeapEvent(`Clicked - Book-Now - Calendar Month Back Button`);
    await updateDay(previousMonthFormatted);
  };

  const updateMonthNext = async (e) => {
    if (e) e.preventDefault();
    const nextMonth = getNextMonth();
    const nextMonthFormatted = dateToDmy(nextMonth);
    trackEvent('booking', `week`, `click month next`);
    trackHeapEvent(`Clicked - Book-Now - Calendar Month Forward Button`);
    await updateDay(nextMonthFormatted);
  };

  const getCurrentDay = () => {
    const state = ctx.getState();
    return dmyToDate(state.day);
  };

  const getCurrentWeekDays = () => {
    const { day } = ctx.getState();
    const month = new Date(getCurrentDay()).getMonth();
    const year = new Date(getCurrentDay()).getFullYear();
    const { currentWeekDays } = getCalendarForWeek(year, month, day);
    return currentWeekDays;
  };

  const getCurrentWeekEnd = () => {
    return getCurrentWeekDays().pop();
  };

  const getPreviousWeek = () => {
    const currentWeekDays = getCurrentWeekDays();
    const previousWeek = dmyToDate(formatDateForBlvd(currentWeekDays.shift().date));
    previousWeek.setDate(previousWeek.getDate() - 7);
    return previousWeek < new Date(firstAvailableDate) ? new Date(firstAvailableDate) : previousWeek;
  };

  const getNextWeek = () => {
    const currentWeekDays = getCurrentWeekDays();
    const nextWeek = dmyToDate(formatDateForBlvd(currentWeekDays.shift().date));
    nextWeek.setDate(nextWeek.getDate() + 7);
    return nextWeek;
  };

  const getCalendarForWeek = (year, month, day) => {
    const cal = calendar().detailed(year, month);
    const currentWeekIndex = cal.calendar.findIndex(week => {
      return week.find(({weekDay, date}) => {
        const ymdOf = formatDateForBlvd(date);
        return dmyToDate(ymdOf).getTime() === dmyToDate(day).getTime();
      });
    });

    return {
      currentWeekDays: cal.calendar[currentWeekIndex],
      currentMonth: cal.month,
      currentYear: cal.year,
      weekdays: cal.weekdaysAbbr
    };
  };

  const hasPreviousWeek = () => {
    let startDateCompare;
    const currentWeekDays = getCurrentWeekDays();
    const previousWeek = dmyToDate(formatDateForBlvd(currentWeekDays.shift().date));
    if (bookingRangeEnabled) {
      startDateCompare = new Date(firstAvailableDate);
    } else {
      startDateCompare = dmyToDate(startDate);
    }
    return startDateCompare < previousWeek;
  };

  const hasNextWeek = () => {
    const currentWeekDays = getCurrentWeekDays();
    const currentWeekEnd = dmyToDate(formatDateForBlvd(currentWeekDays.pop().date));
    let endDateCompare;
    if (bookingRangeEnabled) {
      endDateCompare = new Date();
      endDateCompare.setDate(new Date().getDate() + bookingRange);
    } else {
      endDateCompare = dmyToDate(endDate);
    }
    return endDateCompare > currentWeekEnd;
  };

  const getPreviousMonth = () => {
    const previousMonth = getCurrentDay();
    previousMonth.setDate(1);
    previousMonth.setMonth(previousMonth.getMonth() - 1);
    const nextAvailableDate = new Date(firstAvailableDate);
    nextAvailableDate.setDate(nextAvailableDate.getDate() + 1);
    return previousMonth.getTime() < nextAvailableDate.getTime() ? nextAvailableDate : previousMonth;
  };

  const getNextMonth = () => {
    const nextMonth = getCurrentDay();
    nextMonth.setDate(1);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    return nextMonth;
  };

  const hasPreviousMonth = () => {
    const currentMonth = getCurrentDay();
    const firstMonth = new Date(firstAvailableDate);
    const previousMonth = getPreviousMonth();
    return (previousMonth.getMonth() >= firstMonth.getMonth()) && (previousMonth.getMonth() < currentMonth.getMonth());
  };

  const hasNextMonth = () => {
    let nextMonth = getNextMonth();
    let endDateCompare;
    if (bookingRangeEnabled) {
      endDateCompare = new Date();
      endDateCompare.setDate(new Date().getDate() + bookingRange);
    } else {
      endDateCompare = dmyToDate(endDate);
    }
    return endDateCompare.getTime() > nextMonth.getTime();
  };

  const getBookingAvailability = async (start, end, limit) => {
    const state = ctx.getState();

    const available = await getAvailableDates(
      state.cartId,
      start,
      end,
      limit,
    );

    return (available?.data?.cartBookableDates || []).map(({ date }) => date).shift();
  };

  const selectDay = async (e) => {
    updateDay(e.target.value);

    trackHeapEvent(`Clicked - Book-Now - Appointment Date Selected`, {
      date: e.target.value,
    });
  };

  const updateDay = async (day, fromBack = false) => {
    const state = ctx.getState();
    state.week = [day];
    state.day = day;
    state.times = null;
    state.reservedTimeFail = false;
    ctx.hydrate(state);
    ctx.emit('bookingUpdateWeek', state);
    scrollUp(node);
    !fromBack && ctx.emit('bookingUpdateViewWeek', ctx.getState(), day);
  };

  const updateTime = () => {
    ctx.emit('bookingUpdateReserve');
    trackEvent('booking', `week`, `click proceed`);
  };

  const isDiscountedDate = (day) => {
    const dayDate = dmyToDate(day);
    const dayName = getDayOfWeek(dayDate);

    return discountDays?.includes(dayName);
  };

  

  const onMount = () => {
    on(choozy(node).selectDay, 'click', selectDay);
    hasPreviousWeek() && on(choozy(node).previousWeek, 'click', updateWeekPrevious);
    hasNextWeek() && on(choozy(node).nextWeek, 'click', updateWeekNext);
    hasPreviousMonth() && on(choozy(node).previousMonth, 'click', updateMonthPrevious);
    hasNextMonth() && on(choozy(node).nextMonth, 'click', updateMonthNext);

    if (enableMobileCalendarToggle) {
      on(choozy(node).calendarToggleEl, 'click', toggleCalendar);
    }

    ctx.emit('bookingResize');
  };

  const renderToggleCalendarButton = () => {
    return `
      <div class="m:dn tc c-white mono ttu pt20 f16">
        <button class="js-calendarToggleEl x tc df aic jcc">
          <span class="mr10">${calendarVisible ? toggleCalendarClosedText : toggleCalendarOpenText}</span>
          <img src="/assets/icons/white-arrow-down.svg" class="ml1 vam ${calendarVisible ? 'r180' : ''}" width="10">
        </button>
      </div>
    `;
  };

  const renderCalendarDiscountKey = () => {
    return `
      <div class="m:dn tc c-black bg-bud ttu pt10 pb10 f16 010em mono">
        <p>${discountCalendarKey}</p>
      </div>
      <div class="dn m:df mt20 aic">
        <div class="booking-discount-key"></div>
        <p class="tc c-white ttu f16 010em mono">${discountCalendarKey}</p>
      </div>
  `;
  };

  const renderLoader = () => {
    node.innerHTML = loader;
    return;
  };

  const render = (state) => {
    const doesHavePreviousWeek = hasPreviousWeek();
    const doesHaveNextWeek = hasNextWeek();
    const doesHavePreviousMonth = hasPreviousMonth();
    const doesHaveNextMonth = hasNextMonth();
    const failed = state.reservedTimeFail ? `<div class="aic ba bc-clay br10 df f14 m:f16 max-w350 mha ph10 pv10 tl"><img src="/assets/icons/sad.svg" class="mr10 vas" style="height: 2em;"/><span>${timeTaken.innerText.trim().length ? timeTaken.innerHTML : `Whoops that spot is no longer available! Please select another date and time.`}</span></div>` : '';

    if (!state.day) return;
    const month = getCurrentDay().getMonth();
    const year = getCurrentDay().getFullYear();
    const calendarForMonth = calendar().detailed(year, month);
    const { currentWeekDays, currentMonth, currentYear, weekdays } = getCalendarForWeek(year, month, state.day);
    const isDiscountDate = isDiscountedDate(state.day);

    if (state.isBookingOpen === false) {
      node.innerHTML = `
        <h3 class="pv35">Sorry no times are available. Please check back again soon.</h3>
      `;
      return;
    }

    node.innerHTML = `
    <div class="m:df mha y x">
      <div class="m:w50 m:y x">
        <div class="rel y x">
          <div class="r z0 x y" style="background: black url('${dateImageURL}') top left / cover no-repeat;">
            <div class="${calendarVisible ? 'dn' : 'm:dn'} pt30 pb20 ph5 z1 mha max-w375 m:pt80">
              <div class="tc c-white mono ttu pb10 f16">
                ${currentMonth} ${currentYear}
              </div>
              <div class="x bg-white df aie pt20 pb10">
                <button class="df jcc aic pv5 w10 r180 ml5 mb10 js-previousWeek ${(doesHavePreviousWeek ? '' : 'o25 pen')}" ${(doesHavePreviousWeek ? '' : 'disabled')}>
                  <img src="/assets/icons/arrow-down.svg" class="rr90" style="height: 01rem;">
                </button>
                <div class="x">
                  <div class="df x aic jca pb5">
                    ${weekdays.map(day => {
                      return `
                              <div class="w14 tc f16 mono fw600 ttu">${day}</div>
                            `;
                    }).join('')}
                  </div>
                  <div class="df x aic jca">
                    ${weekdays.map((day, i) => {
                      const dayYmd = formatDateForBlvd(currentWeekDays[i].date);
                      const dayDate = dmyToDate(dayYmd);
                      const dayName = getDayOfWeek(dayDate);
                      const isDiscountDay = isDiscountedDate(dayYmd);
                      const isActive = dayDate.getTime() === dmyToDate(state.day).getTime();

                      let endDateCompare;
                      if (bookingRangeEnabled) {
                        endDateCompare = new Date();
                        endDateCompare.setDate(new Date().getDate() + bookingRange + 1);
                      } else {
                        endDateCompare = dmyToDate(endDate);
                      }
                      const startDateCompare = new Date().setDate(new Date().getDate() - 1);
                      const disabled = studioClosedDays?.includes(dayName) || dayDate > endDateCompare || dayDate < startDateCompare || dayDate < new Date(firstAvailableDate);
                      return `
                        <div class="w14"><button class="booking-cal-day tc f16 mono pv15 js-selectDay ${isDiscountDay ? 'is-discount-day' : ''} ${isActive ? 'is-active' : ''}" value="${dateToDmy(dayDate)}" ${disabled ? 'disabled' : ''}>${currentWeekDays[i].day}</button></div>
                      `;
                    }).join('')}
                  </div>
                </div>
                <button class="df jcc aic pv5 w10 mr5 mb10 js-nextWeek ${(doesHaveNextWeek ? '' : 'o25')}" ${(doesHaveNextWeek ? '' : 'disabled')}>
                  <img src="/assets/icons/arrow-down.svg" class="rr90" style="height: 01rem;">
                </button>
              </div>
              ${discountDays.length > 0 ? renderCalendarDiscountKey() : ''}
              ${enableMobileCalendarToggle ? renderToggleCalendarButton() : ''}
            </div>
            <div class="${calendarVisible ? 'db' : 'dn m:db'} pt30 pb20 ph5 z1 mha max-w375 m:pt80">
              <h1 class="f24 lh140 light mv20 tl c-white">${dateHeader}</h1>
              <div class="df x aic jca c-white">
                <button class="df jcc aic pv5 w10 r180 ml5 mb10 js-previousMonth ${(doesHavePreviousMonth ? '' : 'o25')}" ${(doesHavePreviousMonth ? '' : 'disabled')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m12 16 4-4m0 0-4-4m4 4H8m14 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"/></svg>
                </button>
                <div class="x tc mono ttu pb10 f16">
                  ${currentMonth} ${currentYear}
                </div>
                <button class="df jcc aic pv5 w10 mr5 mb10 js-nextMonth ${(doesHaveNextMonth ? '' : 'o25')}" ${(doesHaveNextMonth ? '' : 'disabled')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m12 16 4-4m0 0-4-4m4 4H8m14 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"/></svg>
                </button>
              </div>
              <div class="x bg-white df aie pt20 pb10">
                <div class="x">
                  <div class="df x aic jca pb5">
                    ${weekdays.map(day => {
                      return `
                              <div class="w14 tc f16 mono fw600 ttu">${day}</div>
                            `;
                    }).join('')}
                  </div>
                  ${calendarForMonth.calendar.map(week => {
                    return `
                      <div class="df x aic jca">
                        ${week.map((day, i) => {
                          const dayYmd = formatDateForBlvd(day.date);
                          const dayDate = dmyToDate(dayYmd);
                          const dayName = getDayOfWeek(dayDate);
                          const isDiscountDay = isDiscountedDate(dayYmd);
                          const isActive = dayDate.getTime() === dmyToDate(state.day).getTime();

                          let endDateCompare;
                          if (bookingRangeEnabled) {
                            endDateCompare = new Date();
                            endDateCompare.setDate(new Date().getDate() + bookingRange + 1);
                          } else {
                            endDateCompare = dmyToDate(endDate);
                          }
                          const startDateCompare = new Date().setDate(new Date().getDate() - 1);
                          const disabled = studioClosedDays?.includes(dayName) || dayDate > endDateCompare || dayDate < startDateCompare || dayDate < new Date(firstAvailableDate);
                          return `
                                  <div class="w14"><button class="booking-cal-day tc f16 mono pv15 js-selectDay ${isDiscountDay ? 'is-discount-day' : ''} ${isActive ? 'is-active' : ''}" value="${dateToDmy(dayDate)}" ${disabled ? 'disabled' : ''}>${day.day}</button></div>
                                `;
                        }).join('')}
                      </div>
                    `;
                  }).join('')}
                </div>
              </div>
              ${discountDays.length > 0 ? renderCalendarDiscountKey() : ''}
              ${enableMobileCalendarToggle ? renderToggleCalendarButton() : ''}
            </div>
          </div>
        </div>
      </div>
      <div class="m:w50 x rel">
        <div class="max-w400 mha m:pt80">
          ${failed}
          <div class="lh140 light ph20 mv20 tl">
            ${state.originalAppointment ? `
              <h1 class="f24">${dateRescheduleHeader.replace('{date}', formatDayForDisplay(state.day, tz))}</h1>
              <p class="f16 pt10">${dateRescheduleSubheader}</p>
            ` : `
              <h1 class="f24">${timeHeader.replace('{date}', formatDayForDisplay(state.day, tz))}</h1>
            `}
          </div>
          <div class="week mt20 tl ph20 pb40">
            <div class="week-day-block-v2 pb20" data-component="bookingDayV2" data-enable-booking-range="${bookingRangeEnabled}" data-booking-range="${bookingRange}" data-start-time="${startTime}" data-date="${state.day}"  data-start-time="${startTime}" data-end-date="${endDate}" data-enable-last-call="${lastCallEnabled}" data-enable-last-call-visual="${lastCallVisualEnabled}" data-discount-date="${isDiscountDate}" data-discount-day-label="${discountDayLabel}"></div>
            ${noTime?.innerText?.length ? `
              <div class="df aic f14 bg-white ba bw1 p10 mt30">
                <img src="/assets/icons/calendar.svg" class="mr10 vas"/>
                <div class="rte">${noTime.innerHTML}</div>
              </div>
            ` : ``}
          </div>
        </div>
      </div>
    </div>
    `;
    onMount();
  };

  ctx.on('bookingUpdateWeek', (state) => {
    if (state?.day === undefined) {
      renderLoader();
    } else {
      render(state);
    }

    ctx.emit('bookingMountComponents');
  });

  ctx.on('bookingUpdateWeekDay', (state) => {
    updateDay(state.day);
  });

  ctx.on('bookingUpdateWeekByDate', async (state, day) => {
    await day ? updateDay(day, true) : start();
  });

  ctx.on('bookingUpdateWeekTimeUpdate', (state) => {
    updateTime();
  });

  const start = async (overrideDate = null) => {
    let endDateWeekEnd;
    if (bookingRangeEnabled) {
      endDateWeekEnd = new Date();
      endDateWeekEnd.setDate(new Date().getDate() + bookingRange);
    } else {
      endDateWeekEnd = dmyToDate(endDate);
      endDateWeekEnd.setDate(endDateWeekEnd.getDate() + (14 - 1));
    }

    firstAvailableDate = await getBookingAvailability(startDate, formatDateForBlvd(endDateWeekEnd), 1);
    
    if (overrideDate) {
      updateDay(overrideDate);
    } else {
      updateDay(firstAvailableDate);
    }
  };

  const init = async () => {
    const state = ctx.getState();
    checkBookingInterval();
    ctx.emit('bookingUpdateWeek');

    if (state.day) {
      await start(state.day);
    } else {
      await start();
    }
  };

  init();
});
