import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { getPercentInView, loader, scrollPast, trackHeapEvent } from '@/util/utils';

/*
  TODO: Refine and move into README

  ****************************
  *** How To Use Component ***
  ****************************

  *** Callbacks ***
  // Define lottie callbacks object
  if(!window.lottie?.callbacks) window.lottie = {callbacks:{}};
  // Add individual callback
  window.lottie.callbacks['myCallback'] = function(anim, marker) {}

  *** Attributes ***
  <div data-component="lottie"
   data-step-callback="myCallback"
   data-path="/assets/animations/animation.json">
 */
export default component((node) => {
  const lottie = require('lottie-web/build/player/lottie_light.min.js'); // eslint-disable-line global-require
  const { lottiePlayer, lottieLoader, lottieSkip, lottieProgress, lottieProgressInner } = choozy(node);
  const header = document.getElementById('app-header');
  const path = node.getAttribute('data-path');
  const callback = node.getAttribute('data-step-callback');
  const framesPerViewport = node.getAttribute('data-fpv') || '24';
  let currentMarker = -1;

  if (lottieSkip) {
    on(lottieSkip, 'click', () => {
      trackHeapEvent('Skip Link', { page: window.location.pathname });
      scrollPast(node);
    });
  }

  lottie.setQuality('low');
  lottieLoader.innerHTML += `<div class="js-loadingSpinner abs aic df jcc x y top left z0 f28 m:f48">${loader}</div>`;
  const animation = lottie.loadAnimation({
    container: lottiePlayer,
    renderer: 'svg',
    loop: true,
    autoplay: false,
    path
  });

  function resize() {
    if (lottieSkip) {
      const windowHeight = window.visualViewport?.height || 100;
      lottieSkip.style.top = `calc(${windowHeight}${(windowHeight === 100 ? 'vh' : 'px')} - env(safe-area-inset-bottom) - env(safe-area-inset-top) - ${lottieSkip.getBoundingClientRect().height}px)`;
    }
  }

  function step() {
    const scrollPosition = window.scrollY;
    const animationPercent = getPercentInView(node);
    const maxFrames = animation.totalFrames;
    const frame = Math.round((maxFrames / 100) * animationPercent);
    animation.goToAndStop(frame, true);
    if (lottieProgress && lottieProgressInner) {
      if (animationPercent < 5) {
        lottieProgress.style.opacity = `${animationPercent/5}`;
      }
      lottieProgressInner.style.width = `${animationPercent}%`;
    }
    if (window.lottie?.callbacks?.[callback] && typeof window.lottie.callbacks[callback] === 'function') window.lottie.callbacks[callback](animation, animation?.markers?.[currentMarker]?.payload?.name);
  }

  function init() {
    node.classList.add('rel');
    lottieLoader.classList.add('dn');
    lottiePlayer.classList.add('sticky', 'oh');
    lottiePlayer.style.top = `${header.offsetHeight}px`;
    const playerHeight = ((animation.totalFrames / parseInt(framesPerViewport)) * 10);
    node.style.height = `${playerHeight}vh`;
    if (lottieProgress) {
      lottieProgress.style.opacity = `0`;
      lottieProgress.style.top = `${header.offsetHeight}px`;
    }
    step();
    resize();
  }

  animation.addEventListener('enterFrame', function () {
    const pastMarkers = animation.markers.filter((marker, i) => marker.time < animation.currentFrame);
    currentMarker = pastMarkers && pastMarkers.length ? Object.keys(pastMarkers).reduce((a, b) => pastMarkers[a] > pastMarkers[b] ? a : b) : -1;
  });
  document.addEventListener('scroll', step);
  animation.addEventListener('DOMLoaded', init);
  on(window, 'resize', resize);
});