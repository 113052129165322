import Player from '@vimeo/player';
import flickity from 'flickity';
import choozy from 'choozy';
import { component } from 'picoapp';
import { on } from '@selfaware/martha';
import { scrollUp } from '@/util/utils';
import inview from '@/util/inview';

export default component((node, ctx) => {
  const {
    videoCarouselModal,
    videoCarouselModalVideo,
    videoCarouselModalClose,
    videoCarouselModalBg,
    videoCarouselIframe,
    carousel
  } = choozy(node);
  
  const flkty = new flickity(carousel);
  let timeout;

  const loadElements = () => {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    videoCarouselIframe.forEach(item => {
      const isInView = inview(item, (vh + vh/2));
      if(isInView && !item.classList.contains('is-in-view')) {
        const src = item.getAttribute('data-src');
        item.classList.add('is-in-view');
        item.setAttribute('src', src);
      }
    });
  };

  const scroll = () => {
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }
    timeout = window.requestAnimationFrame(loadElements);
  };

  const openModal = (src) => {
    videoCarouselModalVideo.setAttribute('src', src);
    videoCarouselModal.classList.remove('dn');
    scrollUp(videoCarouselModal);
  };

  const closeModal = () => {
    videoCarouselModalVideo.setAttribute('src', '');
    videoCarouselModal.classList.add('dn');
    videoCarouselIframe.forEach(iframe => {
      var player = new Player(iframe);
      player.play();
    });
    flkty.player.play();
  };

  flkty.on('staticClick', (e, p, el) => {
    const { videoCarouselPlay } = choozy(el);
    if (videoCarouselPlay && videoCarouselPlay.getAttribute('data-video')) {
      openModal(videoCarouselPlay.getAttribute('data-video'));
    }
  });

  loadElements();
  on(window, 'scroll', scroll);
  on(videoCarouselModalClose, 'click', closeModal);
  on(videoCarouselModalBg, 'click', closeModal);
});
