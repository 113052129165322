import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { component } from 'picoapp';
import { 
  trackEvent,
  formatAddress,
  trackHeapEvent
} from '@/util/utils';

export default component((node, ctx) => {
  const locations = JSON.parse(ctx.getState().node.dataset.locations) || [];
  const header = ctx.getState().node.dataset.studioHeader;
  const locationsImage = JSON.parse(ctx.getState().node.dataset.locationsImage);

  const selectLocation = (e) => {
    e.preventDefault();
    const locationId = e.target.value;

    const state = ctx.getState();
    const location = state.locations.find(({ node: { id } }) => `${id}` === `${locationId}`);
    state.location = location.node;
    state.services = null; // Reset services when changing locations
    trackEvent('booking', `${state.view}`, `click location ${state.location.name}`);

    // Get services for location or error if none
    if (state.location) {
      state.view = 'group';
    } else {
      state.view = 'error';
    }
    ctx.hydrate(state);
    ctx.emit('bookingUpdateView', state);
    trackEvent('booking', `location`, `click proceed`);
    trackHeapEvent(`Clicked - Book-Now - Studio ${state.location.name}`);
  };

  const onMount = () => {
    on(choozy(node).locationSelector, 'click', selectLocation);
  };

  const render = (state) => {
    const filteredLocations = locations.map(location => {
      const blvdLocationMatch = state.locations.find(
        ({ node: { id } }) => id === location._key
      );
      return !blvdLocationMatch ? null : {
        ...location, 
        ...blvdLocationMatch.node
      };
    });

    node.innerHTML = `
      <div class="df mha y x">
        <div class="pt40 pb40 max-w400 mha m:w50 y x">
          <h1 class="f24 light pb20">${header}</h1>
          <div class="ph20">
            ${filteredLocations.map(location => {
              const address = formatAddress(location?.address);
              return `
                <button class="df jcb aic x mb10 pv20 ph10 tl bg-bud bc-broccolini ba bw1 br5px js-locationSelector" id=${`location${location.id}`} name="locationId" value="${location.id}">
                  <span class="pen">
                    <span class="f18 fw700">${location.name}</span><br />
                    <span class="f14 c-broccolini">${address}</span>
                  </span>
                  <img src="/assets/icons/arrow-next.svg" width="15" class="pen" />
                </button>
              `;
            }).join('')}
          </div>
        </div>
        <div class="dn m:db m:w50 x rel">
          <div class="abs zn1 x y top left bottom right bg-black bl bc-broccolini bw3" style="background: url('${locationsImage?.lqip}') center center / cover no-repeat;"></div>
          <div class="abs z0 x y top left bottom right bg-black bl bc-broccolini bw3" style="background: url('${locationsImage?.url}') center center / cover no-repeat;"></div>
        </div>
      </div>
      `;
    onMount();
  };

  ctx.on('bookingLocationUpdate', (state) => {
    render(state);
    ctx.emit('bookingMountComponents');
    ctx.emit('bookingResize');
  });

  const init = () => {
    ctx.emit('bookingLocationUpdate');
  };

  init();
});