import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { component } from 'picoapp';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
  AsYouType,
  parsePhoneNumberFromString as parsePhoneNumber,
} from 'libphonenumber-js';
import { isValidSession } from '@/components/booking';
import {
  dmyToDate,
  escape,
  formatAddress,
  formatDateForDisplay,
  formatTimeForDisplay,
  getDayOfWeek,
  getPrice,
  getServiceLabel,
  heapIdentifyUser,
  loader,
  sendAbandonCartEmail,
  trackEvent,
  trackHeapEvent,
  trackHeapProperty,
  trackPaidConversion,
  trackTikTokCheckout,
  validateEmail,
} from '@/util/utils';
import {
  getStripeConfigurationData,
  getSecret,
} from '@/util/payments';
import {
  removeStaffVariantFromTimeInCart,
  addInfoToCart,
  checkoutCart,
  addDataToCart,
  getQuestionId,
} from '@/util/boulevard';
import {
  createReservation,
  updateReservation,
} from '@/util/dashboard';
import validate, { isEmpty } from 'validate.js';

const API_URL = process.env.BACKEND_API_URL || 'https://api.ephemeral.tattoo';
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const sessionId = urlParams.get('session_id');
const location = urlParams.get('l');
const source = urlParams.get('utm_source');
const charge = urlParams.get('c') && atob(urlParams.get('c'));
const referrer = urlParams.get('referrer') || sessionStorage.getItem('referrer');

export default component(async (node, ctx) => {
  const state = ctx.getState();
  const locationSettings = choozy(state.node).locationSetting;
  const locationSettingEl = locationSettings.find(location => JSON.parse(location.dataset.locationSetting).location.slug === state.location.externalId);
  const locationSetting = JSON.parse(locationSettingEl?.dataset?.locationSetting);
  const discountDayBookingMessage = choozy(locationSettingEl).locationSettingDiscountDayBookingMessage;
  const discountDays = locationSetting?.discountDays?.map((day) => day.value) || [];
  const hasEmail = node.dataset.hasEmail === 'true';
  const paymentCaptureEnabled = node.dataset.enablePaymentCapture === 'true';
  const finePrint = choozy(ctx.getState().node).finePrint;
  const lastCallEnabled = locationSetting.enableLastCall;
  const lastCallVisualEnabled = locationSetting.enableLastCallVisual;
  const lastCall = choozy(ctx.getState().node).lastCall;
  const lastCallRange = parseInt(lastCall.getAttribute('data-last-call-range')) || 0;
  const confirmationButton = ctx.getState().node.getAttribute('data-confirmation-button');
  const startDate = node.getAttribute('data-start-date');
  const endDate = node.getAttribute('data-end-date');
  const apptId = node.getAttribute('data-start-appt-id');
  const paymentInfoTitle = ctx.getState().node.dataset.paymentInfoTitle || 'Enter Payment information';
  const detailsTitle = ctx.getState().node.dataset.confirmationDetailsTitle || 'APPOINTMENT DETAILS';
  const creditCardRequiredMessage = ctx.getState().node.dataset.creditCardRequiredMessage || 'A credit card is required to book your appointment. Cancellations are free up to X days before your appointment. Cancellations after 7 days incur a $20 fee.';
  const confirmationImage = JSON.parse(ctx.getState().node.dataset.confirmationImage);
  const urgencyMessage = ctx.getState().node.dataset.urgencyMessage;
  const urgencyColor = ctx.getState().node.dataset.urgencyColor;
  const hasUrgency = urgencyMessage?.trim().length;
  const confirmationRescheduleHeader = ctx.getState().node.dataset.confirmationRescheduleHeader;
  const confirmationRescheduleSubheader = ctx.getState().node.dataset.confirmationRescheduleSubheader;
  const confirmationOptInMessage = ctx.getState().node.dataset.confirmationOptInMessage;
  const showOptIn = ctx.getState().node.dataset.confirmationShowOptIn === 'true';
  const optInDefault = ctx.getState().node.dataset.confirmationOptInDefault === 'true';
  let view, stripeElements, stripe, card, paymentRequest, paymentRequestButton, currency, PUBLIC_KEY, errors;

  const isDiscountedDate = (day) => {
    const dayDate = dmyToDate(day);
    const dayName = getDayOfWeek(dayDate);

    return discountDays?.includes(dayName);
  };

  const formValidator = async (form) => {
    let errors;
    const validations = {
      firstName: {
        presence: {
          message: 'First name can’t be blank',
        },
      },
      lastName: {
        presence: {
          message: 'Last name can’t be blank',
        },
      },
      phone: {
        phoneValidator,
      },
    };

    // Validate email for pending reservations
    if (!hasEmail) {
      validate.validators.emailVerification = validateEmail;

      validations['email'] = {
        presence: {
          message: 'E-Mail can’t be blank',
        },
        email: true,
      };
    }

    // Phone validator
    validate.validators.phoneValidator = phoneValidator;

    // Check basic validators
    errors = validate(
      form,
      validations,
      { fullMessages: false },
    );

    // Check email validator for pending reservations
    if (!hasEmail) {
      validate.async.options = { cleanAttributes: false };
      await validate
        .async(form, {
          email: {
            emailVerification: true,
          },
        })
        .then(
          () => {
            // Success
          },
          (res) => {
            // Error
            errors = !errors ? res : Object.assign(errors, res);
          },
        );
    }

    if (!errors || isEmpty(errors)) return false;
    const messages = Object.keys(errors).reduce(
      (acc, current) => [...acc, ...errors[current]],
      [],
    );

    return messages;
  };

  const phoneValidator = (value) => {
    const intlValue =
      value?.length && !~value?.indexOf(value) ? `+1 ${value}` : value;
    let phoneParse = intlValue
      ? parsePhoneNumber(intlValue, {
          defaultCountry: 'US',
        })
      : false;
    intlValue ? (phoneParse = phoneParse.isValid()) : false;
    return phoneParse ? null : 'Phone number is not valid';
  };

  // Confirm appointment
  const updateInfo = async (e) => {
    e.preventDefault();
    const state = ctx.getState();
    const phoneParse = state.phone?.length
      ? parsePhoneNumber(state.phone, {
          defaultCountry: 'US',
        })
      : false;
    const phoneFormatted = phoneParse ? phoneParse.format('E.164') : '';

    view = 'loading';

    // Validate form inputs
    const errors = await formValidator(node);

    // Invalid inputs
    if (errors) {
      state.error = errors.join(', ');
      ctx.emit('bookingButtonUpdate', state);
      trackEvent('booking', `confirmation`, `invalid input`);

    // Valid inputs
    } else {
      trackEvent('booking', `confirmation`, 'click confirm');
      const { rpId, email } = ctx.getState();

      // Save email to pending reservation
      if (!hasEmail) {
        const { rpId, email } = ctx.getState();
        
        await updateReservation(rpId, { email });
      }

      // Pending unpaid reservations
      if (paymentCaptureEnabled) {
        ctx.emit('bookingButtonUpdate', { submitting: true });

        const response = await savePaymentAndBookAppointment(stripe, card);

        // Card saved and Appointment booked
        if (response?.setupIntent?.status === 'succeeded') {
          trackHeapProperty('reservation_id', rpId);
          trackHeapEvent('Purchased Reservation', {
            type: `card`,
            success: true,
          });

          trackAndUpdateReservationWithSetupIntent(response);

          ctx.emit('bookingAppointmentBooked', state);

        // Card failed to save
        } else if (response?.error) {
          trackHeapEvent('Purchased Reservation', {
            type: `card`,
            success: false,
          });
          ctx.emit('bookingButtonUpdate', { submitting: false, disabled: false, error: response.error.message });
          trackEvent('booking', `confirmation`, 'invalid card');
          return;

        // Appointment failed to book
        } else {
          ctx.emit('bookingReservationFailure', state);
          return;

        }

      // Paid reservations
      } else {

        // Re-validate the session
        const session = await isValidSession(sessionId, rpId, apptId);
        if (!session.valid) {
          ctx.emit('bookingUpdateView', { view: 'used' });
          return;
        }

        // Book appointment
        const confirmed = await bookAppointment({
          firstName: state.firstName,
          lastName: state.lastName,
          email: email,
          phone: phoneFormatted
        });
        if (confirmed) {
          ctx.emit('bookingAppointmentBooked', state);
        } else {
          ctx.emit('bookingReservationFailure', state);
        }
      }
    }
  };

  const handleCardComplete = ({ complete, empty, ctx }) => {
    if (complete && !empty) {
      ctx.emit('bookingButtonUpdate', { disabled: false, error: false });
    } else {
      ctx.emit('bookingButtonUpdate', { disabled: true });
    }
    trackHeapEvent('Clicked - Credit Card Field on Booking Page');
  };

  const trackAndUpdateReservationWithSetupIntent = async (response) => {
    const { rpId, email, phone } = ctx.getState();

    const data = await updateReservation(rpId, {
      email: email,
      setup_intent_id: response?.setupIntent?.id,
      phone_number: phone,
    });

    const { client_public_id } = data;

    heapIdentifyUser(client_public_id);

    await trackPaidConversion();
  };

  const updatePhoneInput = (e) => {
    const value = e.target.value;
    const intlValue = !~value.indexOf(value) ? `+1 ${value}` : value;
    const phoneParse = parsePhoneNumber(intlValue, {
      defaultCountry: 'US',
    });

    if ([8, 46].indexOf(e.keyCode) !== -1) return;

    if (phoneParse) {
      const asYouType = new AsYouType(phoneParse.country);
      e.target.value = asYouType.input(value);
    }

    updateInfoInput(e);
  };

  const updateInfoInput = (e) => {
    const state = ctx.getState();
    const name = e.target.getAttribute('name');
    state[name] = e.target.value;
    state.error = false;
    ctx.hydrate(state);
    ctx.emit('bookingButtonUpdate', state);
  };

  const updateOptIn = (e) => {
    const state = ctx.getState();
    const checked = e.target.checked;
    state.optIn = checked;
    ctx.hydrate(state);
    ctx.emit('bookingButtonUpdate', state);
  };

  const goBack = async (e) => {
    e.preventDefault();
    const { cartId, itemId } = ctx.getState();
    await removeStaffVariantFromTimeInCart(cartId, itemId);
    ctx.emit('bookingButtonUpdate', { disabled: false, error: false });
    ctx.emit('bookingUpdateView', { view: 'week', reservedTimeFail: false });
    trackEvent('booking', `confirmation`, `click back`);
  };

  const updateEmail = (e) => {
    const email = e.target.value.trim().toLowerCase();

    if (email) {
      const state = ctx.getState();
      state.email = email;
      ctx.hydrate(state);
      ctx.emit('bookingButtonUpdate', { disabled: false, error: false });
    }
  };

  const captureEmail = async (e) => {
    const email = e.target.value;

    if (email.length) {
      const state = ctx.getState();
      trackEvent('booking', `${state.view}`, `entered email`);
      sendAbandonCartEmail(state.rpId, email, state.location?.externalId, state.service?.name);
    }
  };

  const savePaymentAndBookAppointment = async (stripe, card) => {
    const { firstName, lastName, phone, email } = ctx.getState();

    // Save card
    const save = await stripe.confirmCardSetup(await getSecret(email), {
      payment_method: {
        card,
        billing_details: { name: `${firstName} ${lastName}`, phone },
      },
    });

    if (save.error) return save;

    // Book appointment
    const confirmed = await bookAppointment({
      firstName,
      lastName,
      email,
      phone
    });
    if (!confirmed) return false;

    return save;
  };

  const savePaymentAndBookAppointmentForPaymentRequest = async (
    info,
    stripe,
    paymentMethod,
  ) => {
    // Save card
    const save = await stripe.confirmCardSetup(
      await getSecret(info.email),
      { payment_method: paymentMethod.id },
      { handleActions: false },
    );
    if (save.error) return save;

    // Book appointment
    const nameParts = info.name.includes(' ') ? info.name.split(' ') : [info.name, ' '];
    const firstName = nameParts[0];
    const lastName = nameParts[1];
    const confirmed = await bookAppointment({
      firstName,
      lastName,
      email: info.email,
      phone: info.phone
    });
    if (!confirmed) return false;

    return save;
  };

  const paymentRequestProcess = async (ev) => {
    const state = ctx.getState();
    const { rpId } = ctx.getState();

    const info = {
      email: ev.payerEmail,
      name: ev.payerName,
      phone: ev.payerPhone,
      location: location,
    };

    const paymentMethod = ev.paymentMethod;
    const response = await savePaymentAndBookAppointmentForPaymentRequest(
      info,
      stripe,
      paymentMethod,
    );

    // Card saved and Appointment booked
    if (response?.setupIntent?.status === 'succeeded') {
      const nameParts = ev.payerName.includes(' ') ? ev.payerName.split(' ') : [ev.payerName, ' '];
      const firstName = nameParts[0];
      const lastName = nameParts[1];
      ctx.hydrate({ firstName, lastName, phone: ev.payerPhone, email: ev.payerEmail});

      trackHeapProperty('reservation_id', rpId);
      trackHeapEvent('Purchased Reservation', {
        type: paymentMethod?.card?.wallet?.type,
        success: true,
      });

      trackAndUpdateReservationWithSetupIntent(response);

      ctx.emit('bookingButtonUpdate', { submitting: false });
      ctx.emit('bookingAppointmentBooked', state);
      ev.complete('success');
      return;

    // Card failed to save
    } else if (response?.error) {
      trackHeapEvent('Purchased Reservation', {
        type: paymentMethod?.card?.wallet?.type,
        success: false,
      });
      trackEvent('booking', `confirmation`, 'invalid wallet payment');
      ctx.emit('bookingButtonUpdate', { submitting: false, disabled: false, error: response.error.message });
      ev.complete('fail');
      return;

    // Appointment failed to book
    } else {
      ctx.emit('bookingReservationFailure', state);
      ev.complete('fail');
      return;

    }
  };

  const bookAppointment = async (info) => {
    const { firstName, lastName, email, phone } = info;
    const state = ctx.getState();

    const phoneParse = phone?.length
      ? parsePhoneNumber(phone, {
          defaultCountry: 'US',
        })
      : false;
    const phoneFormatted = phoneParse ? phoneParse.format('E.164') : '';
    state.phone = phoneFormatted;
    let infoToCart = false;

    // Add the customer info, reset to week view if it fails
    try {
      infoToCart = await addInfoToCart(
        state.cartId,
        firstName,
        lastName,
        email,
        phoneFormatted,
      );
    } catch (e) {
      return false;
    }

    if (!infoToCart || infoToCart.error || infoToCart.errors) {
      return false;
    }

    try {
      // Get cart metadata field ID
      const key = 'data';
      const {
        data: {
          cart: { bookingQuestions },
        },
      } = await getQuestionId(state.cartId);

      // Format appointment data
      const questionToSet = bookingQuestions.find((q) => q.key === key);
      const apptData = {
        cart_id: state.cartId,
        booking_range_start: startDate,
        booking_range_end: endDate,
        ...(source && {
          utm_source: source,
        }),
        ...(state.rpId && {
          rp_id: state.rpId,
        }),
        ...(sessionId && {
          session_id: sessionId,
        }),
        ...(charge && {
          charge,
        }),
        ...(referrer && {
          referrer,
        }),
        ...(state.time?.last_call && {
          last_call: state.time?.last_call,
        }),
        ...(state.optIn && {
          opt_in: state.optIn,
        }),
      };
      if (apptId) apptData.reschedule_appointment_id = apptId;

      // Save appointment metadata to the cart
      const data = await addDataToCart(
        state.cartId,
        escape(JSON.stringify(apptData)),
        questionToSet.id,
      );
    } catch (e) {
      return false;
      // throw new Error(e);
    }

    // Book the appointment, reset to week view if it fails
    try {
      const checkout = await checkoutCart(state.cartId);

      if (
        checkout &&
        !checkout.error &&
        !checkout.errors &&
        checkout.data &&
        checkout.data.checkoutCart &&
        checkout.data.checkoutCart.cart &&
        checkout.data.checkoutCart.cart.completedAt
      ) {
        ctx.hydrate({ confirmed: 'Confirmed' });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  const formatUrgencyMessage = (msg, number) => {
    let newMsg = msg.replace('{number}', number);
    if (number > 1) {
      newMsg = newMsg.replace(/{plural:([^}]*?)}/g, newMsg.match(/{plural:(.*?)}/)[1]);
    } else {
      newMsg = newMsg.replace(/{singular:([^}]*?)}/g, newMsg.match(/{singular:(.*?)}/)[1]);
    }
    return newMsg.replace(/{([^}]*)}/g, '');
  };

  const onMount = async (state) => {
    if (paymentCaptureEnabled) {
      ctx.emit('bookingButtonUpdate', { disabled: true });

      // Stripe setup
      if (PUBLIC_KEY && document.querySelector('.card-details-info')) {
        if (!stripe) {
          stripe = await loadStripe(PUBLIC_KEY);

          // CC field setup
          stripeElements = stripe.elements();

          // Apple/Google pay setup
          paymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: currency,
            total: {
              label: 'Deposit',
              amount: 0,
            },
            requestPayerPhone: true,
            requestPayerName: true,
            requestPayerEmail: true,
          });

          card = stripeElements.create('card', { style: {
            base: {
              fontSize: '16px',
              fontSmoothing: 'antialiased',
            },
            invalid: {
              iconColor: '#ff0033',
              color: '#ff0033',
            },
          }});

          paymentRequestButton = stripeElements.create('paymentRequestButton', {
            paymentRequest: paymentRequest,
          });

        } else if (card && paymentRequestButton) {
          card.clear();
          paymentRequestButton.clear();
        }

        paymentRequest.canMakePayment().then(function (result) {
          const paymentRequestButtonEl = document.getElementById('payment-request-button');
          const paymentRequestTitleEl = document.getElementById('payment-request-title');

          if (paymentRequestButtonEl && paymentRequestTitleEl) {
            if (result) {
              paymentRequestButton.mount('#payment-request-button');
            } else {
              document.getElementById('payment-request').classList.add('dn');
              paymentRequestTitleEl.classList.remove('dn');
            }

            // Remove all skeleton loading elements
            const skeletons = document.querySelectorAll('.js-selectInfo .skeleton');
            skeletons.forEach(el => {
              el.classList.remove('skeleton', 'skeleton-divider');
            });
          }
        });

        paymentRequest.on('paymentmethod', paymentRequestProcess);

        card.mount('.card-details-info');
        card.on('change', (data) => handleCardComplete({ ...data, ctx }));
      }
    }
  };

  const render = (state) => {
    if (view === 'loading') {
      node.innerHTML = loader;
      return;
    }

    const date = formatDateForDisplay(state.time.name, state.location.tz);
    const time = formatTimeForDisplay(state.time.name, state.location.tz);
    const service = getServiceLabel(state);
    const price = getPrice(state);
    const location = state.location;
    const today = new Date();
    const isLastCall = (Math.abs(Date.parse(state.time.name)-Date.parse(today.toISOString())) / 36e5) <= lastCallRange && lastCallEnabled;
    const showLastCall = isLastCall && lastCallVisualEnabled;

    const confirmationButtonText = confirmationButton.length ? confirmationButton : 'Book Now';
    const address = formatAddress(location?.address);
    const encodedAddress = `https://www.google.com/maps/search/${encodeURIComponent("Ephemeral Tattoo " + address)}`;
    const hour = today.getHours();
    const isDiscountDay = isDiscountedDate(state.day);

    // Only show urgency between 10am and 11pm
    const urgencyInRange = hour >= 10 && hour <= 23;

    // Don't show any urgency number greater than this
    const urgencyMax = 3;
    
    let number = 0;
    if (hasUrgency && urgencyInRange) {
      const dateObj = new Date(state.time.name);
      // Random calculations on the current time and appointment time to make the urgency look consistent but real
      number = Math.abs(Math.abs(hour-12)-(12/3));
      const numberAdj1 = parseInt(dateObj.getTime().toString().substr(3, 1));
      const numberAdj2 = parseInt(dateObj.getTime().toString().substr(7, 1));
      number = Math.round((number/numberAdj1)*numberAdj2);
      number = Math.min(number, urgencyMax);
    }

    if (hasUrgency && number) {
      trackHeapEvent('Book-Now Urgency Message Shown', {
        number,
      });
    }

    const optInChecked = state.optIn ? 'checked' : '';

    node.innerHTML = `
      <div class="df mha y x">
        <div class="y x m:w50">
          ${hasUrgency && number ? `
            <div class="booking-urgency x tl ph40 pv10 bg-${urgencyColor || 'mustard'}">
              <div class="df aic jcc">
                <img src="/assets/icons/eye.svg" class="mr10 vas" width="40" />
                <span>${formatUrgencyMessage(urgencyMessage, number)}</span>
              </div>
            </div>
          `: ''}
          <form action="/book" class="js-selectInfo max-w400 mha x pt40 mb60 tl ph30">
            <div class="f16 lh140">
              ${state.originalAppointment ? `
                <h2 class="x tc df aic jcc f24 light">${confirmationRescheduleHeader}</h2>
                <p class="f16 pt10">${confirmationRescheduleSubheader}</p>
              ` : `
                <h2 class="x tc df aic jcc f24 light">${detailsTitle}</h2>
              `}
              <div class="mt20">
                <div class="df ais mb15">
                  <img src="/assets/icons/calendar.svg" class="mr10 vas"/>
                  <div>
                    <p class="mb5"><span class="f18 fw900">${date}</span> | <button href="#back" class="js-goBack tdu c-black">Edit</button></p>
                    <p class="c-oblack f14">${time}</p>
                  </div>
                </div>
                <div class="df ais mb15">
                  <img src="/assets/icons/pin.svg" class="mr10 vas"/>
                  <div>
                    <p class="f18 fw900 mb5">Ephemeral Tattoo - ${location?.name}</p>
                    ${address.trim().length ? `
                      <p class="c-oblack f14"><a href="${encodedAddress}" target="_blank" class="tdu">${address}</a></p>
                    `: ''}
                  </div>
                </div>
                <div class="df ais mb15">
                  <img src="/assets/icons/person.svg" class="mr10 vas"/>
                  <div>
                    <p class="f18 fw900 mb5">${service}</p>
                    <p class="c-oblack f14">${price}</p>
                  </div>
                </div>
              </div>
            </div>

            ${showLastCall ? `<div class="rte f12 lh140 tl">${lastCall.innerHTML}</div>` : ``}
            ${isDiscountDay ? `<div class="rte f14 lh140 tc mt20 mb20 bg-bud pt10 pb10">${discountDayBookingMessage.innerHTML}</div>` : ``}

            ${paymentCaptureEnabled ? `
              <img src="/assets/icons/sparkle.svg" class="db mha mv5 tc vam" width="20">
              <h2 class="light f24 x tc pb10 pt15">${paymentInfoTitle}</h2>
              <p class="c-oblack f14 mb15 lh140">${creditCardRequiredMessage}</p>
              <div id="payment-request" class="payment-request">
                <div class="payment-request-container">
                  <div class="payment-request-button-and-divider mb20">
                    <div id="payment-request-button" class="payment-request-button skeleton skeleton-button mt10">
                      <!-- A Stripe Element will be inserted here. -->
                    </div>
                    <div class="payment-request-divider skeleton skeleton-divider tc mt30">
                      <hr class="bs-solid bw1 mbn10">
                      <label class="bg-cream di f14 pv20 ph15">OR</label>
                    </div>
                  </div>
                </div>
              </div>
            `: ''}
            ${!hasEmail ? `
              <div class="pb10">
                <label class="f14 mb5 db" for="email">Email *</label>
                <input type="email" id="email" name="email" class="js-emailSelector ba bc-black bw1 db ph10 pv5 x" value="${state.email || ''}" required>
              </div>
            `: ''}
            <div class="mt10 pb10 df">
              <div class="pr10">
                <label class="f14 mb5 db" for="firstName">First Name *</label>
                <input type="text" id="firstName" name="firstName" class="js-firstName ba bc-black bw1 db ph10 pv5 x" value="${state.firstName ||
                  ''}">
              </div>
              <div class="pl10">
                <label class="f14 mb5 db" for="lastName">Last Name *</label>
                <input type="text" id="lastName" name="lastName" class="js-lastName ba bc-black bw1 db ph10 pv5 x" value="${state.lastName ||
                  ''}">
              </div>
            </div>
            <div class="pb10">
              <label class="f14 mb5 db" for="phone">Phone *</label>
              <input type="tel" id="phone" name="phone" class="js-phone ba bc-black bw1 db ph10 pv5 x" placeholder="+1 555-555-5555"  title="+1 555-555-5555" maxlength="20" value="${state.phone ||
                ''}">
            </div>
            ${ errors?.length ? `
              <div class="error ba bc-clay bw1 c-clay ph10 mv10 p5">
                ${errors.map(error => (
                  `<div class="p5">${error}</div>`
                )).join('')}
              </div>`
              : ``}

            ${paymentCaptureEnabled ? `
              <div class="pb10">
                <label id="payment-request-title" class="f14 mb5 db">Card</label>
                <div class="input-container-sm bg-cream p10 ba bc-black bw1">
                  <div class="card-details-info"></div>
                </div>
              </div>
            `: ''}

            ${showOptIn ? `
              <div class="checkbox pb10">
                <input type="checkbox" id="optIn" name="optIn" class="js-optIn" value="${state.optIn}" ${optInChecked}>
                <label class="f14 mb5 db" for="optIn"><span class="dib pl10 pt5">${confirmationOptInMessage}</span></label>
              </div>
            `: ''}

            ${hasEmail ? `
              <div class="f12 lh140">
                A confirmation will be emailed to ${state.email}.
              </div>
            `: ''}

            <div class="x tc pt5" data-component="bookingButton" data-prop="firstName,lastName,phone" data-value="${confirmationButtonText}" data-validate="error"></div>

            ${paymentCaptureEnabled ? `
              <div class="payment-info df jcb aic mt10 mb10 f12">
                <p>Guaranteed <strong>safe &amp; secure</strong> checkout</p>
                <a href="https://stripe.com/" target="_blank" class="payment-logo w30">
                  <img src="/assets/icons/powered-by-stripe.svg" class="x" alt="powered by stripe"/>
                </a>
              </div>
              ` : ``}

            ${paymentCaptureEnabled && finePrint.innerText.trim().length ? `<div class="rte f12 lh140 tl pt10">${finePrint.innerHTML}</div>` : ``}
          </form>
        </div>
        <div class="dn m:db m:w50 x rel">
          <div class="abs zn1 x y top left bottom right bg-black bl bc-broccolini bw3" style="background: url('${confirmationImage?.lqip}') center center / cover no-repeat;"></div>
          <div class="abs z0 x y top left bottom right bg-black bl bc-broccolini bw3" style="background: url('${confirmationImage?.url}') center center / cover no-repeat;"></div>
        </div>
      </div>
      `;

    if (!hasEmail) {
      on(choozy(node).emailSelector, 'blur', captureEmail);
      on(choozy(node).emailSelector, 'input', updateEmail);
    }
    on(choozy(node).selectInfo, 'submit', updateInfo);
    on(choozy(node).firstName, 'keyup', updateInfoInput);
    on(choozy(node).lastName, 'keyup', updateInfoInput);
    on(choozy(node).phone, 'keyup', updatePhoneInput);
    on(choozy(node).goBack, 'click', goBack);
    if (showOptIn) on(choozy(node).optIn, 'change', updateOptIn);
    onMount(state);
  };

  ctx.on('bookingInfoUpdate', (state) => {
    if (state.view !== 'confirmation') return;
    render(state);
    ctx.emit('bookingMountComponents');
    ctx.emit('bookingResize');
  });

  const init = async () => {
    view = 'loading';
    const state = ctx.getState();

    const data = {
      ...(state.location?.externalId && {l: state.location?.externalId}),
      ...(state.service?.name && {design: {type: state.service?.name}}),
      ...(state.email && {email: state.email}),
    };

    // Generate a reservation id (rpId) if we don't have one or update existing reservation
    if (!state.rpId) {
      const { reservation_id } = await createReservation(data);
      state.rpId = reservation_id;
      ctx.hydrate(state);
    } else {
      await updateReservation(state.rpId, data);
    }

    // Track abandoners
    if (paymentCaptureEnabled && hasEmail) {
      sendAbandonCartEmail(state.rpId, state.email, state.location?.externalId, state.service?.name);
    }

    // Set state of opt-in checkbox
    state.optIn = optInDefault;

    // Initial mount
    ctx.emit('bookingInfoUpdate');

    if (paymentCaptureEnabled) {
      // Get one time stripe config data then remount
      const stripeConfig = await getStripeConfigurationData();
      PUBLIC_KEY = stripeConfig.publicKey;
      currency = stripeConfig.currency;

      // Track checkout view
      trackTikTokCheckout();
    }
    ctx.hydrate(state);

    // Remount
    view = 'loaded';
    ctx.emit('bookingInfoUpdate');
  };

  init();
});
