import { component } from 'picoapp';
import { heapIdentifyUser, isStringBase64 } from '@/util/utils';

export default component((node) => {
  const API_URL = process.env.BACKEND_API_URL || 'https://api.ephemeral.tattoo';

  const retrieveClientPublicId = async (email) => {
    const encodedEmail = encodeURIComponent(email);
    const response = await fetch(
      `${API_URL}/api/client?email=${encodedEmail}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    const data = await response.json();

    return data?.client_public_id;
  };

  const retrieveEmailFromParams = () => {
    const search = new URLSearchParams(window.location.search);

    return search.get('e') ? search.get('e') : null;
  };

  const sanitizeEmail = (email) => {
    if (isStringBase64(email)) {
      email = atob(email);
    }

    // This could be removed if we encode email properly from typeform to booking page redirect
    // Check if email has empty space(s) in it and replaces it with +
    email = email.replace(/\s/g, '+').toLowerCase();

    return email;
  };

  const init = async () => {
    let email = retrieveEmailFromParams();

    if (email !== null && email !== undefined) {
      email = sanitizeEmail(email);
      const clientPublicId = await retrieveClientPublicId(email);
      heapIdentifyUser(clientPublicId);
    }
  };

  window.onload = () => init();
});
