import { component } from 'picoapp';
import choozy from 'choozy';
import { trackHeapEvent } from '@/util/utils';

export default component((node) => {
  const API_URL = process.env.BACKEND_API_URL;
  const {
    bundlePromo,
    bundlePromoBoxInnerContainer,
    bundlePromoLoader,
  } = choozy(node);

  const showBundleCode = (bundleCode) => {
    bundlePromo.innerHTML += `${bundleCode.toUpperCase()}`;
    bundlePromoBoxInnerContainer.classList.remove('dn');
  };

  const setLoadingState = (status) => {
    if (status) {
      bundlePromoLoader.classList.add('loading');
    } else {
      bundlePromoLoader.classList.remove('loading');
    }
  };

  const pollForBundleCode = async (stripeCheckoutSessionId) => {
    const response = await fetch(
      `${API_URL}/api/promo_codes/${stripeCheckoutSessionId}`,
      {
        method: 'GET',
      },
    );

    if (response.status === 404 || response.status === 500) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await pollForBundleCode(stripeCheckoutSessionId);
    } else {
      const data = await response.json();

      return data?.code;
    }
  };

  const init = async () => {
    setLoadingState(true);
    const search = new URLSearchParams(window.location.search);
    const stripeCheckoutSessionId = search.get('checkout_session_id')
      ? search.get('checkout_session_id')
      : null;

    if (stripeCheckoutSessionId) {
      trackHeapEvent('Purchased Bundle', {
        checkout_session_id: stripeCheckoutSessionId,
      });

      const code = await pollForBundleCode(stripeCheckoutSessionId);
      setLoadingState(false);
      showBundleCode(code);
    }
  };

  init();
});
