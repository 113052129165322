import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { component } from 'picoapp';
import {
  addHours,
  addDays,
  dateToDmy,
  dmyToDate,
  formatDateForBlvd,
  formatDayForDisplay,
  formatWeekForDisplay,
  loader,
  scrollUp,
  trackEvent,
  trackHeapEvent,
} from '@/util/utils';
import { getAvailableDates, removeServiceInCart, getTimesForDate } from '@/util/boulevard';

export default component((node, ctx) => {
  const paymentCaptureEnabled = node.dataset.enablePaymentCapture === 'true';
  const lastCall = choozy(ctx.getState().node).lastCall;
  const lastCallRange = parseInt(lastCall.getAttribute('data-last-call-range')) || 0;
  const timeTaken = choozy(ctx.getState().node).timeTaken;
  const state = ctx.getState();
  const tz = state.location.tz;
  const weekLength = 14; // Number of days to show in the week view
  const continueButton = ctx.getState().node.getAttribute('data-continue-button');
  const continueButtonText = paymentCaptureEnabled ? 
                                    continueButton.length ? continueButton : 'Proceed to Checkout'
                                     : 'Proceed to Confirmation';

  const locationSettings = choozy(ctx.getState().node).locationSetting;
  const locationSettingEl = locationSettings.find(location => JSON.parse(location.dataset.locationSetting).location.slug === state.location.externalId);
  const locationSetting = JSON.parse(locationSettingEl?.dataset?.locationSetting);
  let firstAvailableDate, startTime, startDate, endDate, lastCallEnabled, bookingRangeEnabled, bookingRangeStart, bookingRange;

  const noTime = choozy(locationSettingEl).locationSettingNoTime;
  lastCallEnabled = locationSetting.enableLastCall;
  bookingRangeEnabled = locationSetting.enableBookingRange;
  bookingRangeStart = parseInt(locationSetting.bookingRangeStart, 10) || 72;
  bookingRange = parseInt(locationSetting.bookingRange, 10);
  if (bookingRangeEnabled && bookingRange) {
    const startDateObject = addHours(new Date(), bookingRangeStart).toISOString();
    startTime = startDateObject;
    startDate = startDateObject.slice(0, 10);
    endDate = addDays(new Date(), bookingRange).toISOString().slice(0, 10);
  } else {
    startDate = locationSetting.startDate;
    endDate = locationSetting.endDate;
  }

  const checkBookingInterval = () => {
    const endDateWeekEnd = dmyToDate(endDate);
    endDateWeekEnd.setDate(endDateWeekEnd.getDate() + (weekLength - 1));
    const today = new Date();

    today.setHours(23, 59, 59, 0);
    endDateWeekEnd.setHours(23, 59, 59, 0);
    ctx.hydrate({ isBookingOpen: endDateWeekEnd - today > 0 });
  };

  const updateWeekPrevious = async (e) => {
    if (e) e.preventDefault();
    const previousWeek = getPreviousWeek();
    const previousWeekFormatted = formatDateForBlvd(previousWeek);
    ctx.hydrate({ reservedTimeFail: false });
    ctx.emit('bookingUpdateViewWeek', ctx.getState(), previousWeekFormatted);
    trackEvent('booking', `week`, `click week previous`);
    trackHeapEvent(`Clicked - Book-Now - Calendar Week Back Button`);
    await updateWeek(previousWeekFormatted);
  };

  const updateWeekNext = async (e) => {
    if (e) e.preventDefault();
    const nextWeek = getNextWeek();
    const nextWeekFormatted = formatDateForBlvd(nextWeek);
    ctx.hydrate({ reservedTimeFail: false });
    ctx.emit('bookingUpdateViewWeek', ctx.getState(), nextWeekFormatted);
    trackEvent('booking', `week`, `click week next`);
    trackHeapEvent(`Clicked - Book-Now - Calendar Week Forward Button`);
    await updateWeek(nextWeekFormatted);
  };

  const getCurrentWeek = () => {
    const state = ctx.getState();
    const weekDays = state.week ? Object.keys(state.week) : [];
    return dmyToDate(weekDays.shift());
  };

  const getCurrentWeekEnd = () => {
    const state = ctx.getState();
    const weekDays = state.week ? Object.keys(state.week) : [];
    return dmyToDate(weekDays.pop());
  };

  const getPreviousWeek = () => {
    const weekStart = getCurrentWeek();
    const previousWeek = dmyToDate(formatDateForBlvd(weekStart));
    previousWeek.setDate(previousWeek.getDate() - weekLength);
    return previousWeek;
  };

  const getNextWeek = () => {
    const weekStart = getCurrentWeek();
    const nextWeek = dmyToDate(formatDateForBlvd(weekStart));
    nextWeek.setDate(nextWeek.getDate() + weekLength);
    return nextWeek;
  };

  const hasDay = (day) => {
    const currDateCompare = dmyToDate(day);
    const endDateWeekEnd = dmyToDate(endDate);
    // This doesn't use weekLength because the content manager and data analyst is expecting the endDate to be the beginning of the last week shown
    endDateWeekEnd.setDate(endDateWeekEnd.getDate() + 7);
    return endDateWeekEnd > currDateCompare;
  };

  const hasPreviousWeek = () => {
    const currentWeek = getCurrentWeek();
    const startDateCompare = dmyToDate(startDate);
    return startDateCompare < currentWeek;
  };

  const hasNextWeek = () => {
    const currentWeek = getCurrentWeekEnd();
    const endDateCompare = dmyToDate(endDate);
    return endDateCompare > currentWeek;
  };

  const getBookingAvailability = async (start, end, limit) => {
    const state = ctx.getState();

    const available = await getAvailableDates(
      state.cartId,
      start,
      end,
      limit,
    );

    return (available?.data?.cartBookableDates || []).map(({ date }) => date);
  };

  const skipStartDate = async (date) => {
    if (!bookingRangeEnabled) {
      return false;
    }

    const times = await getTimesForDate(state.cartId, date, tz);

    if (times?.data?.cartBookableTimes) {
      const sortedTimes = times.data.cartBookableTimes.sort((a, b) => (new Date(a.startTime) > new Date(b.startTime)) ? 1 : ((new Date(b.startTime) > new Date(a.startTime)) ? -1 : 0));
      const filteredTimes = sortedTimes.filter((time) => (Date.parse(time.startTime) > Date.parse(startTime) ? true : false));

      if (filteredTimes.length === 0) { return true; }
    }

    return false;
  };

  const redirectToServiceSelection = async () => {
    const { cartId, service } = ctx.getState();
    await removeServiceInCart(cartId, service.ID);

    trackEvent('booking', `week`, `redirected after sold out`);
    ctx.emit('bookingUpdateView', { view: 'services' });
  };

  const updateWeek = async (date) => {
    let day = dmyToDate(date);
    const state = ctx.getState();

    const endDateWeekEnd = dmyToDate(endDate);
    endDateWeekEnd.setDate(endDateWeekEnd.getDate() + (weekLength - 1));
    const anyServicableDates = await getBookingAvailability(startDate, formatDateForBlvd(endDateWeekEnd), 1);

    if (!anyServicableDates.length && !state.service?.fromURL) {
      await redirectToServiceSelection();

      state.bookableDates = null;
      state.week = undefined;
      state.service = null;
      ctx.hydrate(state);

      ctx.emit('bookingUpdateView', { view: 'services' });
    }

    const weekEndDate = dmyToDate(date);
    weekEndDate.setDate(weekEndDate.getDate() + (weekLength - 1));
    const bookableDates = await getBookingAvailability(date, formatDateForBlvd(weekEndDate), weekLength);

    state.bookableDates = bookableDates;
    state.week = {};
    state.week[formatDateForBlvd(day)] = false;
    for (let i = 1; i < weekLength; i++) {
      const nextDay = dmyToDate(formatDateForBlvd(day));
      nextDay.setDate(nextDay.getDate() + i);
      // Filter out dates from beyond end date (JIC the range set doesn't play nicely with the week lenght)
      const nextDayFormatted = formatDateForBlvd(nextDay);
      if (hasDay(nextDayFormatted)) {
        state.week[nextDayFormatted] = false;
      }
    }

    scrollUp(node);
    ctx.hydrate(state);
    ctx.emit('bookingUpdateWeek', state);
  };

  const updateTime = (e) => {
    e.preventDefault();
    ctx.emit('bookingUpdateReserve');
    trackEvent('booking', `week`, `click proceed`);
  };

  const goBack = (e) => {
    e.preventDefault();
    ctx.emit('bookingUpdateView', { view: 'services' });
    trackEvent('booking', `week`, `click back`);
  };

  const onMount = () => {
    const doesHavePreviousWeek = hasPreviousWeek();
    const doesHaveNextWeek = hasNextWeek();

    doesHavePreviousWeek && on(choozy(node).previousWeek, 'click', updateWeekPrevious);
    doesHaveNextWeek && on(choozy(node).nextWeek, 'click', updateWeekNext);
    on(choozy(node).selectTime, 'submit', updateTime);
    choozy(node).goBack && on(choozy(node).goBack, 'click', goBack);
  };

  const renderLoader = () => {
    node.innerHTML = loader;
    return;
  };

  const render = (state) => {
    const doesHavePreviousWeek = hasPreviousWeek();
    const doesHaveNextWeek = hasNextWeek();
    const weekStart = formatWeekForDisplay(getCurrentWeek(), tz);
    const weekEnd = formatWeekForDisplay(getCurrentWeekEnd(), tz);
    const failed = state.reservedTimeFail ? `<div class="aic ba bc-clay br10 df f14 m:f16 max-w350 mha ph10 pv10 tl"><img src="/assets/icons/sad.svg" class="mr10 vas" style="height: 2em;"/><span>${timeTaken.innerText.trim().length ? timeTaken.innerHTML : `Whoops that spot is no longer available! Please select another date and time.`}</span></div>` : '';
    const today = new Date().toISOString();
    if (!state.week) return;

    if (state.isBookingOpen === false) {
      node.innerHTML = `
        <h3 class="pv35">Booking session is closed</h3>
      `;
      return;
    }

    node.innerHTML = `
      <div class="df x aic jcc mb20 pt30">
        <button class="df jcc aic pv5 ph40 r180 js-previousWeek ${(doesHavePreviousWeek ? '' : 'o25')}" ${(doesHavePreviousWeek ? '' : 'disabled')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#181818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m12 16 4-4m0 0-4-4m4 4H8m14 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"/></svg>
        </button>
        <h3 class="f16 lh140 ttu">${(weekStart)} – ${(weekEnd)}</h3>
        <button class="df jcc aic pv5 ph40 js-nextWeek ${(doesHaveNextWeek ? '' : 'o25')}" ${(doesHaveNextWeek ? '' : 'disabled')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#181818" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m12 16 4-4m0 0-4-4m4 4H8m14 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"/></svg>
        </button>
      </div>
      ${failed}
      <form action="/book" class="js-selectTime week mt30 tl ph20" style="${(7.45 * weekLength)}em">
        ${Object.keys(state.week).map((day) => {
          const dayFormatted = formatDayForDisplay(day, tz);
          const isSelected = (state.time?.name && (dateToDmy(state.time?.name) === day));
          const isLastCall = isSelected === true && (Math.abs(Date.parse(state.time.name)-Date.parse(today)) / 36e5) <= lastCallRange && lastCallEnabled;
          const isAvailable = state.bookableDates.indexOf(day) !== -1;

          return `
                <h4 class="pb10">${dayFormatted}</h4>
                <div class="week-day-block pb20 ${isLastCall === true ? 'week-day-block--last-call' : ''}" data-component="bookingDay" data-enable-booking-range="${bookingRangeEnabled}" data-available="${isAvailable}" data-start-time="${startTime}" data-date="${day}" data-last-call="${String(isLastCall === true)}" data-enable-last-call="${lastCallEnabled}"></div>
              `;
        }).join('')}
        ${noTime?.innerText?.length ? `
            <div class="tc">
              <div class="aic dib f14 mha mt20 p20" style="background: #d8b09c;">
                <img src="/assets/icons/calendar.svg" class="mr10 vas"/>
                <div class="rte">${noTime.innerHTML}</div>
              </div>
            </div>
          ` : ``}
        ${doesHavePreviousWeek || doesHaveNextWeek ? `
          <div class="max-w375 mha tc pt20 df">
            ${doesHavePreviousWeek ? `
              <a href="#prevweek" class="js-previousWeek bc-black bs-solid btn btn-full bw1 c-black cta-ref f14 mh5 mt10 ttu lsn04em mono btn-with-reverse-arrow">Previous Week</a>
            ` : ``}
            ${doesHaveNextWeek ? `
              <a href="#nextweek" class="js-nextWeek bc-black bs-solid btn btn-full bw1 c-black cta-ref f14 mh5 mt10 ttu lsn04em mono btn-with-arrow">Next Week</a>
            ` : ``}
          </div>
        ` : ``}
        <div class="max-w375 mha pt5 tc" data-component="bookingButton" data-prop="time" data-value="${continueButtonText}"></div>
      </form>
      ${state.service && !state.service?.fromURL ? `
        <div class="tc pt10 pb30">
          <a href="#back" class="js-goBack f12 tdu c-black">Back to style selection</a>
        </div>
      ` : ``}
    `;
    onMount();
  };

  ctx.on('bookingUpdateWeek', (state) => {
    if (state.week === undefined) {
      renderLoader();
    } else {
      render(state);
    }

    ctx.emit('bookingMountComponents');
  });

  ctx.on('bookingUpdateWeekByDate', async (state, week) => {
    await week ? updateWeek(week) : start();
  });

  ctx.on('bookingButtonUpdate', (state) => {
    if (state.view !== 'week') return;
    render(state);
    ctx.emit('bookingMountComponents');
  });

  const start = async () => {
    const shouldSkipStartDate = await skipStartDate(startDate);
    if (shouldSkipStartDate) {
      startDate = addDays(new Date(startDate), 1).toISOString().slice(0, 10);
      await updateWeek(startDate);
    } else {
      await updateWeek(startDate);
    }
  };

  const init = async () => {
    const state = ctx.getState();
    checkBookingInterval();
    ctx.emit('bookingUpdateWeek');

    if (state.week) {
      await updateWeek(formatDateForBlvd(getCurrentWeek()));
    } else {
      await start();
    }
  };

  init();
});
