import { component } from 'picoapp';

export default component((node, ctx) => {
  const progress = (state) => {
    const hasServiceType = node.dataset.hasServiceType === 'true';
    const hasLocation = node.dataset.hasLocation === 'true';
    let progressPercent = 0;
    let totalSteps = 5;
    if (hasLocation) totalSteps--;
    if (hasServiceType) totalSteps--;
    const step = (100/totalSteps);
    if (/loading|reserving/.test(state.view)) return;
    
    switch (state.view) {
      case 'locations': {
        progressPercent = step * 1;
        break;
      }
      case 'group': {
        progressPercent = hasLocation ? step * 1 : step * 2;
        break;
      }
      case 'services': {
        progressPercent = hasLocation ? step * 1 : step * 2;
        break;
      }
      case 'week': {
        if (hasLocation && hasServiceType) {
          progressPercent = step * 1;
        } else if (hasLocation || hasServiceType) {
          progressPercent = step * 2;
        } else {
          progressPercent = step * 3;
        }
        break;
      }
      case 'confirmation': {
        progressPercent = step * (totalSteps-1);
        break;
      }
      case 'thanks': {
        progressPercent = step * (totalSteps);
        break;
      }
    }

    setTimeout(() => {
      document.documentElement.style.setProperty('--app-progress-percent', `${progressPercent}%`);
    }, 250);
  };

  ctx.on('bookingProgressUpdate', (state) => {
    progress(state);
  });

  const init = () => {
    if (!getComputedStyle(document.documentElement).getPropertyValue('--app-progress-percent')?.length) {
      document.documentElement.style.setProperty('--app-progress-percent', `0%`);
    }
    const state = ctx.getState();
    progress(state);
  };

  init();
});
