import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';

export default component((node) => {
  const { textBoxWrapper, imgWrapper } = choozy(node);
  const images = imgWrapper.children;
  const textBoxes = textBoxWrapper.children;

  let containerViewHeight;
  let parentPositionTop;
  let parentPositionBottom;

  let lastScrollY;
  let ticking;

  const init = () => {
    lastScrollY = 0;
    ticking = false;
    containerViewHeight = Math.floor(imgWrapper.clientHeight);
    parentPositionTop = node.offsetTop;
    parentPositionBottom = parentPositionTop + containerViewHeight * images.length;
    const wrapperHeight = imgWrapper.clientHeight;

    node.style.setProperty('--img-wrapper-height', `${wrapperHeight}px`);

    for (let i = 0; i < images.length; i += 1) {
      images[i].style.zIndex = images.length - i;
      images[i].style.opacity = 1;
    }

    for (let i = 0; i < textBoxes.length; i += 1) {
      textBoxes[i].style.height = `${containerViewHeight}px`;
    }
  };

  const fadeContent = () => {
    const active = Math.floor((lastScrollY - parentPositionTop) / containerViewHeight);

    if (active !== 0) {
      images[active - 1].style.opacity = 0;
      textBoxes[active - 1].style.opacity = 0;
    }

    const imagePositionStart = (lastScrollY - containerViewHeight * active) - parentPositionTop;

    images[active].style.opacity = 1 - (imagePositionStart / containerViewHeight);
    textBoxes[active].style.opacity = 1 - (imagePositionStart / containerViewHeight);

    ticking = false;
  };

  const requestTick = () => {
    if(!ticking) {
      requestAnimationFrame(fadeContent);

      ticking = true;
    }
  };

  const handleOnScroll = () => {
    lastScrollY = window.scrollY;
    const shouldFade = lastScrollY > parentPositionTop && lastScrollY < parentPositionBottom - containerViewHeight;

    if (!shouldFade) return;

    requestTick();
  };

  on(document, 'scroll', handleOnScroll);
  on(document, 'DOMContentLoaded', init);
  on(window, 'resize', init);
});

