import * as Sentry from '@sentry/browser';

const sentryRelease = () =>
  `${process.env.NODE_ENV || 'development'}-${process.env.BUILD_ID ||
    'NONE'}`;

const init = () => {
  if (!process.env.SENTRY_DSN) return;
  const environment = process.env.NODE_ENV || 'development';
  const host = new URL(process.env.BACKEND_API_URL).host;

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: sentryRelease(),
    environment,
    debug: environment === 'development',
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ['localhost', /^\//, host],
        shouldCreateSpanForRequest(_url) {
          return true;
        },
      }),
    ],
    tracesSampleRate: Number(process.env.SENTRY_TRACES_SAMPLE_RATE),
  });
};

export default init;
