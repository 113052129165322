import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { component } from 'picoapp';

export default component((node, ctx) => {
  const value = node.getAttribute('data-value');
  const prop = node.getAttribute('data-prop');

  const render = (state) => {
    const props = prop.split(',');
    const empty = props.find((prop) => !state[prop]);
    const enabled = !empty && !state.error && !state.disabled && !state.submitting
        ? ''
        : 'disabled';
    node.innerHTML = `
      ${
        !state.error
          ? ``
          : `
        <div class="f14 bg-lgrey p5 mv5">${state.error}</div>
      `
      }
      <input type="submit" ${enabled} class="btn bg-oblack c-white bc-white f18 ass btn-full mt10 ttu lsn04em fw700 mono" value="${
      state.submitting ? 'Processing...' : value
    }" />
      `;
  };

  ctx.on('bookingButtonUpdate', (state) => {
    render(state);
  });

  const init = () => {
    const state = ctx.getState();
    render(state);
  };

  init();
});
