const BLVD_API_URL = process.env.BLVD_API_URL;
const BLVD_API_KEY = process.env.BLVD_API_KEY;
const BLVD_BUSINESS_ID = process.env.BLVD_BUSINESS_ID;
const BLVD_API_KEY_ENCODED = btoa(`${BLVD_API_KEY}:`);

/**
 * Get the locations
 *
 * @return {object}
 */
export const getLocations = async () => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        query {
          business {
            locations(first: 20) {
              edges {
                node {
                  id
                  name
                  tz
                  externalId
                  phoneNumber
                  contactEmail
                  address {
                    line1
                    line2
                    city
                    state
                    zip
                  }
                }
              }
            }
          }
        }
        `,
    }),
  });
  return res ? res.json() : false;
};

/**
 * Create the cart
 *
 * @return {object}
 */
export const createCart = async (locationId) => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        mutation {
          createCart(input: {
            locationId: "${locationId}"
          }) {
            cart {
              id
        
              availableCategories {
                name
        
                availableItems {
                  id
                  name
                }
              }
            }
          }
        }
        `,
    }),
  });
  return res ? res.json() : false;
};

/**
 * Add service to the cart
 *
 * @return {object}
 */
export const addToCart = async (cartId, serviceId) => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        mutation {
          addCartSelectedBookableItem(input: {
            id: "${cartId}"
            itemId: "${serviceId}"
          }) {
            cart {
              id
              selectedItems {
                id
              }
            }
          }
        }
        `,
    }),
  });
  return res ? res.json() : false;
};

/**
 * Get bookable times for a date
 *
 * @return {object}
 */
export const getTimesForDate = async (
  cartId,
  date,
  tz = 'America/New_York',
) => {
  // TODO: Update timezone to user's
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        query {
          cartBookableTimes(
            id: "${cartId}"
            searchDate: "${date}"
            tz: "${tz}"
          ) {
            id
            score
            startTime
          }
        }
        `,
    }),
  });
  return res ? res.json() : false;
};

export const getAvailableDates = async (
  cartId,
  startDate,
  endDate,
  limit,
  tz = 'America/New_York',
) => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        query GetAvailableDates(
          $cartId: ID!,
          $startDate: Date,
          $endDate: Date,
          $tz: Tz,
          $limit: Int 
        ) {
          cartBookableDates(
            id: $cartId,
            searchRangeLower: $startDate,
            searchRangeUpper: $endDate,
            tz: $tz
            limit: $limit
          ) {
            date
          }
        }
      `,
      variables: {
        cartId,
        startDate,
        endDate,
        limit,
        tz,
      },
    }),
  });

  return res ? res.json() : false;
};

/**
 * Get cart
 *
 * @return {object}
 */
export const getCart = async (cartId) => {
  // TODO: Update timezone to user's
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        query {
          cart(
            id: "${cartId}"
          ) {
            selectedItems {
              id
            }
          }
        }
        `,
    }),
  });
  return res ? res.json() : false;
};

/**
 * Reserve the spot in the cart
 *
 * @return {object}
 */
export const reserveTimeInCart = async (cartId, timeId) => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        mutation {
          reserveCartBookableItems(input: {
            id: "${cartId}"
            bookableTimeId: "${timeId}"
          }) {
            cart {
              id
              selectedItems {
                id
              }
            }
          }
        }              
        `,
    }),
  });
  return res ? res.json() : false;
};

/**
 * Update the time in the cart to remove the staff variant so that all bookable times will still be returned
 *
 * @return {object}
 */
export const removeStaffVariantFromTimeInCart = async (cartId, itemId) => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        mutation {
          updateCartSelectedBookableItem(input: {
            id: "${cartId}"
            itemId: "${itemId}"
            itemStaffVariantId: null
          }) {
            cart {
              id
              selectedItems {
                id
              }
            }
          }
        }              
        `,
    }),
  });
  return res ? res.json() : false;
};

/**
 * Remove the reserved service in the cart to free up the reserved time
 *
 * @return {object}
 */
export const removeServiceInCart = async (cartId, serviceId) => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        mutation {
          removeCartSelectedItem(input: {
            id: "${cartId}"
            itemId: "${serviceId}"
          }) {
            cart {
              id
              selectedItems {
                id
              }
            }
          }
        }              
        `,
    }),
  });
  return res ? res.json() : false;
};

/**
 * Remove the reserved service in the cart to free up the reserved time
 *
 * @return {object}
 */
export const removeAndAddServiceInCart = async (
  cartId,
  removeServiceId,
  addServiceId,
) => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        mutation {
          removeCartSelectedItem(input: {
            id: "${cartId}"
            itemId: "${removeServiceId}"
          }) {
            cart {
              id
            }
          }
          addCartSelectedBookableItem(input: {
            id: "${cartId}"
            itemId: "${addServiceId}"
          }) {
            cart {
              id
              selectedItems {
                id
              }
            }
          }
        }
        `,
    }),
  });
  return res ? res.json() : false;
};

/**
 * Reserve the spot in the cart
 *
 * @return {object}
 */
export const addInfoToCart = async (
  cartId,
  firstName,
  lastName,
  email,
  phone,
) => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        mutation {
          updateCart(input: {
            id: "${cartId}",
            clientInformation: {
              email: "${email}",
              firstName: "${firstName}",
              lastName: "${lastName}",
              phoneNumber: "${phone}"
            }
          }) {
            cart {
              id
            }
          }
        }         
        `,
    }),
  });
  return res ? res.json() : false;
};

export const getQuestionId = async (cartId) => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        query {
          cart(id: "${cartId}") {
            bookingQuestions {
              id
              key
              answer {
                __typename
              }
            }
          }
        }         
        `,
    }),
  });
  return res ? res.json() : false;
};

/**
 * Add reservation id to cart
 *
 * @return {object}
 */
export const addDataToCart = async (cartId, data, questionId) => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        mutation {
          cartBookingQuestionAddAnswer(input: {
            id: "${cartId}",
            answer: {
              textValue: "${data}"
            },
            questionId: "${questionId}"
          }) {
            cart {
              bookingQuestions {
                id
                key
                answer {
                  __typename,
                  ... on CartBookingQuestionTextAnswer {
                    textValue
                  }
                }
              }
            }
          }
        }         
        `,
    }),
  });
  return res ? res.json() : false;
};

/**
 * Checkout the cart to finish the appointment booking
 *
 * @return {object}
 */
export const checkoutCart = async (cartId) => {
  const res = await fetch(`${BLVD_API_URL}/${BLVD_BUSINESS_ID}/client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${BLVD_API_KEY_ENCODED}`,
    },
    body: JSON.stringify({
      query: `
        mutation {
          checkoutCart(input: {
            id: "${cartId}"
          }) {
            cart {
              id
              completedAt
            }
          }
        }
        `,
    }),
  });
  return res ? res.json() : false;
};

/**
 * Construct the services for the cart
 *
 * @return {object}
 */
export const buildLocationCartServices = async (locationId) => {
  const servicesData = await createCart(locationId);
  const services =
    servicesData &&
    servicesData.data &&
    servicesData.data.createCart &&
    servicesData.data.createCart.cart &&
    servicesData.data.createCart.cart.availableCategories &&
    servicesData.data.createCart.cart.availableCategories.length &&
    servicesData.data.createCart.cart.availableCategories[0].availableItems &&
    servicesData.data.createCart.cart.availableCategories[0].availableItems
      .length
      ? servicesData.data.createCart.cart.availableCategories[0].availableItems
      : false;

  // Mutate data into simple variables for state object only if services are found
  return services
    ? {
        services,
        cartId: servicesData.data.createCart.cart.id,
      }
    : false;
};
