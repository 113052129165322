import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { component } from 'picoapp';
import { getTimesForDate } from '@/util/boulevard';
import { loader, trackEvent, trackHeapEvent, formatTimeForDisplay, dmyToDate } from '@/util/utils';

export default component((node, ctx) => {
  const bookingRangeEnabled = node.getAttribute('data-enable-booking-range') === 'true';
  const dateStartTime = node.getAttribute('data-start-time');
  const date = node.getAttribute('data-date');
  const isAvailable = node.getAttribute('data-available') !== 'false';
  const isLastCallDay = node.getAttribute('data-last-call') !== 'false';
  const lastCallEnabled = node.dataset.enableLastCall === 'true';
  const lastCall = choozy(ctx.getState().node).lastCall;
  const lastCallRange = parseInt(lastCall.getAttribute('data-last-call-range')) || 0;
  const lastCallLabel = lastCall.getAttribute('data-last-call-label') || 'Last Call!';
  const tz = ctx.getState().location.tz;
  let loading = true;

  const getTimes = async (date) => {
    let state = ctx.getState();

    if (!isAvailable) {
      state.week[date] = [];
    } else if (!state.week[date]) {
      // Get times for a date and update the state
      const times = await getTimesForDate(state.cartId, date, tz);
      if (times && times.data && times.data.cartBookableTimes) {
        const sortedTimes = times.data.cartBookableTimes.sort((a, b) => (new Date(a.startTime) > new Date(b.startTime)) ? 1 : ((new Date(b.startTime) > new Date(a.startTime)) ? -1 : 0));
        state.week[date] = sortedTimes;
      }
    }

    // Recheck state in case week changed while waiting, only proceed if date still exists in the latest week
    state = ctx.getState();
    const hasDate = Object.keys(state.week).find(day => day === date);

    if (hasDate) {
      updateDate(state);
      ctx.emit('bookingTimeUpdate', state);
    }

  };

  const updateSelectedTime = (e) => {
    if (e.target.checked) {
      const state = ctx.getState();
      state.time = { name: e.target.getAttribute('data-time'), ID: e.target.value, last_call: e.target.getAttribute('data-last-call') === 'true' };
      ctx.hydrate({ time: state.time });
      ctx.emit('bookingButtonUpdate', state);
      trackEvent('booking', `${state.view}`, `click time ${state.time.name}`);
      trackHeapEvent('Clicked Appointment Time Slot', {
        date: date, 
        time: formatTimeForDisplay(state.time.name, tz),
        dow: dmyToDate(date).toLocaleDateString('en-US', { weekday: 'long' })
      });
      updateDate(state);
    }
  };

  const updateDate = (state) => {
    loading = false;
    render(state);
  };

  const updateScroll = (e) => {
    const state = ctx.getState();
    state.week[date].scroll = choozy(node).dayBlock.scrollLeft;
    ctx.hydrate({ week: state.week });
  };

  const render = (state) => {
    if (loading) {
      node.innerHTML = `
      <div class="day-block day-block--disabled js-dayBlock">
        <div class="time-block time-block--disabled">
          <label>${loader}</label>
        </div>
        <div class="time-block time-block--disabled o80">
          <label>${loader}</label>
        </div>
        <div class="time-block time-block--disabled o60">
          <label>${loader}</label>
        </div>
        <div class="time-block time-block--disabled o40">
          <label>${loader}</label>
        </div>
        <div class="time-block time-block--disabled o20">
          <label>${loader}</label>
        </div>
      </div>
      `;
    } else if (!state.week[date].length) {
      node.innerHTML = `
      <div class="day-block day-block--disabled js-dayBlock">
        <div class="time-block time-block--sold-out">
          <label>Sold Out</label>
        </div>
        <div class="time-block time-block--sold-out o80">
          <label>Sold Out</label>
        </div>
        <div class="time-block time-block--sold-out o60">
          <label>Sold Out</label>
        </div>
        <div class="time-block time-block--sold-out o40">
          <label>Sold Out</label>
        </div>
        <div class="time-block time-block--sold-out o20">
          <label>Sold Out</label>
        </div>
      </div>
      `;
    } else {
      const today = new Date().toISOString();
      node.innerHTML = `
      <div class="day-block js-dayBlock">
        ${state.week[date].map(({ id, startTime }) => {
          const isLastCall = (Math.abs(Date.parse(startTime)-Date.parse(today)) / 36e5) <= lastCallRange && lastCallEnabled;
          const time = formatTimeForDisplay(startTime, tz);
          const checked = state.time && id === state.time.ID;

          // Hide time slot if DateStartTime is greater than day start time
          if (bookingRangeEnabled && (Date.parse(dateStartTime) > Date.parse(startTime))) { return null; }

          return `
                  <div class="time-block rel ba bw1 ${isLastCall ? `last-call` : ''}">
                    <input type="radio" id="${`time${id}`}" ${checked ? 'checked' : ''} name="time" value="${id}" data-time="${startTime}" data-last-call="${isLastCall}" class="js-timeOption">
                    <label for="${`time${id}`}">${time}</label>
                    ${isLastCall ? `<span class="abs bg-black bottom c-white f12 i left x ttu pen">${lastCallLabel}</span>` : ''}
                  </div>
                  `;
        }).join('')}
      </div>`;

      if (isLastCallDay && lastCallEnabled) {
        node.innerHTML += `<div class="rte f12 pv10">${lastCall.innerHTML}</div>`;
      }
      if (choozy(node).timeOption) on(choozy(node).timeOption, 'change', updateSelectedTime);
      on(choozy(node).dayBlock, 'scroll', updateScroll);

      const dayBlock = choozy(node).dayBlock;
      dayBlock.scrollTo({ left: state.week[date].scroll, behavior: 'instant'});
    }
  };

  ctx.on('bookingTimeUpdate', (state) => {
    const nodeExists = document.body.contains(node);
    if (!nodeExists) return;
    const nextDate = Object.keys(state.week).find(day => !state.week[day]);
    if (nextDate === date && loading) getTimes(date);
  });

  const init = () => {
    const state = ctx.getState();
    const firstDate = Object.keys(state.week).shift();
    if (firstDate === date) {
      getTimes(date);
    } else if (state.week[date]) {
      getTimes(date);
    }
    render(state);
  };

  init();
});
