import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';

export default component((node, ctx) => {
  let preferencesForm = null;
  let subscriptions = null;
  const API_URL = process.env.BACKEND_API_URL || 'https://api.ephemeral.tattoo';
  const urlParams = new URLSearchParams(window.location.search);
  const e = urlParams.get('e');
  const landingText = node.getAttribute('data-landing-text');
  const savedText = node.getAttribute('data-saved-text');
  const notFoundText = node.getAttribute('data-not-found-text');
  const hiddenLists = node.getAttribute('data-hidden-lists').split(',');
  ctx.hydrate({ view: 'loading' });

  const filterHidden = async (subs) => {
    return subs.channels.map(({ messageTypes }) => messageTypes.filter(list => !~hiddenLists.indexOf(`${list.id}`))).filter(list => list.length);
  };

  const getSubscriptions = async () => {
    const rawResponse = e ? await fetch(`${API_URL}/api/subscription?e=${e}`, {
      method: 'GET'
    }) : false;
    const allSubscriptions = rawResponse ? await filterHidden(await rawResponse.json()) : false;
    subscriptions = allSubscriptions.flat();
    ctx.emit('preferencesUpdate', { view: 'form' });
  };

  const addLists = async (subs) => {
    subs.map(async sub => {
      const rawResponse = await fetch(`${API_URL}/api/subscription?e=${e}&subscription_group=messageType&subscription_group_id=${sub.value}`, {
        method: 'POST',
        body: JSON.stringify({
          e,
          subscription_group: 'messageType',
          subscription_group_id: parseFloat(sub.value)
        })
      });
    });
  };

  const removeLists = async (subs) => {
    subs.map(async sub => {
      const rawResponse = await fetch(`${API_URL}/api/subscription?e=${e}&subscription_group=messageType&subscription_group_id=${sub.value}`, {
        method: 'DELETE',
        body: JSON.stringify({
          e,
          subscription_group: 'messageType',
          subscription_group_id: parseFloat(sub.value)
        })
      });
    });
  };

  const submit = (e) => {
    e.preventDefault();
    const addedLists = [];
    const removedLists = [];

    preferencesForm.querySelectorAll('input[type=checkbox]').forEach((input) => {
      const currentList = subscriptions.find(channel => `${input.value}` === `${channel.id}`);
      const subscribed = currentList.subscribed;
      if (input.checked !== subscribed) {
        if (subscribed) {
          removedLists.push(input);
        } else {
          addedLists.push(input);
        }
        currentList.subscribed = !subscribed;
      }
    });

    ctx.emit('preferencesUpdate', { view: 'loading' });
    removeLists(removedLists);
    addLists(addedLists);
    ctx.emit('preferencesUpdate', { view: 'thanks' });
  };

  ctx.on('preferencesUpdate', state => {
    if (state.view === 'loading') {
      node.innerHTML = `<p>Loading...</p>`;
    } else if (state.view === 'thanks') {
      node.innerHTML = `<p>${savedText}</p>`;
    } else if (!e || !subscriptions || !subscriptions.length) {
      node.innerHTML = `<p>${notFoundText}</p>`;
    } else {
      node.innerHTML = `
        <p>${landingText}</p>
        <form action="/preferences" class="js-preferencesForm mt30">
          ${subscriptions.map(({ name, id, subscribed }) => {
            const checked = subscribed ? 'checked' : '';
            return `
                  <div class="checkbox pb20">
                    <input type="checkbox" id=${`channel${id}`} name=${`channel${id}`} value=${id} ${checked}>
                    <label for=${`channel${id}`}>${name}</label>
                  </div>
                  `;
          }).join('')}
          <input type="submit" class="bc-black bg-black btn c-white dib x notranslate pea mono f18 lsn04em ttu fw700" value="Update Preferences" />
        </form>
      `;
      preferencesForm = choozy(node).preferencesForm;
      on(preferencesForm, 'submit', submit);
    }
  });
  getSubscriptions();

});