import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import validate from 'validate.js';
const API_URL = process.env.BACKEND_API_URL || 'https://api.ephemeral.tattoo';

export const formatDateForBlvd = (date) => {
  return `${date.toISOString().slice(0, 10)}`;
};

export const formatDateForDisplay = (date, tz = 'America/New_York') => {
  return new Date(date).toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: tz,
  });
};

export const formatTimeForDisplay = (date, tz = 'America/New_York') => {
  return new Date(date).toLocaleTimeString('en-us', {
    hour: 'numeric',
    minute: '2-digit',
    timeZone: tz,
  });
};

export const formatWeekForDisplay = (date, tz = 'America/New_York') => {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    timeZone: tz,
  });
};

export const formatShortDateTimeForDisplay = (
  date,
  tz = 'America/New_York',
) => {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZone: tz,
  });
  // Reformat from iOS safari format which breaks addtocalendar
  return formattedDate.replace(' at', ',');
};

export const formatDayForDisplay = (date, tz = 'America/New_York') => {
  return dmyToDate(date).toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    timeZone: tz,
  });
};

/**
 * Converts a DMY (30-12-2022) format to a date object
 * @param {date} string - (required) DMY string
 * @returns {Date} Date - Javascript date object
 */
export const dmyToDate = (date) => {
  return new Date(`${date}T12:00:00`);
};

/**
 * Converts a date string to YMD format (12-30-2022)
 * @param {date} string - (required) date string
 * @returns {Date} Date - DMY string
 */
export const dateToDmy = (date) => {
  const formatted = new Date(date);
  return `${formatted.getFullYear()}-${String(
    formatted.getMonth() + 1,
  ).padStart(2, '0')}-${String(formatted.getDate()).padStart(2, '0')}`;
};

/**
 * Converts a MDY (12-30-2022) format to a date object
 * @param {date} string - (required) MDY string
 * @returns {Date} Date - Javascript date object
 */
export const mdyToDate = (date) => {
  return new Date(`${date} 12:00:00`);
};

export const loader = `<div class="aic df jcc x y"><img class="bg-white br40 p15" src="/assets/images/hourglass.gif"></div>`;

export const trackEvent = (
  category = false,
  action = false,
  label = false,
  value = false,
) => {
  window.dataLayer = window.dataLayer || [];
  const data = { event: 'customEvent' };
  if (category) data['eventCategory'] = titleCase(category);
  if (action) data['eventAction'] = titleCase(action);
  if (label) data['eventLabel'] = titleCase(label);
  if (value) data['eventValue'] = value; // Only accepts numbers per GA docs
  dataLayer.push(data);
};

/**
 * Use this API to send custom events and associated metadata
 * that Heap does not automatically capture, such as purchase information, email sent and more.
 * @param {eventName} string - (required) event name: name of the custom interation - limited to 255 characters.
 * @param {eventProperties} object - (optional) event properties: a JSON object / key-value pairs to be associated with an event.
 * @returns {void}
 */
export const trackHeapEvent = (eventName, eventProperties = null) => {
  if (typeof window.heap === 'undefined') {
    setTimeout(() => {
      trackHeapEvent(eventName, eventProperties);
    }, 250);
    return;
  }

  if (
    eventName &&
    eventName !== undefined &&
    typeof window?.heap?.track === 'function'
  ) {
    window.heap.track(eventName, eventProperties);
  }
};

/**
 * Sends a client public id to heap to identify user
 * @param {id} string - client's public id
 * @returns {void}
 */
export const heapIdentifyUser = (id = null) => {
  if (typeof window.heap === 'undefined') {
    setTimeout(() => {
      heapIdentifyUser(id);
    }, 250);
    return;
  }

  if (id && id !== undefined) {
    window.heap.identify(id);
  }
};

/**
 * Use this API to send custom properties
 * that Heap does not automatically capture like reservation ID
 * @param {name} string - (required) name: name of the custom property
 * @param {value} string - (required) value: value of the custom property
 * @returns {void}
 */
export const trackHeapProperty = (name, value) => {
  if (typeof window.heap === 'undefined') {
    setTimeout(() => {
      trackHeapProperty(name, value);
    }, 250);
    return;
  }

  if (name && name !== undefined && typeof window?.heap?.track === 'function') {
    const obj = {};
    obj[`${name}`] = `${value}`;
    window.heap.addEventProperties(obj);
  }
};

export const titleCase = (str) => {
  return str
    ? str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase())
    : false;
};

export const setCache = (state) => {
  sessionStorage.setItem('booking', JSON.stringify(state));
};

export const getCache = (name = false) => {
  const cache = sessionStorage.getItem('booking');
  return cache ? (name ? JSON.parse(cache)[name] : JSON.parse(cache)) : false;
};

export const throttle = (fn, wait) => {
  let previouslyRun, queuedToRun;

  return function invokeFn(...args) {
    const now = Date.now();
    queuedToRun = clearTimeout(queuedToRun);

    if (!previouslyRun || now - previouslyRun >= wait) {
      fn.apply(null, args);
      previouslyRun = now;
    } else {
      queuedToRun = setTimeout(
        invokeFn.bind(null, ...args),
        wait - (now - previouslyRun),
      );
    }
  };
};

export const escape = (str) => str.replace(/"/g, '\\"');

export const scrollUp = (node) => {
  gsap.registerPlugin(ScrollToPlugin);
  const header = document.querySelector('header');
  const offset = header ? header.offsetHeight : 0;
  gsap.to(window, { duration: 0.01, scrollTo: { y: node, offsetY: offset } });
};

export const scrollPast = (node) => {
  gsap.registerPlugin(ScrollToPlugin);
  const header = document.querySelector('header');
  const offset = header ? header.offsetHeight : 0;
  gsap.to(window, {
    duration: 0.01,
    scrollTo: { y: node, offsetY: offset - node.offsetHeight },
  });
};

export const slugify = (str, separator = '-') => {
  return str
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .trim()
    .replace(/\s+/g, separator);
};

/**
 * @param {HTMLElement} element
 * @returns {number} percent of element in view
 */
export const getPercentInView = (el) => {
  const viewTop = window.pageYOffset;
  const viewBottom = viewTop + window.innerHeight;
  const rect = el.getBoundingClientRect();
  const elementTop = rect.top + viewTop;
  const elementHeight = rect.height;
  const elementBottom = elementTop + elementHeight;

  if (viewTop < elementTop) {
    return 0;
  } else if (viewTop > elementTop + elementHeight) {
    return 100;
  } else if (viewTop > elementTop) {
    return ((viewTop - elementTop) / elementHeight) * 100;
  }
};

/**
 * Returns a new date object with added hours added to original date
 * @param {Object} date - date object that we want to add hours to
 * @param {number} h - the amount of hours we want to add to date object
 * @returns {date}
 */
export const addHours = (date, h) => {
  const result = new Date(date);
  result.setTime(result.getTime() + h * 60 * 60 * 1000);

  return result;
};

/**
 * Returns a new date object with added days added to original date
 * @param {Object} date - date object that we want to add days to
 * @param {number} days - the amount of days we want added to date object
 * @returns {date}
 */
export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);

  return result;
};

/**
 * Checks to see if string is base64
 * @param {string}
 * @returns {boolean} - indicating whether string is base64
 */
export const isStringBase64 = (str) => {
  if (str === undefined || str === '' || str.trim() === '') {
    return false;
  }

  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
};

/**
 * This function tracks Tik Tok Checkout View
 * @returns {void}
 */
export const trackTikTokCheckout = () => {
  if (typeof window?.ttq?.track === 'function') {
    window?.ttq.track('InitiateCheckout', { value: 0 });
  }
};

/**
 * This function tracks Tik Tok Conversion
 * @returns {void}
 */
export const trackTikTokConversion = () => {
  if (typeof window?.ttq?.track === 'function') {
    window?.ttq.track('CompleteRegistration', { value: 0 });
  }
};

/**
 * This function tracks internal gtag (Google tag manager) conversion
 * @returns {void}
 */
export const trackGTMConversion = () => {
  if (typeof window?.gtag === 'function') {
    window?.gtag('event', 'conversion');
  }
};

/**
 * This function tracks external gtag (Google tag manager) conversion
 * @returns {void}
 */
export const trackExternalGTMConversion = () => {
  const EXTERNAL_GTM_ID = process.env.EXTERNAL_GTM_ID || null;

  if (typeof window?.gtag === 'function' && EXTERNAL_GTM_ID) {
    window?.gtag('event', 'conversion', {
      send_to: EXTERNAL_GTM_ID,
    });
  }
};

/**
 * This function tracks Pinterest conversion
 * @returns {void}
 */
export const trackPinterestConversion = () => {
  if (typeof window?.pintrk === 'function') {
    window?.pintrk('track', 'checkout');
  }
};

/**
 * This function calls a gtag custom event to track facebook conversion
 * @returns {void}
 */
export const trackFbConversion = () => {
  if (typeof window?.gtag === 'function') {
    window?.gtag('event', 'FB-conversion');
  }
};

/**
 * This function calls all of the paid conversion events
 * @returns {void}
 */
export const trackPaidConversion = async () => {
  trackFbConversion();
  trackExternalGTMConversion();
  trackPinterestConversion();
  trackTikTokConversion();
  trackGTMConversion();
};

/**
 * Sends client info to the abandon checkout API endpoint
 * @param {rpId} string - client's reservation ID
 * @param {email} string - client's email
 * @param {location} string - client's location
 * @param {serviceType} string - client's service type
 * @param {serviceType} string - client's service type
 * @returns {void}
 */
export const sendAbandonCartEmail = async (
  rpId,
  email,
  location,
  serviceType,
) => {
  const opts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      l: location === 'location-other' ? undefined : location,
      s: serviceType,
      rp_id: rpId,
    }),
  };

  const abandonRes = await fetch(`${API_URL}/api/abandon_checkout`, opts);
  const { success, client_public_id } = await abandonRes.json();

  if (success) {
    heapIdentifyUser(client_public_id);
  }
};

/**
 * Sends an email to our validation endpoint
 * @param {email} string - client's email
 * @returns {string} - string of error message, or false if the validation passes
 */
export const validateEmail = async (email) => {
  const valid = !email
    ? false
    : await fetch(`${API_URL}/api/validate_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      })
        .then(function (result) {
          return result.json();
        })
        .catch((error) => {
          return false;
        });

  return new validate.Promise(function (resolve) {
    if (!valid) resolve(); // API failed, allow it to pass
    if (valid.code !== 200) resolve(); // API failed, allow it to pass
    if (valid.body.result !== 'undeliverable') resolve(); // Email is valid
    resolve('is not valid'); // Email is not valid
  });
};

/**
 * Shuffles an array into a random order
 * @param {array}
 * @returns {array} - shuffled array
 */
export const shuffle = (arr) => {
  return arr
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
};

/**
 * Formats address data into a natural string
 * @param {address} object - object with address data
 * @returns {string} - string of error message, or false if the validation passes
 */
export const formatAddress = (address) => {
  return [
    address?.line1,
    address?.line2,
    address?.city,
    address?.state,
    address?.zip,
  ]
    .filter((i) => !!i)
    .join(', ');
};

/**
 * returns days of the week from date object
 * @param {date} - date object
 * @returns {string} - string representing day of the week
 */
export const getDayOfWeek = (date) => {
  return date.toLocaleString('en-us', { weekday: 'long' });
};

/**
 * returns service label
 * @param {state} - state object
 * @returns {string} - string representing service name for booking
 */
export const getServiceLabel = (state) => {
  let serviceStr = state?.service?.title || state?.service?.name;

  if (state.productInfo?.name && serviceStr) {
    serviceStr = `${serviceStr} - ${state.productInfo?.name}`;
  }

  return serviceStr;
};

/**
 * returns price message
 * @param {state} - state object
 * @returns {string} - string representing price being paid for reservation
 */
export const getPrice = (state) => {
  let priceMsg;

  if (state.productInfo?.price && state?.service?.price) {
    const priceStr = (state?.productInfo?.price / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    if (state?.service?.price?.includes(`{price}`)) {
      priceMsg = state?.service?.price;
      priceMsg = priceMsg.replace('{price}', priceStr);
    } else {
      priceMsg = priceStr;
    }
  } else if (state?.service?.price) {
    priceMsg = state?.service?.price;
  } else {
    priceMsg = 'Price TBD';
  }

  return priceMsg;
};
