import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';

export default component((node, ctx) => {
  let { toggle, content } = choozy(node);

  let initToggle = on(toggle, 'click', () => {
    if (content.style.maxHeight === 'none') {
      content.style.maxHeight = '0px';
      toggle.classList.remove('dn');
    } else {
      content.style.maxHeight = 'none';
      toggle.classList.add('dn');
    }
  });

  return () => {
    initToggle();
  };
});
