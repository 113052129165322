import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { component } from 'picoapp';
import { 
  getTimesForDate,
  getAvailableDates
} from '@/util/boulevard';
import {
  loader, 
  trackEvent, 
  trackHeapEvent, 
  formatTimeForDisplay, 
  formatDayForDisplay,
  formatDateForBlvd,
  dmyToDate
} from '@/util/utils';

export default component((node, ctx) => {
  const state = ctx.getState();
  const locationSettings = choozy(state.node).locationSetting;
  const locationSettingEl = locationSettings.find(location => JSON.parse(location.dataset.locationSetting).location.slug === state.location.externalId);
  const discountDayMessage = choozy(locationSettingEl).locationSettingDiscountDayMessage;
  const discountDate = node.dataset.discountDate === 'true';
  const discountDayLabel = node.dataset.discountDayLabel;
  const bookingRangeEnabled = node.dataset.enableBookingRange === 'true';
  const bookingRange = bookingRangeEnabled ? parseInt(node.dataset.bookingRange) : false;
  const dateStartTime = node.dataset.startTime;
  const endDate = node.dataset.endDate;
  const date = node.dataset.date;

  const lastCallEnabled = node.dataset.enableLastCall === 'true';
  const lastCallVisualEnabled = node.dataset.enableLastCallVisual === 'true';
  const lastCall = choozy(ctx.getState().node).lastCall;
  const lastCallRange = parseInt(lastCall.getAttribute('data-last-call-range')) || 0;
  const lastCallLabel = lastCall.getAttribute('data-last-call-label') || 'Last Call!';
  const noAppointments = ctx.getState().node.dataset.noAppointments;
  const noAppointmentsNextCTA = ctx.getState().node.dataset.noAppointmentsNextCta?.length ? ctx.getState().node.dataset.noAppointmentsNextCta : 'Go To Next Appointment';
  const tz = ctx.getState().location.tz;
  let loading = true;

  const getTimes = async (date) => {
    let state = ctx.getState();

    // Get times for a date and update the state
    const times = await getTimesForDate(state.cartId, date, tz);
    if (times?.data?.cartBookableTimes) {
      const sortedTimes = times.data.cartBookableTimes.sort((a, b) => (new Date(a.startTime) > new Date(b.startTime)) ? 1 : ((new Date(b.startTime) > new Date(a.startTime)) ? -1 : 0));
      ctx.hydrate({ times: sortedTimes });
    }

    // Recheck state in case week changed while waiting, only proceed if date still exists in the latest week
    state = ctx.getState();
    if (state.day === date) {
      loading = false;
      ctx.emit('bookingTimeUpdate', state);
    }
  };

  const updateSelectedTime = (e) => {
    const state = ctx.getState();
    state.time = { name: e.target.getAttribute('data-time'), ID: e.target.value, last_call: e.target.getAttribute('data-last-call') === 'true' };
    ctx.hydrate({ time: state.time });
    ctx.emit('bookingButtonUpdate', state);
    ctx.emit('bookingUpdateWeekTimeUpdate', state);
    
    trackEvent('booking', `${state.view}`, `click time ${state.time.name}`);
    trackHeapEvent('Clicked Appointment Time Slot', {
      date: date, 
      time: formatTimeForDisplay(state.time.name, tz),
      dow: dmyToDate(date).toLocaleDateString('en-US', { weekday: 'long' })
    });
  };

  const goToNext = async (e) => {
    loading = true;
    const state = ctx.getState();
    ctx.emit('bookingTimeUpdate', state);

    let findStartDate = dmyToDate(date);
    findStartDate.setDate(findStartDate.getDate() + 1);
    findStartDate = formatDateForBlvd(findStartDate);

    let findEndDate;
    if (bookingRangeEnabled) {
      findEndDate = new Date();
      findEndDate.setDate(new Date().getDate() + bookingRange);
    } else {
      findEndDate = dmyToDate(endDate);
    }
    findEndDate = formatDateForBlvd(findEndDate);
    let nextAvailableDate = await getBookingAvailability(findStartDate, findEndDate, 1);

    if (!nextAvailableDate?.length) {
      nextAvailableDate = findEndDate;
    }
    state.day = nextAvailableDate;
    ctx.emit('bookingUpdateWeekDay', state);
  };

  const getBookingAvailability = async (start, end, limit) => {
    const state = ctx.getState();

    const available = await getAvailableDates(
      state.cartId,
      start,
      end,
      limit,
    );

    return (available?.data?.cartBookableDates || []).map(({ date }) => date).shift();
  };

  const onMount = () => {
    const { timeOption, nextAvailable } = choozy(node);
    timeOption && on(timeOption, 'click', updateSelectedTime);
    nextAvailable && on(nextAvailable, 'click', goToNext);
    ctx.emit('bookingResize');
  };

  const render = (state) => {
    // Filter out times before than DateStartTime
    const times = state.times?.filter(({ id, startTime }) => (
      !bookingRangeEnabled || (Date.parse(startTime) > Date.parse(dateStartTime))
    ));

    if (loading) {
      node.innerHTML = `
        <label>${loader}</label>
      `;
    } else if (!times?.length) {
      node.innerHTML = `
        <p class="f16 tl mb40">${noAppointments.replace('{date}', formatDayForDisplay(state.day, tz))}</p>
        <button class="js-nextAvailable btn bg-oblack c-white bc-white f18 ass btn-full mt10 ttu lsn04em fw700 mono" value="${noAppointmentsNextCTA}">${noAppointmentsNextCTA}</button>
      `;
    } else {
      let isLastCallDay = false;
      let isDiscountdate = false;
      const today = new Date().toISOString();
      node.innerHTML = `
      <div class="day-block js-dayBlock">
        ${times?.map(({ id, startTime }) => {
          const isLastCall = (Math.abs(Date.parse(startTime)-Date.parse(today)) / 36e5) <= lastCallRange && lastCallEnabled;
          const showLastCall = isLastCall && lastCallVisualEnabled;
          const time = formatTimeForDisplay(startTime, tz);
          const checked = state.time && id === state.time.ID;
          if (isLastCall && showLastCall) isLastCallDay = true;
          if (discountDate) isDiscountdate = true;

          return `
                  <button class="time-block bg-black c-white rel dib tc mono js-timeOption ${discountDate ? `discount-date` : '' } ${showLastCall ? `last-call` : ''}" value="${id}" data-last-call="${isLastCall}" data-time="${startTime}">
                    <label for="${`time${id}`}" class="pen">${time}</label>
                    ${showLastCall ? `<span class="abs bg-bud bottom c-black f12 sans left tc x ttu pen">${lastCallLabel}</span>` : ''}
                    ${isDiscountdate ? `<span class="abs bg-bud bottom c-black f12 sans left tc x ttu pen">${discountDayLabel}</span>` : ''}
                  </button>
                  `;
        }).join('')}
      </div>`;

      if (isLastCallDay) {
        node.innerHTML += `<div class="rte f12 pv10">${lastCall.innerHTML}</div>`;
      }

      if (discountDate) {
        node.innerHTML += `<div class="rte f12 pv10">${discountDayMessage.innerHTML}</div>`;
      }
    }
    onMount();
  };

  ctx.on('bookingTimeUpdate', (state) => {
    const nodeExists = document.body.contains(node);
    if (!nodeExists) return;
    render(state);
  });

  const init = () => {
    const state = ctx.getState();
    getTimes(date);
    render(state);
  };

  init();
});
