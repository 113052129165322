import app from '@/app';
import Highway from '@dogstudio/highway';

// renderers
import Base from '@/renderers/Base';
import Booking from '@/renderers/Booking';

// transitions
import Fade from '@/transitions/Fade';

// pollyfills
import 'eligrey-classlist-js-polyfill';

import sentryInit from './util/sentry.js';

class Core extends Highway.Core {
  constructor() {
    super({
      renderers: {
        default: Base,
      },
      transitions: {
        default: Fade,
      },
    });
  }
}

const H = new Core();

const back = document.querySelector('.js-back');
let fromUrl;

H.on('NAVIGATE_END', handleNavigateEnd);

function handleNavigateEnd({ location, from }) {
  back.removeEventListener('click', handleBackClick);
  window.history.replaceState({ prevUrl: from.page.URL }, document.title, window.location.href);

  if (location.anchor) {
    const offset = document.querySelector('header').offsetHeight;
    const el = document.querySelector(location.anchor);

    if (el) {
      window.scrollTo(el.offsetLeft, el.offsetTop - offset);
    }
  }

  if (back) {
    handleBackRedirect();
  }
}

function handleBackRedirect() {
  back.addEventListener('click', handleBackClick);
}

function handleBackClick(e) {
  e.preventDefault();
  if (window.history.state?.prevUrl) {
    H.redirect(window.history.state.prevUrl);
  } else if(window.history.state?.view) {
    app.emit('bookingBack');
  }
}

window.addEventListener('load', () => {
  const offset = document.querySelector('header').offsetHeight;
  const target = window.location.hash;
  const cache = target?.replace('#', '');

  if (target) {
    const el = document.getElementById(cache);
    el && window.scrollTo(el.offsetLeft, el.offsetTop - offset);
  }

  if (back) {
    handleBackRedirect();
  }

  setScrollY();
  sentryInit();
});

function setScrollY() {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
}

document.addEventListener('scroll', setScrollY);