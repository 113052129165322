import choozy from 'choozy';
import { component } from 'picoapp';
import { async } from 'validate.js';
import { heapIdentifyUser } from '@/util/utils';

export default component((node) => {
  const API_URL = process.env.BACKEND_API_URL || 'https://api.ephemeral.tattoo';

  const postConfirmationAndRetrieveClientPublicId = async (appointmentData) => {
    const response = await fetch(`${API_URL}/api/appointments/confirm`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(appointmentData),
    });

    const data = await response.json();

    return data?.client_public_id;
  };

  const init = async () => {
    const search = new URLSearchParams(window.location.search);
    const appointmentHash = search.get('a') ? search.get('a') : null;
    const appointmentData = {
      appointment_id: appointmentHash
    };

    const clientPublicId = await postConfirmationAndRetrieveClientPublicId(appointmentData);
    heapIdentifyUser(clientPublicId);
  };

  init();
});
