// import Player from '@vimeo/player';
import { component } from 'picoapp';
import { on } from '@selfaware/martha';
import choozy from 'choozy';

const getVideoID = (url) => {
  if (!url) return undefined;

  const u = new URL(url);

  return u.pathname.includes('video') ? parseInt(u.pathname.split('/video/')[1]) : parseInt(u.pathname.slice(1), u.pathname.length);
};

let timeout;

const MEDIUM_BREAKPOINT = 850;
const DESKTOP = 'desktop';
const MOBILE = 'mobile';

const getVideoType = () => {
  return document.body.clientWidth >= MEDIUM_BREAKPOINT ? DESKTOP : MOBILE;
};

const getVideoSize = ($video) => {
  const $iframe = getVideoType() === 'desktop' ? choozy($video).desktopVideo : choozy($video).mobileVideo || choozy($video).desktopVideo;

  if (!$iframe) return {};

  const $container = $video.parentNode;

  const originalWidth = parseInt($iframe.getAttribute('width'), 10);
  const originalHeight = parseInt($iframe.getAttribute('height'), 10);
  const scaleH = $container.offsetWidth / originalWidth;
  const scaleV = $container.offsetHeight / originalHeight;
  const scale = scaleH > scaleV ? scaleH : scaleV;

  const width = originalWidth * scale;
  const height = originalHeight * scale;

  return { width, height };
};

const initVimeoPlayer = (node, id, params) => {
  // const instance = new Player(node, Object.assign(params, {
  //   id,
  //   background: true,
  //   quality: '240p'
  // }));

  // instance.on('loaded', () => {
  //   const { width, height } = getVideoSize(node);

  //   node.style.width = `${width}px`;
  //   node.style.height = `${height}px`;
  // });

  // return instance;
    const { width, height } = getVideoSize(node);
    node.style.width = `${width}px`;
    node.style.height = `${height}px`;
};

export default component((node) => {
  const desktopURL = node.getAttribute('data-url-desktop');
  const mobileURL = node.getAttribute('data-url-mobile');
  let player = null;

  if (!desktopURL) {
    console.warn('[Video] Missing Video URL');
    return;
  }

  let videoType = getVideoType();
  const id = {
    [DESKTOP]: getVideoID(desktopURL),
    [MOBILE]: getVideoID(mobileURL),
  };
  const params = {
    [DESKTOP]: Object.fromEntries(new URL(desktopURL).searchParams),
    [MOBILE]: Object.fromEntries(new URL(mobileURL).searchParams),
  };

  player = initVimeoPlayer(node, id[videoType] || id[DESKTOP], params[videoType] || params[DESKTOP]);

  const onResize = () => {
    if (timeout) window.cancelAnimationFrame(timeout);

    timeout = window.requestAnimationFrame(() => {
      // const nextVideoType = getVideoType();

      // if (nextVideoType !== videoType) {
      //   videoType = nextVideoType;
      //   player.destroy();
      //   player = initVimeoPlayer(node, id[nextVideoType]);
      // }

      const { width, height } = getVideoSize(node);
      node.style.width = `${width}px`;
      node.style.height = `${height}px`;
    });
  };

  on(window, 'resize', onResize);
});
