import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import validate, { isEmpty } from 'validate.js';
import { 
  validateEmail,
  heapIdentifyUser, 
  trackHeapEvent, 
  trackHeapProperty,
} from '@/util/utils';

export default component((node) => {
  const API_URL = process.env.BACKEND_API_URL || 'https://api.ephemeral.tattoo';

  let {
    designNowForm,
    submitButton,
    email: emailInput,
    errors: errorsWrapper,
  } = choozy(node);

  let typeformID = node.getAttribute('data-typeform-id') || null;
  let optionHeapName = node.getAttribute('data-option-heap-name') || null;
  const showQuick = node.getAttribute('data-show-quick') || null;
  const showService = node.getAttribute('data-show-service') || null;
  const bookingPage = node.getAttribute('data-booking-page');

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let serviceParam = urlParams.get('s');
  serviceParam = serviceParam ? decodeURI(serviceParam) : null;

  // Collection and Flash
  const collection = node.getAttribute('data-collection') === 'true';
  const collectionName = node.getAttribute('data-collection-name') || null;
  const collectionFlashName =
    node.getAttribute('data-collection-flash-name') || null;
  const collectionFlashUrl =
    node.getAttribute('data-collection-flash-url') || null;
  const collectionFlashServiceType =
    node.getAttribute('data-collection-flash-service-type') || null;

  const setButtonState = (buttonElement, loading) => {
    if (loading) {
      buttonElement.classList.add('btn-loading');
      buttonElement.disabled = true;
    } else {
      buttonElement.classList.remove('btn-loading');
      buttonElement.disabled = false;
    }
  };

  const setErrors = (errors = []) => {
    errorsWrapper.classList.add('dn');
    errorsWrapper.innerHTML = '';

    if (errors?.length) {
      for (let i = 0; i < errors.length; i += 1) {
        const el = document.createElement('span');
        el.innerHTML = errors[i];
        errorsWrapper.appendChild(el);
      }
      errorsWrapper.classList.remove('dn');
    }
  };

  const formValidator = async (form) => {
    let errors;

    // Email validator
    if (emailInput) {
      validate.validators.emailVerification = validateEmail;

      // Check basic validators
      errors = validate(
        form,
        {
          email: {
            presence: {
              message: 'E-Mail can’t be blank',
            },
            email: true,
          },
        },
        { fullMessages: false },
      );

      // Check email validator
      validate.async.options = { cleanAttributes: false };
      await validate
        .async(form, {
          email: {
            emailVerification: true,
          },
        })
        .then(
          () => {
            // Success
          },
          (res) => {
            // Error
            errors = !errors ? res : Object.assign(errors, res);
          },
        );
    }

    if (!errors || isEmpty(errors)) return false;

    const messages = Object.keys(errors).reduce(
      (acc, current) => [...acc, ...errors[current]],
      [],
    );

    setErrors(messages);
    return true;
  };

  const redirect = (link) => {
    window.location.href = link;

    return false;
  };

  const createPendingReservation = async (email, studio, design = {}) => {
    let pendingReservationObj = {
      l: studio,
      ...(email && { email }),
      ...(typeformID && { tf_id: typeformID }),
    };

    // Add Design to params if it contains value
    if (Object.keys(design).length > 0) {
      pendingReservationObj = {
        ...pendingReservationObj,
        design,
      };
    }

    const response = await fetch(`${API_URL}/api/reservation`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pendingReservationObj),
    });

    const data = await response.json();

    return {
      redirectLink: data?.survey_link,
      bookingLink: data?.booking_link,
      clientPublicId: data?.client_id,
      reservationId: data?.reservation_id
    };
  };

  const retrieveFormData = (form) => {
    const formData = new FormData(form);
    const email = formData.get('email');
    let studio = formData.get('studio');
    studio = studio === 'location-other' ? '' : studio;
    const description = formData.get('description');
    const data = { email, studio };

    if (showService) {
      data.design = { s: formData.get('option') };
    } else if (description) {
      data.design = { description, has_description: true };
    } else if (collection) {
      data.design = {
        collection: collectionName,
        flashName: collectionFlashName,
        flash: collectionFlashUrl,
        type: collectionFlashServiceType,
      };
    }

    return data;
  };

  const signUp = async () => {
    const { email, studio, design } = retrieveFormData(designNowForm);
    const {
      redirectLink,
      bookingLink,
      clientPublicId,
      reservationId,
    } = await createPendingReservation(email, studio, design);

    heapIdentifyUser(clientPublicId);
    trackHeapProperty('reservation_id', reservationId);

    if (collection && collectionName && collectionFlashName) {
      trackHeapEvent('Submitted Flash Design', {
        flash_name: collectionFlashName,
        collection: collectionName,
      });
    } else {
      trackHeapEvent('Started Design Survey', {
        journey_path: optionHeapName,
        typeform_id: typeformID
      });
    }

    const bookingLinkParams = new URL(bookingLink)?.searchParams;
    redirect(collection ? `${bookingPage}?${bookingLinkParams}` : redirectLink);
  };

  if (emailInput) {
    on(emailInput, 'keyup', (e) => {
      e.target.value = e.target.value.toLowerCase();
    });
  }

  if (showQuick || showService) {
    on(choozy(node).optionSelector, 'change', (ev) => {
      if (!showService) {
        typeformID = ev.target.value;
      }
      optionHeapName = ev.target.getAttribute('data-option-heap-name');
      trackHeapEvent(`Click - ${optionHeapName}`);
    });
  }

  if (serviceParam) {
    const optionSelector = choozy(node).optionSelector;
    const options = choozy(optionSelector).option;
    options.forEach(option => {
      if (option.value.toLowerCase().trim() === serviceParam.toLowerCase().trim()) {
        option.checked = true;
      }
    });
  }

  on(designNowForm, 'submit', async (e) => {
    e.preventDefault();
    setButtonState(submitButton, true);
    setErrors();

    const hasValidationErrors = await formValidator(node);

    if (hasValidationErrors) {
      setButtonState(submitButton, false);

      return false;
    }

    signUp();
  });

  // Refresh the page if coming from browser back button to prevent hanging form submit
  window.onpageshow = function (e) {
    if (e.persisted) {
      window.location.reload();
    }
  };
});