import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import Player from '@vimeo/player';

export default component((node, ctx) => {
  let {
    uiBar,
    playDesktop,
    playMobile,
    desktopVideo,
    mobileVideo,
    playMobilePlay,
    playMobilePause,
    playDesktopPlay,
    playDesktopPause,
    mobileVideoDroid,
  } = choozy(node);

  if (desktopVideo) {
    const toggleDesktopVideo = () => {
      desktopVideo.classList.add('z2');
      desktopPlayer.getPaused().then((paused) => {
        if (paused) {
          desktopPlayer.play();
          playDesktopPlay.classList.add('dn');
          playDesktopPause.classList.remove('dn');
        } else {
          desktopPlayer.pause();
          playDesktopPlay.classList.remove('dn');
          playDesktopPause.classList.add('dn');
        }
      });
    };
    const desktopPlayer = new Player(desktopVideo);
    on(playDesktop, 'click', toggleDesktopVideo);
    on(playDesktopPause, 'click', toggleDesktopVideo);
    on(playDesktopPlay, 'click', toggleDesktopVideo);
  }

  if (mobileVideo) {
    const droid = typeof window.Android !== 'undefined' || /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv)/i.test(navigator.userAgent);
    const id = mobileVideo.src.substring(
      mobileVideo.src.indexOf('video/') + 'video/'.length,
      mobileVideo.src.indexOf(
        ['?', '&'].find((str) => mobileVideo.src.indexOf(str) !== -1),
      ),
    );
    const mobilePlayer = droid
      ? new Player(mobileVideoDroid, { controls: true, id })
      : new Player(mobileVideo);
    const toggleMobileVideo = () => {
      mobileVideo.classList.add('z2');
      mobilePlayer.getPaused().then((paused) => {
        if (paused) {
          mobilePlayer.play();
          playMobilePlay.classList.add('dn');
          playMobilePause.classList.remove('dn');
        } else {
          mobilePlayer.pause();
          playMobilePlay.classList.remove('dn');
          playMobilePause.classList.add('dn');
        }
      });
    };

    if (droid) {
      mobileVideo.classList.add('dn');
      mobileVideoDroid.classList.add('z2');
      mobileVideoDroid.classList.remove('dn');
      playMobilePause.classList.add('dn');
      playMobilePlay.classList.add('dn');
      uiBar.classList.add('dn');
    } else {
      on(playMobile, 'click', toggleMobileVideo);
      on(playMobilePause, 'click', toggleMobileVideo);
      on(playMobilePlay, 'click', toggleMobileVideo);
    }
  }
});
