import choozy from 'choozy';
import { component } from 'picoapp';
import {
  addHours,
  dmyToDate,
  escape,
  formatAddress,
  formatDateForDisplay,
  formatShortDateTimeForDisplay,
  formatTimeForDisplay,
  getDayOfWeek,
  getPrice,
  getServiceLabel,
} from '@/util/utils';

export default component((node, ctx) => {
  const state = ctx.getState();
  const locationSettings = choozy(state.node).locationSetting;
  const locationSettingEl = locationSettings.find(location => JSON.parse(location.dataset.locationSetting).location.slug === state.location.externalId);
  const locationSetting = JSON.parse(locationSettingEl?.dataset?.locationSetting);
  const discountDays = locationSetting?.discountDays?.map((day) => day.value) || [];
  const discountDayBookingMessage = choozy(locationSettingEl).locationSettingDiscountDayBookingMessage;
  const thankYouHeader = ctx.getState().node.dataset.thankYouHeader;
  const thankYouMessage = ctx.getState().node.dataset.thankYouMessage;
  const rescheduleAndCancellationPolicy = choozy(ctx.getState().node).rescheduleAndCancellationPolicy;
  const rescheduleAndCancellationTitle = ctx.getState().node.dataset.rescheduleAndCancellationTitle || 'Reschedule & Cancellation Policies';
  const thankYouImage = JSON.parse(ctx.getState().node.dataset.thankYouImage);

  const isDiscountedDate = (day) => {
    const dayDate = dmyToDate(day);
    const dayName = getDayOfWeek(dayDate);

    return discountDays?.includes(dayName);
  };

  const formatCtaUrl = (url = '') => {
    const state = ctx.getState();
    let newUrl = url?.replace('{rp_id}', escape(encodeURIComponent(state?.rpId)));
    newUrl = newUrl?.replace('{email}', escape(encodeURIComponent(state?.email)));
    return newUrl;
  };
  
  const render = (state) => {
    const date = formatDateForDisplay(state?.time?.name, state?.location?.tz);
    const time = formatTimeForDisplay(state?.time?.name, state?.location?.tz);
    const location = state?.location;
    const service = getServiceLabel(state);
    const price = getPrice(state);
    const thankYouCta = formatCtaUrl(state?.service?.ctaUrl);
    const thankYouCtaText = state?.service?.ctaText;
    const customerName = state?.firstName;
    const customerEmail = state?.email;
    const address = formatAddress(location?.address);
    const encodedAddress = `https://www.google.com/maps/search/${encodeURIComponent("Ephemeral Tattoo " + address)}`;
    const isDiscountDay = isDiscountedDate(state.day);

    node.innerHTML = `
      <div class="df mha x y">
        <div class="pt30 pb30 max-w400 mha m:w50 x">
          <div class="x mha">
            <div class="mt30 tl ph30">
              <div class="df fc acc mb20">
                <img class="mb20 w10 mha" src="/assets/icons/checkmark.svg" />
                <div class="lh140 light">
                  <h1 class="f24 tc mb10">${thankYouHeader.replace('{customerName}', customerName)}</h1>
                  <p class="f14 tc">${thankYouMessage.replace('{email}', `<span class="tdu">${customerEmail}</span>`)}</p>
                </div>
              </div>
              <hr class="mb20" />
              <div class="pb20 f16 lh140">
                <div class="df ais mb15">
                  <img src="/assets/icons/calendar.svg" class="mr10 vas"/>
                  <div>
                    <p class="mb5"><span class="f18 fw900">${date}</span>
                    <p class="c-oblack f14">${time}</p>
                  </div>
                </div>
                <div class="df ais mb15">
                  <img src="/assets/icons/pin.svg" class="mr10 vas"/>
                  <div>
                    <p class="f18 fw900 mb5">Ephemeral Tattoo - ${location?.name}</p>
                    ${address.trim().length ? `
                      <p class="c-oblack f14"><a href="${encodedAddress}" target="_blank" class="tdu">${address}</a></p>
                    `: ''}
                  </div>
                </div>
                <div class="df ais mb15">
                  <img src="/assets/icons/person.svg" class="mr10 vas"/>
                  <div>
                    <p class="f18 fw900 mb5">${service}</p>
                    <p class="c-oblack f14">${price}</p>
                  </div>
                </div>
                ${isDiscountDay ? `<div class="rte f14 lh140 tc bg-bud pt10 pb10">${discountDayBookingMessage.innerHTML}</div>` : ``}
              </div>
            </div>
            <!-- AddEvent Button -->
            <div title="Add to Calendar" class="js-addEventCalendarButton addeventatc rte f14 tc x dn mb10 ph30" data-styling="none">
              <span class="addeventatc-btn bc-black bg-black btn c-white dib x notranslate pea mono f18 lsn04em ttu fw700">Add to Calendar</span>
              <span class="start">${formatShortDateTimeForDisplay(
                state?.time?.name,
                state?.location?.tz,
              )}</span>
              <span class="end">${formatShortDateTimeForDisplay(
                addHours(state?.time?.name, 1),
                state?.location?.tz,
              )}</span>
              <span class="timezone">${state?.location?.tz}</span>
              <span class="location">Ephemeral Tattoo, ${address}</span>
              <span class="title">Ephemeral Tattoo</span>
            </div>
            ${thankYouCtaText?.length && thankYouCta?.length ? ` 
              <div class="ph30 x">
                <a href="${thankYouCta}" class="bc-black btn ba bw1 cta-url c-black dib x notranslate pea mono f18 lsn04em ttu fw700">${thankYouCtaText}</a>
              </div>
            `: ""}
            <div class="mt30 tl ph30">
              <hr class="mb20" />
            </div>
          </div>

          <div class="x max-w400 mha tl">
            <div class="mt30 tl ph30">
              <div class="mb20">
                <p class="f18 fw900 mb5">Need to talk to the studio?</p>
                <p class="f14 mb5">Call: <a class="h:u" href=tel:${state.location?.phoneNumber}>${state.location?.phoneNumber}</a></p>
                <p class="f14 mb5">Email: <a class="h:u" href=mailto:${state.location?.contactEmail}>${state.location?.contactEmail}</a></p>
              </div>
              
              ${rescheduleAndCancellationPolicy ? ` 
                <div class="f14">
                  <p class="f18 fw900 mb5">${rescheduleAndCancellationTitle}</p>
                  ${rescheduleAndCancellationPolicy.innerHTML}
                </div>
              `: ""}
            </div>
          </div>
        </div>
        <div class="dn m:db m:w50 x rel">
          <div class="abs zn1 x y top left bottom right bg-black bl bc-broccolini bw3" style="background: url('${thankYouImage?.lqip}') center center / cover no-repeat;"></div>
          <div class="abs z0 x y top left bottom right bg-black bl bc-broccolini bw3" style="background: url('${thankYouImage?.url}') center center / cover no-repeat;"></div>
        </div>
      </div>
    `;

    // <!-- AddEvent Button Script -->
    var addeventScript = document.createElement('script');
    addeventScript.setAttribute(
      'src',
      'https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js',
    );
    node.appendChild(addeventScript);
  };

  ctx.on('bookingThanksUpdate', (state) => {
    render(state);
    ctx.emit('bookingMountComponents');
    ctx.emit('bookingResize');
  });

  const init = () => {
    ctx.emit('bookingThanksUpdate');
  };

  init();
});
