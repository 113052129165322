const API_URL = process.env.BACKEND_API_URL || 'https://api.ephemeral.tattoo';

export const getStripeConfigurationData = async () => {
  const response = await fetch(`${API_URL}/checkouts/prepare`);
  const data = await response.json();

  return data;
};

export const getSecret = async (email) => {
  const response = await fetch(`${API_URL}/api/payments/setup_intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  const data = await response.json();
  return data.client_secret;
};
