import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import inview from '@/util/inview';
import { CountUp } from 'countup.js';

export default component((node, ctx) => {
  let { listicleItem, listicleItemImage, listicleItemText } = choozy(node);

  const animateElements = () => {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const offset = vh / 4;
    const inViewHeight = vh - offset;
    listicleItemImage.forEach(item => {
      const isInView = inview(item, inViewHeight);
      if(isInView && !item.classList.contains('is-in-view')) {
        item.classList.add('is-in-view');
      }
    });
    listicleItemText.forEach(item => {
      const isInView = inview(item, inViewHeight);
      if(isInView && !item.classList.contains('is-in-view')) {
        item.classList.add('is-in-view');

        const count = node.getAttribute('data-count');
        if (count) {
          const { listicleItemTextCount } = choozy(item);
          const title = item.getAttribute('data-title');
          const decimalPlaces = title.split('.').length > 1 ? 1 : false;
          const countUp = new CountUp(listicleItemTextCount, title, { decimalPlaces });
          countUp.start();
        }
      }
    });
  };
    
  let timeout;
  on(window, 'scroll', () => {
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }
    timeout = window.requestAnimationFrame(animateElements);
  });
});
