import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { trackEvent } from '@/util/utils';

export default component((node) => {
  const { toggle, content, body } = choozy(node);
  const analytics = toggle.getAttribute('data-analytics');

  on(toggle, 'click', (e) => {
    const drawerHeight = content.getBoundingClientRect().height;

    if (toggle.classList.contains('isOpen')) {
      body.style.maxHeight = '0px';
      toggle.classList.remove('isOpen');
      node.classList.remove('isOpen');
    } else {
      body.style.maxHeight = drawerHeight + 'px';
      toggle.classList.add('isOpen');
      node.classList.add('isOpen');
      if (analytics) trackEvent(window.location.pathname, 'Click FAQ', analytics);
    }
  });
});
