import { component } from 'picoapp';
import { on } from '@selfaware/martha';

export default component((node, ctx) => {
  const getElementOffset = (el) => {
    var de = document.documentElement;
    var box = el.getBoundingClientRect();
    var top = box.top + window.pageYOffset - de.clientTop;
    var left = box.left + window.pageXOffset - de.clientLeft;
    return { top: top, left: left };
  };

  const animateElement = () => {
    const viewportHeight = window.innerHeight;
    const scrollTop = window.scrollY;
    const elementOffsetTop = getElementOffset(node).top;
    const elementHeight = node.offsetHeight;

    // Calculate percentage of the element that's been seen
    const distance = (scrollTop + viewportHeight) - elementOffsetTop;
    const percentage = Math.round(distance / ((viewportHeight + elementHeight) / 100));

    // Restrict the range to between 0 and 100
    const animPerc = Math.min(100, Math.max(0, percentage));

    // Step animation
    node.style.animationDelay = -animPerc + 's';
  };

  let timeout;
  on(window, 'scroll', () => {
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }
    timeout = window.requestAnimationFrame(animateElement);
  });
});
