import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { trackEvent, heapIdentifyUser } from '@/util/utils';

export default component((node) => {
  const API_URL = process.env.BACKEND_API_URL || 'https://api.ephemeral.tattoo';
  let {
    signupForm,
    email,
    zipcode,
    signupVal,
    formTitle,
    formSuccess,
    campaign,
    fields,
    firstName,
    lastName,
    company
  } = choozy(node);

  const validateEmail = async (email) => {
    const valid = !email
      ? false
      : await fetch(`${API_URL}/api/validate_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      })
        .then(function (result) {
          return result.json();
        })
        .catch((error) => {
          return false;
        });

    if (!valid) return true; // API failed, allow it to pass
    if (valid.code !== 200) return true; // API failed, allow it to pass
    if (valid.body.result !== 'undeliverable') return true; // Email is valid
    return false; // Email is not valid
  };

  const signUp = async (email) => {
    if (
      email != '' &&
      (!zipcode || zipcode.value != '') &&
      email.indexOf('@') > -1
    ) {
      const valid = await validateEmail(email);
      if (!valid) {
        signupVal.classList.remove('dn');
        signupVal.innerHTML = 'Please enter a valid email';
        return;
      }

      trackEvent('newsletter', `signup`, window.location.pathname);
      signupForm.classList.add('is-submitted');
      const data = {
        email: email,
        campaign: campaign ? campaign.value : '',
        ...(zipcode?.value && {
          zip: zipcode.value,
        }),
        ...(firstName?.value && {
          first_name: firstName.value,
        }),
        ...(lastName?.value && {
          last_name: lastName.value,
        }),
        ...(company?.value && {
          company: company.value,
        }),
      };
      const rawResponse = await fetch(`${API_URL}/api/lead`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const content = await rawResponse.json();
      const clientPublicId = content?.client_public_id;
      heapIdentifyUser(clientPublicId);

      if (formSuccess) formSuccess.classList.remove('dn');
      if (formTitle) formTitle.classList.add('dn');
      if (!signupVal.classList.contains('dn')) {
        signupVal.classList.add('dn');
      }
      fields.classList.add('o0');
      // email.value = ''
      // if (zipcode) {
      //   zipcode.value = ''
      // }
      // setTimeout(() => {
      //   formSuccess.classList.add('dn')
      //   formTitle.classList.remove('dn')
      // }, 3000)
    } else {
      signupVal.classList.remove('dn');
      if (email == '' && zipcode.value != '') {
        signupVal.innerHTML = 'Email is required';
      } else if (email != '' && zipcode.value == '') {
        signupVal.innerHTML = 'Zipcode is required';
      } else if (!email.indexOf('@') > -1) {
        signupVal.innerHTML = 'Please enter a valid email';
      }
    }
  };

  on(signupForm, 'submit', (e) => {
    e.preventDefault();
    signUp(email.value);
  });

  const auto = node.getAttribute('data-auto');
  if (auto) {
    const search = new URLSearchParams(window.location.search);
    const e = search.get('e') ? atob(search.get('e')) : null;
    if (e && e.length) {
      signUp(e);
      fields.classList.add('dn');
    }
  }
});
