import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';

export default component((node) => {
  let { creditToggle, creditContainer } = choozy(node);

  on(creditToggle, 'click', () => {
    if (creditContainer.classList.contains('o0')) {
      creditContainer.classList.remove('o0');
    } else {
      creditContainer.classList.add('o0');
    }
  });
});
