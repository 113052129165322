import { component } from 'picoapp';
import { on } from '@selfaware/martha';
import choozy from 'choozy';
import { shuffle } from '@/util/utils';

export default component((node, ctx) => {
  const {
    form,
    results,
    design,
    answersContainer,
    processing,
    retry,
    mood,
    environment,
    music,
    location,
    activity,
    era,
    film,
    interest,
    unexpected,
    groupMoodButton,
    groupUnexpectedButton,
    groupInterestButton,
    groupActivityEraButton,
    ideaSubmitButton,
    designGenerator,
    bookThisIdeaButton,
    generatorContent,
    summary,
    bookContainer,
    description,
  } = choozy(node);

  let currentGroup = -1;
  let currentQuestion = '';

  const groups = [
    { fields: 'groupUnexpected', question: 'unexpected', background: '#E6865F' },
    { fields: 'groupInterest', question: 'interest', background: '#737F4C' },
    { fields: 'groupActivityEra', question: 'activityEraDevice', background: '#C19AC2' },
    { fields: 'groupMood', question: 'mood', background: '#E2BB50' },
  ];

  const getAnswer = (q, a, a2) => {
    return fetch(`/nlpcloud?q=${q}&a=${a}&a2=${a2}`)
          .then((response) => response.json())
          .then((data) => !data ? '' : parseResponse(data.answer))
          .catch(function (err) {
            console.log(err.response);
            return false;
          });
  };

  const parseResponse = (res) => {
    if (!res) return false;
    const parts = res.toLowerCase().split(/,|:|\n/).map(part => part.trim()).filter(part => part.length); // |-
    let answer = parts[parts.length - 1];
    return answer;
  };

  const loadGroup = (event, theme) => {
    const groupIndex = groups.findIndex((x) => {
      return x.fields === theme;
    });

    const group = groups[groupIndex];

    sessionStorage.setItem('ideaGenerator', JSON.stringify({ groupIndex }));

    // Set fields based on group
    const {
      [group.fields]: fields,
    } = choozy(node);

    // Hide previous fields in order to load the new new current fields
    if (currentGroup >= 0) {
      const {
        [groups[currentGroup].fields]: currentThemeField,
      } = choozy(node);
      currentThemeField.classList.add('dn');

      const {
        [`${groups[currentGroup].fields}Button`]: currentThemeSelection,
      } = choozy(node);

      currentThemeSelection.classList.remove(`js-${groups[currentGroup].fields}Button-offset`);
    }

    // Show fields
    fields.classList.remove('dn');
    currentQuestion = group.question;
    currentGroup = groupIndex;

    // Set background color
    designGenerator.style.backgroundColor = group.background;

    // Offset theme selection color
    const {
      [`${groups[currentGroup].fields}Button`]: currentThemeSelection,
    } = choozy(node);

    currentThemeSelection.classList.add(`${currentThemeSelection.className}-offset`);

    // show submit button
    ideaSubmitButton.classList.remove('dn');
  };

  const loadNextGroup = (event) => {
    const cache = sessionStorage.getItem('ideaGenerator');
    const groupIndex = cache ? 
                  JSON.parse(cache)['groupIndex'] + 1 >= groups.length ? 0 : JSON.parse(cache)['groupIndex'] + 1 
                  : Math.round(Math.random(groups.length));
    const group = groups[groupIndex];
    sessionStorage.setItem('ideaGenerator', JSON.stringify({ groupIndex }));

    const {
      [group.fields]: fields,
    } = choozy(node);
    if (currentGroup >= 0) {
      const {
        [groups[currentGroup].fields]: currentThemeField,
      } = choozy(node);
      currentThemeField.classList.add('dn');

      const {
        [`${groups[currentGroup].fields}Button`]: currentThemeSelection,
      } = choozy(node);

      currentThemeSelection.classList.remove(`js-${groups[currentGroup].fields}Button-offset`);
    }

    fields.classList.remove('dn');
    currentQuestion = group.question;
    currentGroup = groupIndex;

    // Set background color
    designGenerator.style.backgroundColor = group.background;

    // offset icon
    const {
      [`${groups[currentGroup].fields}Button`]: currentThemeSelection,
    } = choozy(node);
    
    currentThemeSelection.classList.add(`${currentThemeSelection.className}-offset`);

    // show submit button
    ideaSubmitButton.classList.remove('dn');
  };

  const getAnswers = async () => {
    switch (currentQuestion) {
      case 'mood': {
        sessionStorage.setItem('ideaGeneratorAnswer', JSON.stringify([mood.value]));
        
        const moodAnswer = await getAnswer('mood', mood.value);        
        
        return moodAnswer;
      }
      case 'unexpected': {
        sessionStorage.setItem('ideaGeneratorAnswer', JSON.stringify([unexpected.value]));
        
        const unexpectedAnswer = await getAnswer('unexpected', unexpected.value);
        
        return unexpectedAnswer;
      }
      case 'interest': {
        sessionStorage.setItem('ideaGeneratorAnswer', JSON.stringify([interest.value]));
        
        const interestAnswer = await getAnswer('interest', interest.value);

        return interestAnswer;
      }
      case 'environmentFilm': {
        sessionStorage.setItem('ideaGeneratorAnswer', JSON.stringify([environment.value, film.value]));
        
        const environmentAnswer = await getAnswer('environment', environment.value);
        const filmAnswer = await getAnswer('film', film.value);
        
        return environmentAnswer || filmAnswer ? `${environmentAnswer || ''} ${environmentAnswer && filmAnswer ? 'with' : '' } ${filmAnswer || ''}` : false;
      }
      case 'locationMusic': {
        sessionStorage.setItem('ideaGeneratorAnswer', JSON.stringify([location.value, music.value]));
        
        const locationAnswer = await getAnswer('location', location.value);
        const musicAnswer = await getAnswer('music', music.value);
        
        return locationAnswer || musicAnswer ? `${locationAnswer || ''} ${musicAnswer || ''}` : false;
      }
      case 'activityEraDevice': {
        sessionStorage.setItem('ideaGeneratorAnswer', JSON.stringify([activity.value, era.value]));
        
        let activityAnswer = await getAnswer('activity', activity.value);
        // Try 4 times in case the API times out
        let activityEraDeviceAnswer = activityAnswer ? await getAnswer('activityEraDevice', activityAnswer, era.value) : false;
        
        if (activityAnswer && !activityEraDeviceAnswer) activityAnswer = await getAnswer('activityEraDevice', activityAnswer, era.value);
        if (activityAnswer && !activityEraDeviceAnswer) activityAnswer = await getAnswer('activityEraDevice', activityAnswer, era.value);
        if (activityAnswer && !activityEraDeviceAnswer) activityAnswer = await getAnswer('activityEraDevice', activityAnswer, era.value);
        
        return activityEraDeviceAnswer;
      }
    }
  };

  const showBookingForm = (e) => {
    answersContainer.classList.add('dn');
    bookContainer.classList.remove('dn');
  };

  const sendAnswers = async (e) => {
    e.preventDefault();

    // Hide Form
    form.classList.add('dn');

    // Hide buttons
    generatorContent.classList.add('dn');

    // Show Processing Loader
    processing.classList.remove('dn');

    // Try 4 times in case the API times out
    let answer = await getAnswers();
    if (!answer.length) answer = await getAnswers();
    if (!answer.length) answer = await getAnswers();
    if (!answer.length) answer = await getAnswers();

    processing.classList.add('dn');

    const {
      [`${groups[currentGroup].fields}Question`]: currentSummary,
    } = choozy(node);

    summary.classList.remove('dn');
    currentSummary.classList.remove('dn');
    answersContainer.classList.add(`${currentQuestion}-background`);
    bookContainer.classList.add(`${currentQuestion}-background`);

    const answers = JSON.parse(sessionStorage.getItem('ideaGeneratorAnswer'));

    [...currentSummary.children].forEach((q, index) => {
      q.getElementsByTagName('span')[0].innerHTML = answers[index];
    });

    if (answer.length) {
      bookThisIdeaButton.classList.remove('dn');
    }
    design.forEach(el => {
      el.innerHTML = answer.length ? answer : `Sorry, we couldn't come up with anything. Try again!`;
    });
    description.value = answer.length ? answer.replaceAll('"', '') : '';
    results.classList.remove('dn');
  };

  groupMoodButton.addEventListener('click', (event) => loadGroup(event, "groupMood"));
  groupUnexpectedButton.addEventListener('click', (event) => loadGroup(event, "groupUnexpected"));
  groupInterestButton.addEventListener('click', (event) => loadGroup(event, "groupInterest"));
  groupActivityEraButton.addEventListener('click', (event) => loadGroup(event, "groupActivityEra"));

  on(form, 'submit', sendAnswers);
  on(bookThisIdeaButton, 'click', showBookingForm);
  on(retry, 'click', (event) => {
    form.classList.remove('dn');
    processing.classList.add('dn');
    results.classList.add('dn');

    summary.classList.add('dn');
    const {
      [`${groups[currentGroup].fields}Question`]: currentSummary,
    } = choozy(node);
    currentSummary.classList.add('dn');

    answersContainer.classList.remove(`${currentQuestion}-background`);
    bookContainer.classList.remove(`${currentQuestion}-background`);
    bookThisIdeaButton.classList.add('dn');
    generatorContent.classList.remove('dn');
    loadNextGroup(event);
  });
});
