import Highway from '@dogstudio/highway';
import gsap from 'gsap';

class Fade extends Highway.Transition {
  in({ from, to, done }) {
    window.scrollTo(0, 0);
    from.remove();
    gsap.to(to, {
      duration: 0.5,
      autoAlpha: 1,
      onComplete: done,
    });
    gsap.set('.drawArea', { autoAlpha: 0 });
    document.querySelector('html').classList.add('scroll-smooth');
  }

  out({ from, done }) {
    document.querySelector('html').classList.remove('scroll-smooth');
    gsap.to(from, {
      duration: 0.5,
      autoAlpha: 0,
      onComplete: done,
    });
  }
}

export default Fade;
