import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { component } from 'picoapp';
import { 
  loader,
  slugify, 
  trackEvent,
  trackHeapEvent,
} from '@/util/utils';
import {
  getService,
} from '@/components/booking/service';

export default component((node, ctx) => {
  const header = ctx.getState().node.dataset.groupHeader || 'Select a choice';
  const choices = choozy(ctx.getState().node).choice.map((choice) => JSON.parse(choice.getAttribute('data-choice')));
  const locationsImage = JSON.parse(ctx.getState().node.dataset.locationsImage);
  let view = 'loading';

  const selectChoice = (e) => {
    e.preventDefault();
    const choiceId = e.target.value;
    const choice = choices.find((choice) => choice['_key'] === choiceId) || {};
    const choiceServices = choice?.services?.map((service) => service.value) || [];

    const state = ctx.getState();
    state.choiceServices = choiceServices;
    state.choice = choice._key;
    state.services = null; // Reset services when changing choice

    trackHeapEvent(`Click ${choice.title} choice in Group View`);

    if (state.choice) {
      state.view = 'services';
    } else {
      state.view = 'error';
    }

    ctx.hydrate(state);
    ctx.emit('bookingUpdateView', state);
  };

  const onMount = () => {
    on(choozy(node).groupSelector, 'click', selectChoice);
  };

  const render = (state) => {
    if (view === 'loading') {
      node.innerHTML = loader;
      return;
    }

    node.innerHTML = `
      <div class="df mha y x">
        <div class="pt40 pb40 max-w400 mha m:w50 y x">
          <h1 class="f24 light pb20">${header}</h1>
          <div class="ph20">
            ${choices
              .map((choice) => {
                return `
                <button class="df jcb aic x mb10 pv20 ph10 tl bg-bud bc-broccolini ba bw1 br5px js-groupSelector" id=${`choice${slugify(choice.title)}`} value="${choice._key}">
                  <span class="pen">
                    <span class="f18 fw700">${choice.title}</span><br />
                    ${choice.subtitle?.length ? `<span class="f14 c-broccolini">${choice.subtitle}</span>` : ``}
                  </span>
                  <img src="/assets/icons/arrow-next.svg" width="15" class="pen" />
                </button>
              `;
              })
              .join('')}
          </div>
        </div>
        <div class="dn m:db m:w50 x rel">
          <div class="abs zn1 x y top left bottom right bg-black bl bc-broccolini bw3" style="background: url('${
            locationsImage?.lqip
          }') center center / cover no-repeat;"></div>
          <div class="abs z0 x y top left bottom right bg-black bl bc-broccolini bw3" style="background: url('${
            locationsImage?.url
          }') center center / cover no-repeat;"></div>
        </div>
      </div>
      `;
    onMount();
  };

  ctx.on('bookingGroupUpdate', (state) => {
    render(state);
    ctx.emit('bookingMountComponents');
    ctx.emit('bookingResize');
  });

  const init = () => {
    ctx.emit('bookingGroupUpdate');
    const state = ctx.getState();
    const URLParams = new URLSearchParams(window.location.search);
    const serviceParam = URLParams.get('s');
    const serviceName = serviceParam ? decodeURI(serviceParam) : null;
    const service = serviceName ? getService(state, serviceName) : null;

    // Skip view during initalization for services in params, or always for hidden services 
    if ((service?.title && !state.initalized) || (service?.title && !service.show)) {
      trackEvent('booking', `${state.view}`, `url param redirect`, serviceParam);
      ctx.emit('bookingUpdateView', { view: 'services' });

      // Hydrate service choices based on any group containing selected service
      const choice = choices.find(choice => choice.services?.map(service => serviceName)) || {};
      const choiceServices = choice?.services?.map((service) => service.value) || [];
      if (choice && choiceServices.length) {
        state.choiceServices = choiceServices;
        state.choice = choice['_key'];
        ctx.hydrate(state);
      }
      return;
    }

    view = 'loaded';
    ctx.emit('bookingGroupUpdate');
  };

  init();
});
