import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';

export default component((node, ctx) => {
  const { navBook, navOpenClose, navLink, headerContainer } = choozy(node);

  const closeMenu = () => {
    node.classList.remove('is-active');
    node.scrollTo({ top: 0, behavior: 'instant'});
  };

  const openMenu = () => {
    node.classList.add('is-active');
  };

  on(navOpenClose, 'click', () => {
    if (node.classList.contains('is-active')) {
      closeMenu();
    } else {
      openMenu();
    }
  });

  on(navLink, 'click', () => {
    closeMenu();
  });

  on(navBook, 'click', () => {
    closeMenu();
  });

  on(window, 'scroll', () => {
    const navHeight = headerContainer.getBoundingClientRect().height;
    if (window.pageYOffset > navHeight) {
      if (!node.classList.contains('is-scrolled-past')) {
        node.classList.add('is-scrolled-past');
      }
    } else if (window.pageYOffset < navHeight) {
      if (node.classList.contains('is-scrolled-past')) {
        node.classList.remove('is-scrolled-past');
      }
    }
  });
});
