import { component } from 'picoapp';
import { on } from '@selfaware/martha';
import choozy from 'choozy';

const getURLParams = () => {
  const search = new URLSearchParams(window.location.search);
  const session_id = search.get('session_id');
  const email = search.get('e') ? atob(search.get('e')) : null;

  return { session_id, email };
};

const preferredLocationUrl = () => {
  const { session_id, email } = getURLParams();

  if (!session_id && !email) return null;

  const url = new URL(`${process.env.BACKEND_API_URL}/api/customer_settings`);
  const key = session_id ? 'session_id' : 'email';
  const val = session_id ? session_id : email;
  url.searchParams.append(key, val);

  return url;
};

const getPreferredLocation = () => {
  const url = preferredLocationUrl();

  return url
    ? fetch(url)
        .then((response) => response.json())
        .then((data) => data[0])
    : Promise.resolve();
};

const setPreferredLocation = (l) => {
  const url = preferredLocationUrl();
  url.searchParams.append('l', l === 'location-other' ? '' : l);

  return fetch(url, { method: 'PUT' })
    .then((response) => response.json())
    .then((data) => data[0]);
};

export default component((node) => {
  const {
    locationForm,
    locationPreference,
    noActiveReservation,
    success,
    successChange,
    successLocation,
  } = choozy(node);

  const { session_id, email } = getURLParams();
  let currentLocation = null;

  if (!session_id && !email) {
    console.warn(
      '(Location Preferences): The URL should contain one of the following params: session_id or email',
    );
    return null;
  }

  getPreferredLocation().then((data) => {
    if (!data || data.slug === null) {
      noActiveReservation.classList.remove('dn');
      locationForm.classList.add('dn');
      success.classList.add('dn');
      node.classList.remove('dn');

      return;
    }

    node.classList.remove('dn');

    currentLocation = data.slug || 'location-other';
    const $el = node.querySelector(`[value="${currentLocation}"]`);

    if (!$el) return null;
    $el.checked = true;
    updateLocation({ target: $el });
  });

  const saveLocation = (e) => {
    e.preventDefault();
    setPreferredLocation(currentLocation).then(() => {
      success.classList.remove('o0', 'pen');
    });
  };

  const updateLocation = (e) => {
    currentLocation = e.target.checked ? e.target.value : false;
    successLocation.innerText = e.target.getAttribute('data-title');
  };

  const resetLocation = () => {
    success.classList.add('o0', 'pen');
  };

  on(locationForm, 'submit', saveLocation);
  on(locationPreference, 'change', updateLocation);
  on(successChange, 'click', resetLocation);
});
