import { component } from 'picoapp';
import choozy from 'choozy';
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default component((node) => {
  const {
    badge,
    locationsTrigger,
    locationsTriggerMask,
    animationContainer,
    locationsClose,
    locationLink,
    locationsFutureCta,
  } = choozy(node);

  if (badge) {
    if (badge.length) {
      badge.forEach((b) => {
        const duration = Math.floor(Math.random() * 10) + 5;
        const img = b?.querySelector('img');
        img.style.animationDuration = duration + 's';
      });
    } else {
      const duration = Math.floor(Math.random() * 10) + 5;
      const img = badge?.querySelector('img');
      img.style.animationDuration = duration + 's';
    }
  }

  if (locationsTrigger) {
    locationLink.map((link) =>
      link.addEventListener('click', (e) => {
        enableBodyScroll(animationContainer);
      }),
    )
    ;[...locationsFutureCta.querySelectorAll('a')].map((link) =>
      link.addEventListener('click', (e) => {
        enableBodyScroll(animationContainer);
      }),
    );

    locationsTrigger.addEventListener('click', (e) => {
      if (locationsTriggerMask.classList.contains('is-active')) {
        locationsTriggerMask.classList.remove('is-active');
      } else {
        const pos = getPosition(locationsTriggerMask);
        locationsTriggerMask.style.width = toPx(pos.width);
        locationsTriggerMask.style.height = toPx(pos.height);
        locationsTriggerMask.style.top = toPx(pos.top);
        locationsTriggerMask.style.left = toPx(pos.left);

        locationsTriggerMask.style.position = 'fixed';
        setTimeout(() => locationsTriggerMask.classList.add('is-active'));
      }

      disableBodyScroll(animationContainer);
      animationContainer.classList.add('is-active');
    });

    locationsClose.addEventListener('click', () => {
      locationsTriggerMask.classList.remove('is-active');
      locationsTriggerMask.style.position = 'static';
      locationsTriggerMask.style.top = '';
      locationsTriggerMask.style.left = '';
      enableBodyScroll(animationContainer);
      animationContainer.classList.remove('is-active');
    });
  }
});

function getPosition(el) {
  const rect = el.getBoundingClientRect();
  return {
    top: rect.top,
    left: rect.left,
    width: rect.width,
    height: rect.height,
  };
}

function toPx(value) {
  return `${value}px`;
}
