import app from '@/app';
import { component } from 'picoapp';
import { on } from '@selfaware/martha';
import choozy from 'choozy';
import {
  dmyToDate,
  formatTimeForDisplay,
  dateToDmy,
  getCache,
  heapIdentifyUser,
  loader,
  scrollUp,
  setCache,
  trackEvent,
  trackHeapEvent,
  trackHeapProperty,
} from '@/util/utils';
import {
  getLocations,
  reserveTimeInCart,
} from '@/util/boulevard';

const apiUrl = process.env.BACKEND_API_URL || 'https://api.ephemeral.tattoo';

export const getReservation = async (sessionId, rpId) => {
  return await fetch(`${apiUrl}/api/reservation?session_id=${sessionId}&rp_id=${rpId}`, {
    method: 'GET',
  });
};

export const isValidSession = async (sessionId, rpId, apptId) => {
  const stripeValidation = await getReservation(sessionId, rpId);
  const stripeValidationJson = stripeValidation
    ? await stripeValidation.json()
    : false;
  const pending = stripeValidationJson.is_pending;
  const unused = !stripeValidationJson.booked && !stripeValidationJson.is_refunded;
  const reschedule =
    apptId && stripeValidationJson.booked && !stripeValidationJson.is_refunded;
  const product = stripeValidationJson?.product;

  return { pending, valid: unused || reschedule, product };
};

export const getReschedule = async (apptId) => {
  return await fetch(`${apiUrl}/api/appointments/${apptId}/reschedule_eligible`, {
    method: 'GET',
  });
};

export const isValidReschedule = async (apptId) => {
  const reschedule = await getReschedule(apptId);
  const status = reschedule.status;
  const rescheduleJson = reschedule
    ? await reschedule.json()
    : false;
  return { body: rescheduleJson, status };
};

export default component((node, ctx) => {
  ctx.hydrate({ view: 'disabled', node });
  const { container, usedReservation, noReservation } = choozy(node);
  const useDateV2 = node.dataset.useDateV2 === 'true';
  const bypassRescheduleRestrictions = node.dataset.bypassRescheduleRestrictions === 'true';
  const rescheduleRestrictedMessageTime = choozy(ctx.getState().node).rescheduleRestrictedMessageTime?.innerHTML;
  const rescheduleRestrictedMessageState = choozy(ctx.getState().node).rescheduleRestrictedMessageTime?.innerHTML;
  const rescheduleRestrictedCtaTime = choozy(ctx.getState().node).rescheduleRestrictedMessageTime.dataset.ctaText;
  const rescheduleRestrictedCtaState = choozy(ctx.getState().node).rescheduleRestrictedMessageState.dataset.ctaText;
  const rescheduleRestrictedImage = JSON.parse(ctx.getState().node.dataset.rescheduleRestrictedImage);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const sessionId = urlParams.get('session_id');
  const rpId = urlParams.get('rp_id');
  const serviceName = urlParams.get('s');
  const hasServiceType = !!serviceName;
  const cleanTemplate = node.classList.contains('is-clean-template');

  const cart = {};
  let lastView = 'loading';
  let pending = true;
  let pageLoaded = false;
  let showPaymentCapture = true;
  let resizeTimeout;
  let rescheduleRestricted;

  // pull email and location from url params
  let email = urlParams.get('e'); // 'YmVuQGJlbmdvZGZyZXkuY29t' // 'ben@bengodfrey.com' //
  let locationName = urlParams.get('l'); // 'dXJuOmJsdmQ6TG9jYXRpb246NmFkMjZjOTMtYzU2ZC00NWZkLWE1OWEtM2Y3MTg1NzBkN2E4' // "urn:blvd:Location:6ad26c93-c56d-45fd-a59a-3f718570d7a8" //
  try {
    email = email && email.length ? decodeURIComponent(atob(email)) : false;
  } catch (e) {
    email = false;
  }
  const hasEmail = !!email;

  // pull appt ID from url params
  const apptIdParam = urlParams.get('a');
  const apptId = apptIdParam?.length ? atob(apptIdParam) : false;

  const getClientPublicId = async () => {
    const encodedEmail = encodeURIComponent(email);
    const response = await fetch(`${apiUrl}/api/client?email=${encodedEmail}`, {
      method: 'GET',
    });

    const data = await response.json();

    return data?.client_public_id;
  };

  const hideFaqs = () => {
    const faqs = document.querySelectorAll('.section-drawer');

    [...faqs].forEach((faq) => {
      faq.classList.add('dn');
    });
  };

  const showFaq = (view) => {
    const faqs = document.querySelectorAll('.section-drawer');

    // Hide Faqs
    [...faqs].forEach((faq) => {
      if (faq.classList.contains('dn')) {
        return;
      }

      faq.classList.add('dn');
    });

    const findFaqForView = [...faqs].find((faq) => {
      return faq.querySelector(`#faq-${view}`);
    });

    findFaqForView?.classList?.remove("dn");
  };

  const onResize = () => {
    if (!cleanTemplate) return;
    if (resizeTimeout) window.cancelAnimationFrame(resizeTimeout);

    resizeTimeout = window.requestAnimationFrame(() => {
      node.style.height = '';
      const height = node.getBoundingClientRect().height;
      if (window.innerHeight < height) {
        node.style.height = `${height}px`;
      } else {
        node.style.height = `calc(100vh - var(--app-header-height))`;
      }
    });
  };

  const init = async () => {
    hideFaqs();
    const local = getCache();
    let state =
      (local.sessionId === sessionId || local.rpId === rpId) &&
      local.email === email &&
      (!locationName ||
        (local.location && local.location.id === locationName)) &&
      !hasServiceType
        ? local
        : {};
    state.node = node;
    state.sessionId = sessionId;
    state.rpId = rpId;
    state.initalized = false;
    let view = 'loading';

    // Track event in heap
    trackHeapProperty('reservation_id', rpId);
    trackHeapEvent('Started Booking');

    if (email) {
      state.email = email;
      const clientPublicId = await getClientPublicId(email);
      heapIdentifyUser(clientPublicId);
    }

    // Validate stripe session
    const session = await isValidSession(sessionId, rpId, apptId);
    pending = session.pending;
    view = session.valid ? 'locations' : 'used';

    if (session?.product) {
      state.productInfo = session?.product;
    }

    // Determine if we should show the card capture
    showPaymentCapture = pending || (!rpId && !sessionId) ? 'true' : 'false';

    // Get locations
    if (!state.locations) {
      const locationsData = view === 'locations' ? await getLocations() : false;
      state.locations = locationsData?.data?.business?.locations?.edges?.length ? locationsData.data.business.locations.edges : false;
    }

    if (state.locations) {
      // Match location from URL
      const location = locationName && state.locations?.[0].node?.externalId
        ? state.locations.find(
            ({ node: { externalId } }) => externalId === locationName,
          )
        : false;

      // Skip location step
      if (location) {
        state.location = location.node;
        view = 'group';
      }
    }

    // Check for reschedule restrictions
    if (apptId && !bypassRescheduleRestrictions) {
      const reschedule = await isValidReschedule(apptIdParam);

      if (reschedule.status === 200 && reschedule?.body?.appointment?.starts_at?.length) {
        state.originalAppointment = reschedule?.body;
        state.firstName = state.originalAppointment.client?.first_name;
        state.lastName = state.originalAppointment.client?.last_name;
        state.phone = state.originalAppointment.client?.phone;
      } else {
        rescheduleRestricted = reschedule?.body?.reason;
      }
    }

    // Cancel out if it's a restricted reschedule
    if (rescheduleRestricted) {
      state.view = 'restricted';
      ctx.hydrate(state);
      updateView(state.view);
      return;
    }

    setTimeout(() => {
      const state = ctx.getState();
      pageLoaded = true;
      const href = window.location.href.split('#')[0];
      window.history.replaceState({ view: state.view }, document.title, `${href}#${state.view}`);
    
      // set initalized
      state.initalized = true;
      ctx.hydrate(state);
    }, 1000);

    // listen for browser resize
    on(window, 'resize', onResize);
    onResize();

    if (state.view) view = state.view;
    ctx.hydrate(state);
    updateView(view);
  };

  const updateView = (view, back) => {
    if (view !== lastView) scrollUp(node);
    ctx.hydrate({ view, error: false });
    ctx.emit('bookingUpdate');
    ctx.emit('bookingMountComponents');
    if (['locations', 'group', 'services', 'week', 'confirmation', 'thanks'].includes(view) && !hasServiceType) {
      setCache(ctx.getState());
    }

    // Browser history tracking
    const steps = ['locations', 'group', 'services', 'confirmation', 'thanks'];
    if (steps.includes(view)) {
      if (!back && pageLoaded && view !== lastView) {
        trackEvent('booking', `${view}`, `viewed`);
        const href = window.location.href.split('#')[0];
        window.history.replaceState({view}, document.title, `${href}#${view}`);
      }
    }
    lastView = view;
  };

  // Browser history listening
  window.addEventListener('popstate', function (e) {
    const state = e.state;
    const currentTarget = e?.currentTarget?.location?.pathname?.replace(/\//g, '');

    // Track event
    trackEvent('booking', `confirmation`, `click back browser`);
    trackHeapEvent('Click - Browser Back Button', {
      to: state?.view || currentTarget, // When a user clicks 'back' on 'locations', state?.view returns undefined
    });

    // Return to previous page if no hash
    // TODO: Cleanup. 
    // Leaving here in case there are back button issues, uncommenting this might resolve.
    // Recent tests seem to show this causing the browser to skip the first step when navigating back.
    // Behavior is related to line 197 where the first history state is updated with window.history.replaceState
    // if (!state?.view && !state?.prevUrl) {
    //   window.history.back();
    //   return;
    // }

    // Bypass if not a supported step
    if (!/locations|group|services|week|confirmation|thanks/.test(state?.view)) return;

    // Navigating between weeks
    if (state.view.includes('week') && lastView.includes('week')) {

      // Update week view
      if (state.view.includes(':')) {
        const week = state.view.split(':')[1];
        ctx.emit('bookingUpdateWeekByDate', ctx.getState(), week);

      // Update top level week view
      } else if(state.view === 'week') {
        ctx.emit('bookingUpdateWeekByDate', ctx.getState());
      }
    } else {

      // Update top level views
      ctx.emit('bookingButtonUpdate', { disabled: false, error: false });
      ctx.emit('bookingUpdateView', { view: state.view.split(':')[0], reservedTimeFail: false }, true);
    }
  });

  ctx.on('bookingUpdateView', (state, back) => {
    updateView(state.view, back);
  });

  ctx.on('bookingUpdateViewWeek', (state, newWeek) => {
    if (!pageLoaded) return;
    const newView = `${state.view}:${newWeek}`;
    if (lastView === newView) return;
    const hrefParts = window.location.href.split('#');
    const href = hrefParts[0];
    const hash = hrefParts[1];
    window.history.replaceState({view: newView}, document.title, `${href}#${newView}`);
    lastView = newView;
  });

  ctx.on('bookingResize', () => {
    setTimeout(() => {
      onResize();
    }, 500);
  });

  const reserveTime = async () => {
    const state = ctx.getState();
    let view = 'reserving';
    if (state.view === view) return;

    updateView(view);
    // Reserve the time and then update the state
    try {
      const reserved = await reserveTimeInCart(state.cartId, state.time.ID);
      if (!reserved || reserved.errors) {
        reservationFailure();
      } else {
        view = 'confirmation';
        state.itemId =
          reserved.data.reserveCartBookableItems.cart.selectedItems[0].id;
        state.reserved = true;
        ctx.hydrate(state);
        updateView(view);
      }
    } catch (e) {
      reservationFailure();
    }
  };

  const reservationFailure = async () => {
    let view = 'week';
    const state = ctx.getState();
    if (state.view === view && state.reservedTimeFail) return;

    state.reservedTimeFail = true;
    ctx.hydrate(state);
    updateView(view);
    ctx.emit('bookingUpdateWeek');
    trackEvent('booking', `${view}`, `reservation failed`);
    trackHeapEvent('Redirect - Appointment Sold Out', {
      date: dateToDmy(state.time?.name),
      time: formatTimeForDisplay(state.time?.name, state.location?.tz),
      dow: dmyToDate(dateToDmy(state.time?.name)).toLocaleDateString('en-US', { weekday: 'long' })
    });
  };

  const appointmentBooked = async (state) => {
    if (state.confirmed === 'Confirmed') {
      updateView('thanks');
      setCache({});
      sessionStorage.removeItem('referrer');
      trackEvent('booking', 'thanks', 'reserved');
    } else {
      reservationFailure();
    }
  };

  const formatRescheduleRestrictedMessage = (msg, label) => {
    const state = ctx.getState();
    let name = '';
    let email = '';
    if (state?.location?.name?.length) {
      name = state?.location?.name;
      email = state?.location?.contactEmail;
    }
    let newMsg = msg;
    if (email.length) {
      newMsg += `<p class="pt20"><a href="mailto:{email}" class="bc-black bg-black btn c-white dib x notranslate pea mono f18 lsn04em ttu fw700">${label}</a></p>`;
    }
    newMsg = newMsg.replaceAll('{name}', name);
    newMsg = newMsg.replaceAll('{email}', email);
    return newMsg;
  };

  ctx.on('bookingUpdateReserve', (state) => {
    reserveTime();
  });

  ctx.on('bookingReservationFailure', (state) => {
    reservationFailure();
  });

  ctx.on('bookingAppointmentBooked', (state) => {
    updateView('loading');
    appointmentBooked(state);
  });

  app.on('bookingBack', () => {
    const view = window.history.state?.view.split(':').shift();
    const steps = ['locations', 'group', 'services', 'week', 'confirmation', 'thanks'];
    const currentStep = steps.findIndex(step => step.startsWith(view));
    let previousStep = steps[currentStep-1];

    // Skip back to locations bypassing group and services for no-show services
    if (previousStep === 'services') {
      const state = ctx.getState();
      const service = choozy(node).service.find(service => state.service?.name === service.getAttribute('data-name'));
      if (service.getAttribute('data-show') !== 'true') {
        previousStep = 'locations';
      }
    }
    
    if (previousStep !== undefined) {
      updateView(previousStep);
    } else {
      window.history.back();
    }
  });

  const onMount = (state) => {
    // Display faqs
    showFaq(state.view);

    if (state.view === 'thanks') {
      if (window.addeventatc !== undefined) {
        const { addEventCalendarButton } = choozy(node);

        trackEvent('booking', `${state.view}`, `add-to-calendar-loaded`);

        addEventCalendarButton.classList.remove('dn');

        window.addeventatc.refresh();

        window.addeventatc.register('button-click', function (obj) {
          trackEvent('booking', `${state.view}`, `add-to-calendar`);
        });

        window.addeventatc.register('button-dropdown-click', function (obj) {
          trackEvent(
            'booking',
            `${state.view}`,
            `add-to-calendar-${obj.service}`,
          );
        });
      } else {
        // retry if add event not available yet
        setTimeout(() => {
          onMount(state);
        }, 500);
      }
    }
    
    onResize();
  };

  ctx.on('bookingUpdate', (state) => {
    let render = ``;
    render += `<div class="x y">`;

    if (cleanTemplate) {
      render += `<div data-component="bookingProgress" data-has-location="${!!locationName}" data-has-service-type="${hasServiceType}"></div>`;
    }

    if (rescheduleRestricted) {
      render += `
        <div class="df mha y x">
          <div class="pt40 max-w400 mha m:w50 y x">
            <div class="ph20 rte">
              ${rescheduleRestricted === 'time' ? formatRescheduleRestrictedMessage(rescheduleRestrictedMessageTime, rescheduleRestrictedCtaTime) : formatRescheduleRestrictedMessage(rescheduleRestrictedMessageState, rescheduleRestrictedCtaState)}
            </div>
          </div>
          <div class="dn m:db m:w50 x rel">
            <div class="abs zn1 x y top left bottom right bg-black bl bc-broccolini bw3" style="background: url('${rescheduleRestrictedImage?.lqip}') center center / cover no-repeat;"></div>
            <div class="abs z0 x y top left bottom right bg-black bl bc-broccolini bw3" style="background: url('${rescheduleRestrictedImage?.url}') center center / cover no-repeat;"></div>
          </div>
        </div>
      `;
    } else if (state.view === 'used') {
      render += `<div class="block-text rte mt100 x y ph30">${usedReservation.innerHTML}</div>`;
    } else if (state.view === 'none') {
      render += `<div class="block-text rte mt100 x y ph30">${noReservation.innerHTML}</div>`;
    } else if (state.view === 'disabled') {
      render += ``;
    }

    if (state.view === 'loading') {
      render += `<div class="x y" data-loader="root">${loader}</div>`;
    } else if (state.view === 'error') {
      render += `Oops! Something went wrong, please refresh and try again.`;
    } else if (state.view === 'locations' && state.locations) {
      render += `
          <div class="x y" data-component="bookingLocation"></div>
        `;
    } else if (state.view === 'group') {
      render += `
        <div class="rel oh x y" data-component="bookingGroup"></div>
      `;
    } else if (state.view === 'services') {
      render += `
          <div class="rel oh x y" data-component="bookingService"></div>
        `;
    } else if (state.view === 'week') {
      render += `
            <div class="mha x y ${useDateV2 ? '' : 'mha max-w880'}" data-component="${useDateV2 ? 'bookingWeekV2' : 'bookingWeek'}" data-enable-payment-capture="${showPaymentCapture}"></div>
          `;

    } else if (state.view === 'reserving') {
      render += `
          <div class="x y">
            ${loader}
          </div>
          `;
    } else if (state.view === 'confirmation') {
      render += `
            <div class="x y" data-component="bookingConfirmation" data-start-appt-id="${apptId}" data-enable-payment-capture="${showPaymentCapture}" data-has-email="${hasEmail}"></div>
          `;
    } else if (state.view === 'thanks') {
      render += `
            <div class="x y" data-component="bookingThanks"></div>
          `;
    }

    render += `</div>`;
    container.innerHTML = render;

    onMount(state);
  });

  ctx.emit('bookingUpdate', { view: 'loading' });
  init();
});
