import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import app from '@/app';

export default component((node, ctx) => {
  const { reviewBtn } = choozy(node);
  let activeBtn = reviewBtn?.[0];
  let activeCategory = choozy(node)[`reviewCategory${activeBtn.getAttribute('data-value')}`];

  if (reviewBtn.length > 1 && activeCategory) {
    reviewBtn.forEach(btn => {
      on(btn, 'click', e => {
        // get button collection name
        const collection = e.target.getAttribute('data-value');

        // unset active els
        activeCategory.classList.add('dn');
        activeBtn.classList.remove('is-active');

        // set new active els
        activeBtn = e.target;
        activeCategory = choozy(node)[`reviewCategory${collection}`];
        activeCategory.classList.remove('dn');
        activeBtn.classList.add('is-active');
        app.emit('transition');
      });
    });
  }
});