import { component } from 'picoapp';
import { gsap } from 'gsap/all';

export default component((node, ctx) => {
  let drawArea = document.querySelector('.drawArea');

  if (document.documentElement.clientWidth > 850) {
    gsap.defaults({ ease: 'none' });

    var svgns = 'http://www.w3.org/2000/svg';
    var root = document.querySelector('.drawArea');
    var ease = 0.4;

    var pointer = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    window.addEventListener('mousemove', function (event) {
      pointer.x = event.clientX;
      pointer.y = event.clientY;
    });

    var leader = pointer;

    var total = 10;
    for (var i = 0; i < total; i++) {
      leader = createLine(leader, i);
    }

    function createLine(leader, i) {
      var line = document.createElementNS(svgns, 'line');
      root.appendChild(line);

      gsap.set(line, { x: -15, y: -15, alpha: (total - i) / total });

      window.onscroll = () => {
        if (
          window.innerHeight + window.scrollY >=
          document.querySelector('main').offsetHeight +
            document.querySelector('footer').offsetHeight
        ) {
          gsap.set(drawArea, { autoAlpha: 1 });
        } else {
          gsap.set(drawArea, { autoAlpha: 0 });
        }
      };

      gsap.to(line, {
        duration: 1000,
        x: '+=1',
        y: '+=1',
        repeat: -1,
        modifiers: {
          x: function () {
            let posX = gsap.getProperty(line, 'x');
            let leaderX = gsap.getProperty(leader, 'x');

            var x = posX + (leaderX - posX) * ease;
            line.setAttribute('x2', leaderX - x);
            return x;
          },
          y: function () {
            let posY = gsap.getProperty(line, 'y');
            let leaderY = gsap.getProperty(leader, 'y');

            var y = posY + (leaderY - posY) * ease;
            line.setAttribute('y2', leaderY - y);
            return y;
          },
        },
      });

      return line;
    }
  }
});
