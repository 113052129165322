import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';

const redirectToLocationCheckout = (ev) => {
  ev.preventDefault();

  const $form = ev.target;
  const elements = $form.querySelectorAll('[name="location"]');

  let redirectTo = null;

  for (let i = 0; i < elements.length; i++) {
    if (elements[i].checked) {
      redirectTo = elements[i].value;
      break;
    }
  }

  if (redirectTo === null) {
    $form.classList.add('error');

    return;
  }

  window.location.href = `/${redirectTo}`;
};

const updateErrorState = (ev) => {
  const $wrapper = ev.currentTarget;
  const hasError = $wrapper.classList.contains('error');
  if (hasError) $wrapper.classList.remove('error');
};

const updateButtonLabel = (ev) => {
  const $wrapper = ev.currentTarget;
  const $button = $wrapper.querySelector('[type="submit"]');
  const location = ev.target.dataset.location;
  const placeholder = $button.dataset.placeholder;

  $button.textContent = placeholder.replace('__LOCATION__', location);
};

export default component(async (node) => {
  const { 'location-selector': $locationSelector } = choozy(node);

  on($locationSelector, 'change', (ev) => {
    updateErrorState(ev);
    updateButtonLabel(ev);
  });
  on($locationSelector, 'submit', redirectToLocationCheckout);
});
