import { picoapp } from 'picoapp';
import { size } from '@selfaware/martha';
import app from '@/app';

import booking from '@/components/booking';
import bookingButton from '@/components/booking/button';
import bookingService from '@/components/booking/service';
import bookingLocation from '@/components/booking/location';
import bookingWeek from '@/components/booking/week';
import bookingDay from '@/components/booking/day';
import bookingWeekV2 from '@/components/booking/week-v2';
import bookingDayV2 from '@/components/booking/day-v2';
import bookingConfirmation from '@/components/booking/confirmation';
import bookingThanks from '@/components/booking/thanks';
import bookingProgress from '@/components/booking/progress';
import bookingGroup from '@/components/booking/group';

const components = {
  booking,
  bookingButton,
  bookingLocation,
  bookingService,
  bookingWeek,
  bookingDay,
  bookingWeekV2,
  bookingDayV2,
  bookingConfirmation,
  bookingThanks,
  bookingProgress,
  bookingGroup,
};

const state = {
  ...size(),
};

const BookingApp = picoapp(components, state);
BookingApp.mount();
BookingApp.on('bookingMountComponents', () => {
  setTimeout(() => {
    BookingApp.unmount();
    BookingApp.mount();
  }, 1);
});

app.on('transition', () => {
  BookingApp.mount();
});

export default BookingApp;
