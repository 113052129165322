import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { trackHeapEvent } from '@/util/utils';

export default component((node) => {
  const { purchaserForm, submitButton } = choozy(node);

  // Collection and Flash
  const collection = node.getAttribute('data-collection') === 'true';
  const collectionName = node.getAttribute('data-collection-name') || null;
  const collectionFlashName =
    node.getAttribute('data-collection-flash-name') || null;
  const collectionFlashServiceType =
    node.getAttribute('data-collection-flash-service-type') || null;
  const popupView = node.getAttribute('data-popup-view') === 'true';

  const setButtonState = (buttonElement, loading) => {
    if (loading) {
      buttonElement.classList.add('btn-loading');
      buttonElement.disabled = true;
    } else {
      buttonElement.classList.remove('btn-loading');
      buttonElement.disabled = false;
    }
  };

  const redirect = (link) => {
    window.location.href = link;

    return false;
  };

  const redirectToArtists = async () => {
    redirect('/artists');
  };

  on(purchaserForm, 'submit', async (e) => {
    e.preventDefault();
    setButtonState(submitButton, true);
    redirectToArtists();
  });

  // Refresh the page if coming from browser back button to prevent hanging form submit
  window.onpageshow = function (e) {
    if (e.persisted) {
      window.location.reload();
    }
  };

  if (collection && !popupView) {
    trackHeapEvent('Flash PDP', {
      flash_name: collectionFlashName,
      collection: collectionName,
      type: collectionFlashServiceType,
    });
  }
});
