import { component } from 'picoapp';
import choozy from 'choozy';
import { on, remove } from '@selfaware/martha';
import inview from '@/util/inview';
import app from '@/app';

export default component((node, ctx) => {
  let { lqip, img } = choozy(node);
  let timeout, loaded = false;

  const loadImage = () => {
    img.decode().then(() => {
      requestAnimationFrame(() => {
        ctx.emit('lazy:load', null, { id: node.dataset.id });
        let off = on(img, 'transitionend', () => {
          off();
          lqip.remove();
        });
        remove(img, 'o0');
        img.removeAttribute('data-src');
      });
    }).catch(err => {
      console.log(err);
      setTimeout(loadImage, 250);
    });
  };

  const loadEl = () => {
    if (loaded) return;
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const isInView = inview(img, (vh + vh/2));
    if(isInView) {
      img.src = img.dataset.src;
      img.srcset = img.dataset.srcset;
      loaded = true;
    }
  };

  const scroll = () => {
    if (loaded) return;
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }
    timeout = window.requestAnimationFrame(loadEl);
  };

  on(window, 'scroll', scroll);
  on(img, 'load', loadImage);
  app.on('transition', loadEl);
  setTimeout(loadEl, 500);
});
