import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';

export default component((node) => {
  let { prev, next } = choozy(node);

  let offPrevSlide = on(prev, 'click', () => {
    plusSlides(-1);
  });

  let offNextSlide = on(next, 'click', () => {
    plusSlides(1);
  });

  let slideIndex = 1;
  showSlides(slideIndex);

  function plusSlides(n) {
    showSlides((slideIndex += n));
  }

  function showSlides(n) {
    var i;
    var slides = document.getElementsByClassName('js-slide');
    if (n > slides.length) {
      slideIndex = 1;
    }
    if (n < 1) {
      slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
      slides[i].classList.remove('active');
    }
    slides[slideIndex - 1].style.display = 'grid';
    setTimeout(() => {
      slides[slideIndex - 1].classList.add('active');
    }, 50);
  }

  return () => {
    offNextSlide();
    offPrevSlide();
  };
});
