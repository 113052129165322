import Highway from '@dogstudio/highway';
import { listen } from 'quicklink';
import { on, size } from '@selfaware/martha';
import { gsap, ScrollTrigger } from 'gsap/all';
import app from '@/app';

const renderCustomTemplate = (view, page) => {
  const isCleanTemplate = view.dataset.cleanTemplate === 'true';
  const minimalHeader = view.dataset.minimalHeader === 'true';
  const isTransparentHeader = view.dataset?.transparentHeader === 'true';
  const hamburgerColor = view.dataset?.hamburgerColor?.length
    ? view.dataset.hamburgerColor
    : 'black';

  const $header = document.querySelector('#app-header');
  const $headerBase = document.querySelector('#app-header .header-base');
  const $headerClean = document.querySelector('#app-header .header-clean');
  const $headerHamburger = document.querySelector('#app-header .js-hamburger');
  const $footer = document.querySelector('#app-footer');

  if (isCleanTemplate) {
    $headerBase.classList.add('dn');
    if (minimalHeader) {
      $headerClean.classList.remove('dn');
    } else {
      $headerClean.classList.add('dn');
    }

    $footer.classList.add('dn');
  } else {
    $headerClean.classList.add('dn');
    $headerBase.classList.remove('dn');
    $footer.classList.remove('dn');
  }

  $header.classList.remove('sticky', 'fixed', 'right');
  $headerBase.classList.remove('bg-white');
  $headerHamburger.classList.remove(
    'c-black',
    $headerHamburger.classList
      .toString()
      .match(/c-\w+/i)
      ?.shift(),
  );
  if (isTransparentHeader) {
    $header.classList.add('fixed', 'right');
    $headerHamburger.classList.add(`c-${hamburgerColor}`);
  } else {
    $header.classList.add('sticky');
    $headerBase.classList.add('bg-white');
    $headerHamburger.classList.add('c-black');
  }

  const url = new URL(page.URL);
  const pathPath =
    url.pathname === '/'
      ? '_home'
      : url.pathname.replace(/\/$/, '').replace(/\//g, '_');
  const pageClass = [...document.body.classList].find((cl) =>
    cl.startsWith('page_'),
  );
  pageClass && document.body.classList.remove(pageClass);
  document.body.classList.add(`page${pathPath}`);
};

const reformatParamValue = (
  paramName,
  encode = false,
  b64 = false,
  b64d = false,
) => {
  let value = paramName;
  if (encode) {
    value = encodeURIComponent(paramName);
  }
  if (b64d) {
    value = atob(paramName);
  }
  if (b64) {
    value = btoa(paramName);
  }
  return value;
};

const reformatParamObjects = (params, path, encode) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let newPath = path;
  params.forEach((param) => {
    if (param && param.length) {
      const b64 = param.includes('{param64:');
      const b64d = param.includes('{param64d:');
      let paramName = param
        .replace('{param64d:', '')
        .replace('{param64:', '')
        .replace('{param:', '')
        .replace('}', '');
      let paramSource = urlParams.get(paramName);
      paramSource = paramSource ? paramSource.replace(' ', '+') : '';
      const paramValue = paramSource
        ? reformatParamValue(paramSource, encode, b64, b64d)
        : false;
      newPath =
        paramValue && paramValue.length
          ? newPath.replace(param, paramValue)
          : newPath;
    }
  });
  return newPath;
};

// Replace all links with {param:xxx} to include the param from the current url
const hydrateHrefParams = () => {
  // Replace a href links
  document.querySelectorAll('[href]').forEach((el) => {
    const params =
      typeof el.href !== 'string' ? false : el.href.match(/\{[^}]*\}/g);
    if (!params) return;
    el.href = reformatParamObjects(params, el.href, true);
  });

  // Replace src links
  document.querySelectorAll('[src]').forEach((el) => {
    const params =
      typeof el.src !== 'string' ? false : el.src.match(/\{[^}]*\}/g);
    if (!params) return;
    el.src = reformatParamObjects(params, el.src);
  });

  // Replace data-tf-hidden links
  document.querySelectorAll('[data-tf-hidden]').forEach((el) => {
    const params =
      typeof el.getAttribute('data-tf-hidden') !== 'string'
        ? false
        : el.getAttribute('data-tf-hidden').match(/\{[^}]*\}/g);
    if (!params) return;
    el.setAttribute(
      'data-tf-hidden',
      reformatParamObjects(params, el.getAttribute('data-tf-hidden')),
    );
  });
};

class Base extends Highway.Renderer {
  onFirstLoad() {
    // automatically prefetch URLs for links that are in-viewport during idle time
    listen();

    // broadcast resize event
    on(window, 'resize', this.resize);

    // listen for remount event
    on(window, 'remount', this.remount);

    // setup render loop
    gsap.ticker.add(this.tick);

    gsap.registerPlugin(ScrollTrigger);

    gsap.set('[data-router-view]', { autoAlpha: 1 });

    hydrateHrefParams();

    // mount picoapp
    this.mount();

    // listen for update for child components
    app.on('appUpdate', this.remount);
    renderCustomTemplate(this.properties.view, this.properties.page);

    // store values from URL in cache
    this.setCache();
  }

  onEnter() {
    this.mount();
    app.emit('transition');
    this.resize();
    renderCustomTemplate(this.properties.view, this.properties.page);
    hydrateHrefParams();

    // Run inline javascript, dangerous but necessary... ¯\_(ツ)_/¯
    const scripts = this.properties.view.querySelectorAll('script');
    for (const script of scripts) {
      const code = script.innerText;
      if (code.length !== 0) Function(code)();
    }
  }

  setCache() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referrer = urlParams.get('referrer');
    referrer && sessionStorage.setItem('referrer', referrer);
  }

  onLeave() {
    this.unmount();
  }

  onLeaveCompleted() {}

  resize = () => {
    app.emit('resize', size());
  };

  tick = () => {
    app.emit('tick');
  };

  remount = () => {
    app.unmount();
    app.mount();
  };

  mount = () => {
    app.mount();
  };

  unmount = () => {
    app.unmount();
  };

  setup() {
    this.onFirstLoad();
  }
}

export default Base;
