import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import WZoom from 'vanilla-js-wheel-zoom';
import queryString from 'query-string';
import { trackHeapEvent } from '@/util/utils';

const apiUrl = process.env.BACKEND_API_URL || 'https://api.ephemeral.tattoo';
const breakpoint = 850;
const totalImages = 24;
const retinaScalePercent = 1.35;
const desktopColumns = 4;
const isMobile = () => document.body.clientWidth < breakpoint;
const cols = () => isMobile() ? 2 : desktopColumns;
let isEnd = false;
let lastMobile = isMobile();
let wzoom;
let images = [];
let imageColumnHeights = [];

const chunkArray = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export default component((node, ctx) => {
  const {
    container,
    end,
    footer,
    loadMoreBtn,
    modal,
    modalBg,
    modalCaption,
    modalClose,
    modalContent,
    modalImage,
    modalImageContainer,
    modalZoom,
    modalZoomIn,
    modalZoomOut,
    noResults,
    placement,
    refresh,
    save,
    size,
    skintones,
    style,
  } = choozy(node);

  const getImages = async (total = 20) => {
    let skintonesSelected = [];
    if (skintones) {
      skintones.querySelectorAll('input[type=checkbox]').forEach(input => {
        const value = input.value;
        if (input.checked) {
          skintonesSelected.push(value.toLowerCase());
        } else {
          const index = skintonesSelected.indexOf(value);
          if (index !== -1) {
            skintonesSelected.splice(index, 1);
          }
        }
      });
    }
  
    const params = queryString.stringify({
      ...(placement?.value.length && {
        p: [placement?.value.toLowerCase()],
      }),
      ...(style?.value.length && {
        s: [style?.value.toLowerCase()],
      }),
      ...(skintonesSelected.length && {
        t: skintonesSelected,
      }),
      ...(size?.value.length && {
        z: [size?.value.toLowerCase()],
      }),
    }, {arrayFormat: 'bracket'});
    const response = await fetch(`${apiUrl}/api/lb/init/${total}?${params}`, {
      method: 'GET',
    });
    return response ? await response.json() : false;
  };

  const onResize = () => {
    if (lastMobile !== isMobile()) {
      resetColumns();
      imageColumnHeights = [];
      loadImages(images);
      lastMobile = isMobile();
    }
    setHeight();
    if (wzoom) wzoom.prepare();
  };

  const setHeight = () => {
    unSetHeight();
    if (isEnd) return;
    let { column: columns } = choozy(container);
    const height = container.getBoundingClientRect().height;
    container.style.height = `calc(${height}px - 100vw)`;
    footer.style.marginTop = `-${footer.getBoundingClientRect().height}px`;
    if (columns?.length) {
      columns.forEach((column, i) => {
        imageColumnHeights[i] = column.getBoundingClientRect().height;
      });
    }
  };

  const unSetHeight = () => {
    container.style.height = 'auto';
    footer.style.marginTop = 'auto';
  };

  const imageLoaded = () => {
    setHeight();
    container.classList.remove('is-loading');
  };

  const selectFilter = (el) => {
    const data = el?.target?.type === 'checkbox' ? {
      name: el?.target?.dataset?.group,
      value: el?.target?.value,
    } : {
      name: el?.target?.name,
      value: el?.target?.value,
    };
    trackHeapEvent('Lookbook - Filter', data);
    resetImages();
  };

  const resetImages = () => {
    container.classList.add('is-loading');
    container.innerHTML = '';
    images = [];
    imageColumnHeights = [];
    loadMore();
    window.scrollTo(0, 0);
  };

  const resetColumns = () => {
    container.innerHTML = ``;
    const desktopColumnWidths = ['w23', 'w28', 'w23', 'w28'];
    for (let i = 1; i <= cols(); i++) {
      const width = isMobile() ? `w50` : desktopColumnWidths[i-1];
      container.innerHTML += `<div class="lookbook-column x df fc bg-black ${width} js-column"></div>`;
    }
  };

  const zoomModalIn = () => {
    wzoom.zoomUp();
  };

  const zoomModalOut = () => {
    wzoom.zoomDown();
  };

  const loadImages = (imageData) => {
    let { column: columns } = choozy(container);
    if (!columns) {
      resetColumns();
      columns = choozy(container).column;
    }
    const imageColumns = chunkArray(imageData, Math.ceil(imageData.length/cols()));

    // shift images across columns to compensate for column height differences
    // move 1 for every difference greater than 1/2 of the screen height
    const shortestColumn = Math.min(...imageColumnHeights);
    const tallestColumn = Math.max(...imageColumnHeights);
    const shortColumn = imageColumnHeights.indexOf(shortestColumn);
    const tallColumn = imageColumnHeights.indexOf(tallestColumn);
    if (imageColumns[tallColumn]?.length) {
      for (let i = 1; i <= Math.floor((tallestColumn-shortestColumn)/(window.innerHeight/2)); i++) {
        const lastImage = imageColumns[tallColumn].pop();
        if (imageColumns[shortColumn]) imageColumns[shortColumn].push(lastImage);
      }
    }

    columns.forEach((column, i) => {
      let newImageHTML = '';
      if (imageColumns[i]?.length) {
        imageColumns[i].forEach(image => {
          if (image?.thumbnail) {
            newImageHTML += `
              <button class="js-item bb bw2">
                <img class="db x pen js-image" src="${image.thumbnail}" data-src="${image.fullsize}">
              </button>
            `;
          }
        });
        column.insertAdjacentHTML('beforeend', newImageHTML);
      }
    });
    const { image, item } = choozy(container);
    on(image, 'load', imageLoaded);
    on(item, 'click', el => {
      const { image } = choozy(el.target);
      openModal(image.getAttribute('data-src'));
    });
  };

  const modalLoaded = () => {
    const scaledWidth = Math.round(modalImage.getBoundingClientRect().width);
    const scaledHeight = Math.round(modalImage.getBoundingClientRect().height);
    modalImageContainer.style.width = `${scaledWidth}px`;
    modalImageContainer.style.height = `${scaledHeight}px`;
    modalContent.style.width = `${scaledWidth}px`;
    modalCaption.classList.remove('dn');
    modalZoom.classList.remove('dn');
    modalImage.style.maxWidth = 'none';
    modalImage.style.maxHeight = 'none';
    const unscaledWidth = modalImage.getBoundingClientRect().width;
    const percentScaled = (unscaledWidth/scaledWidth)/retinaScalePercent;
    modalImageContainer.classList.add('df');
    wzoom = WZoom.create(modalImage, {
      width: modalImage.getBoundingClientRect().width,
      height: modalImage.getBoundingClientRect().height,
      maxScale: percentScaled,
      speed: 1.25,
      dragScrollableOptions: {
        onGrab: function () {
          modalImage.style.cursor = 'grabbing';
        },
        onDrop: function () {
          modalImage.style.cursor = 'grab';
        }
      }
    });
  };

  const openModal = (src) => {
    modalImageContainer.classList.remove('df');
    modalImage.setAttribute('src', src);
    if (save) {
      save.setAttribute('href', src);
      save.setAttribute('download', src.split('\\').pop().split('/').pop());
    }
    modal.classList.remove('dn');
    modalZoom.classList.add('dn');
    modalCaption.classList.add('dn');
    modalContent.style.width = ``;
  };

  const closeModal = () => {
    modalContent.style.removeProperty('width');
    modalImageContainer.style.removeProperty('width');
    modalImageContainer.style.removeProperty('height');
    modalImage.style.removeProperty('max-width');
    modalImage.style.removeProperty('max-height');
    modalImage.setAttribute('src', '');
    modal.classList.add('dn');
    if (wzoom) {
      wzoom.destroy();
      wzoom = null;
    }
  };

  const loadMore = async (e) => {
    if (e) e.preventDefault();
    isEnd = false;
    const newImages = await getImages(totalImages);
    footer.classList.remove('dn');
    noResults.classList.add('dn');
    end.classList.add('dn');

    // No results
    if (!images.length && !newImages?.length) {
      container.classList.remove('is-loading');
      noResults.classList.remove('dn');
      footer.classList.add('dn');
      isEnd = true;
      return;
    }

    // End of results
    if (!newImages?.length || newImages.length < totalImages) {
      end.classList.remove('dn');
      footer.classList.add('dn');
      isEnd = true;
    }

    // No more results
    if (!newImages?.length) {
      container.classList.remove('is-loading');
      return;
    }
    images.push(...newImages);
    loadImages(newImages);
  };

  on(window, 'resize', onResize);
  on(loadMoreBtn, 'click', loadMore);
  on(modalClose, 'click', closeModal);
  on(modalBg, 'click', closeModal);
  on(modalImage, 'load', modalLoaded);
  on(modalZoomIn, 'click', zoomModalIn);
  on(modalZoomOut, 'click', zoomModalOut);
  if (refresh) on(refresh, 'click', resetImages);
  if (placement) on(placement, 'change', selectFilter);
  if (style) on(style, 'change', selectFilter);
  if (size) on(size, 'change', selectFilter);
  if (skintones) {
    skintones.querySelectorAll('input[type=checkbox]').forEach(input => {
      on(input, 'change', selectFilter);
    });
  }

  loadMore();
});
