import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import flickity from 'flickity';
import app from '@/app';
require('flickity-imagesloaded');

export default component((node, ctx) => {
  let { carousel, count, countMobile, flickPrev, flickNext } = choozy(node);
  let data = carousel && carousel !== 'undefined' ? carousel.getAttribute('data-flickity') : false;
  data = JSON.parse(data);
  let flkty;

  if (data) {

    flkty = new flickity(carousel, data);

  } else if(carousel && carousel !== 'undefined') {

    flkty = new flickity(carousel, {
      cellAlign: 'center',
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      lazyLoad: true,
      imagesLoaded: true,
      autoPlay: carousel.getAttribute('data-autoplay') ? carousel.getAttribute('data-autoplay') : false,
      on: {
        change: function (index) {
          let allBody = node.querySelectorAll('.js-carouselContainer .js-body');
          let curBody = node.querySelector(
            '.js-carouselContainer .js-body[data-index="' + (index + 1) + '"]',
          );
          allBody.forEach((body) => {
            body.classList.remove('db');
            body.classList.add('dn');
          });
          curBody.classList.remove('dn');
          curBody.classList.add('db');
          let cellNumber = flkty.selectedIndex + 1;
          if (count) {
            count.innerHTML = `<sup>${cellNumber}</sup>/<sub>${flkty.slides.length}</sub>`;
          }
          if (countMobile) {
            countMobile.innerHTML = `<sup>${cellNumber}</sup>/<sub>${flkty.slides.length}</sub>`;
          }
        },
      },
    });

    function updateStatus() {
      var cellNumber = flkty.selectedIndex + 1;
      if (count) {
        count.innerHTML = `<sup>${cellNumber}</sup>/<sub>${flkty.slides.length}</sub>`;
      }
      if (countMobile) {
        countMobile.innerHTML = `<sup>${cellNumber}</sup>/<sub>${flkty.slides.length}</sub>`;
      }
    }

    on(flickPrev, 'click', () => {
      flkty.previous(true);
      let cellNumber = flkty.selectedIndex + 1;
      if (count) {
        count.innerHTML = `<sup>${cellNumber}</sup>/<sub>${flkty.slides.length}</sub>`;
      }
      if (countMobile) {
        countMobile.innerHTML = `<sup>${cellNumber}</sup>/<sub>${flkty.slides.length}</sub>`;
      }
    });

    on(flickNext, 'click', () => {
      flkty.next(true);
      let cellNumber = flkty.selectedIndex + 1;
      if (count) {
        count.innerHTML = `<sup>${cellNumber}</sup>/<sub>${flkty.slides.length}</sub>`;
      }
      if (countMobile) {
        countMobile.innerHTML = `<sup>${cellNumber}</sup>/<sub>${flkty.slides.length}</sub>`;
      }
    });

    updateStatus();
  }

  if (flkty) {
    flkty.resize();

    app.on('transition', () => {
      flkty.resize();
      setTimeout(() => {
        flkty.resize();
      }, 500);
    });
  }
});
