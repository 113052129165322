import { component } from 'picoapp';
import Drift from 'drift-zoom';
import choozy from 'choozy';
import { on } from '@selfaware/martha';

export default component((node) => {
  const images = [...node.querySelectorAll('.js-zoom-img')];
  const { zoom, scrollContainer } = choozy(node);
  let driftImage, lastScrollTime;

  const createZoomContainer = (el) => {
    const zooms = [...el.querySelectorAll('[data-zoom]')];

    zooms.forEach((image) => {
      driftImage = new Drift(image, {
        inlineContainer: zoom,
        containInline: true,
        inlinePane: true,
        zoomFactor: 7,
        hoverDelay: 400,
        touchDelay: 400,
        onShow: () => {
          node.classList.add('is-clicked');
          image.classList.add('is-active');
        },
        onHide: () => {
          image.classList.remove('is-active');
        },
      });

      on(image, 'contextmenu', disablePinch);
      on(image, 'dragstart', disablePinch);
      on(image, 'touchstart', disablePinch);
      on(image, 'ontouchmove', disablePinch);
      on(image, 'touchforcechange', disablePinch);
    });
  };

  const disablePinch = e => {
    if(e.touches?.length > 1) {
      e.preventDefault(); 
      return false;
    }
  };
  
  const isScrolling = (e) => {
    lastScrollTime = new Date().getTime();
  };

  on(window, 'scroll', isScrolling);
  if (scrollContainer) {
    on(scrollContainer, 'scroll', isScrolling);
  }

  const handler = (changes, observer) => {
    changes.forEach((change) => {
      if (change.isIntersecting) {
        node.classList.add('is-in-view');
      }
    });
  };

  let observer = new IntersectionObserver(handler);
  observer.observe(node);

  images.map(createZoomContainer);
});
