const API_URL = process.env.BACKEND_API_URL;

/**
 * Create Reservation API endpoint
 * @param {Object} data - reservation data
 * @param {string} data.email - reservation email
 * @param {string} data.l - reservation location
 * @param {string} data.tf_id - reservation typeform id
 * @param {string} data.phone_number - reservation phone number
 * @param {Object} data.design - reservation additional design information that will be saved in tattoo plan
 * @returns {void}
 */
export const createReservation = async (data = {}) => {
  const opts = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(`${API_URL}/api/reservation`, opts);
  const responseData = await response.json();

  return responseData;
};

/**
 * Updates Reservation API endpoint
 * @param {string} rpId - reservation uuid needed to patch reservation
 * @param {Object} data - reservation data
 * @param {string} data.email - reservation email
 * @param {string} data.l - reservation location
 * @param {string} data.tf_id - reservation typeform id
 * @param {string} data.phone_number - reservation phone number
 * @param {string} data.setup_intent_id - reservation stripe setup intent id that we receive when saving a customer's credit card
 * @param {Object} data.design - reservation additional design information that will be saved in tattoo plan
 * @returns {void}
 */
export const updateReservation = async (rpId, data = {}) => {
  const opts = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(`${API_URL}/api/reservation/${rpId}`, opts);
  const responseData = await response.json();

  return responseData;
};
