import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import app from '@/app';

export default component((node, ctx) => {
  const { select, collection1 } = choozy(node);
  let activeCollection = collection1;

  if (select && activeCollection) {
    on(select, 'change', (e) => {
      const collection = e.target.value;
      activeCollection.classList.add('dn');
      activeCollection = choozy(node)[collection];
      activeCollection.classList.remove('dn');
      app.emit('transition');
    });
  }
});
