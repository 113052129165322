import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';

export default component((node, ctx) => {
  let { logoOverlay, logoDrop } = choozy(node);
  let idleTime = 0;

  let randomItem = logoDrop[Math.floor(Math.random() * logoDrop.length)];

  logoDrop.forEach((logo) => {
    logo.style.display = 'none';
  });
  randomItem.style.display = 'block';

  ctx.hydrate({ idle: 0 });

  const timerIncrement = () => {
    idleTime = idleTime + 1;
    if (idleTime > 10) {
      logoOverlay.classList.remove('o0');
    }
  };

  const setIdle = () => {
    const idleTimer = setInterval(timerIncrement, 1000);
    if (document.documentElement.clientWidth > 850) {
      on(document, 'mousemove', () => {
        logoOverlay.classList.add('o0');
        idleTime = 0;
      });
    }
    on(window, 'scroll', () => {
      logoOverlay.classList.add('o0');
      idleTime = 0;
    });
    ctx.on('resize', ({ ww }) => {
      if (ww < 850) {
        clearInterval(idleTimer);
        logoOverlay.classList.add('o0');
      }
    });
  };

  if (window.location.pathname === '/') {
    logoOverlay.classList.remove('o0');
    on(window, 'scroll', () => {
      if (window.pageYOffset > 200 && ctx.getState().idle != 1) {
        ctx.hydrate({ idle: 1 });
        setIdle();
      }
    });
  } else {
    ctx.hydrate({ idle: 1 });
    setIdle();
  }
});
