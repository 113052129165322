import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';

export default component((node) => {
  const { overlay, quickLookBtn } = choozy(node);

  let modalElm;
  let closeBtnElm;

  // Show Modal and Overlay
  const openPopup = (item) => {
    const itemId = item.getAttribute('data-productid');
    modalElm = document.getElementById(itemId);

    // Display Modal & Overlay
    modalElm.classList.remove('hidden');
    overlay.classList.remove('hidden');

    // Add Event Listener To Modal's Close Button
    closeBtnElm = modalElm.querySelector('.close-btn');
    on(closeBtnElm, 'click', closePopup);
  };

  // Hide Modal and Overlay
  const closePopup = () => {
    modalElm.classList.add('hidden');
    overlay.classList.add('hidden');
  };

  if (quickLookBtn) {
    quickLookBtn.forEach((item) => {
      on(item, 'click', () => openPopup(item));
    });
  }

  if (overlay) {
    on(overlay, 'click', closePopup);
  }
});
