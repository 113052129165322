import app from '@/app';
import choozy from 'choozy';
import { on } from '@selfaware/martha';
import { component } from 'picoapp';
import { buildLocationCartServices, addToCart, getCart, removeServiceInCart, removeAndAddServiceInCart, getAvailableDates } from '@/util/boulevard';
import { 
  loader,
  slugify,
  trackEvent,
  trackHeapEvent
} from '@/util/utils';
import flickity from 'flickity';

export const getService = (state, name) => {
  if (!name?.length) return false;
  let services = choozy(state.node).service;
  const service = services.find(el => slugify(el.getAttribute('data-name')) === slugify(name));
  return service ? {
    index: service,
    title: service.getAttribute('data-title'),
    price: service.getAttribute('data-price'),
    description: service.innerHTML,
    imageUrl: service.getAttribute('data-image-url'),
    imageLqip: service.getAttribute('data-image-lqip'),
    show: service.getAttribute('data-show') === 'true',
    ctaText: service.getAttribute('data-cta-text'),
    ctaUrl: service.getAttribute('data-cta-url'),
  } : false;
};

export default component((node, ctx) => {
  let serviceMessage, showGalleryInServices;

  const header = ctx.getState().node.dataset.serviceHeader;
  const servicesGalleryTabText = ctx.getState().node.dataset.servicesGalleryTabText;
  const servicesGallery = choozy(ctx.getState().node).servicesGallery;
  const servicesImageURLs = JSON.parse(ctx.getState().node.dataset.servicesImages);

  const state = ctx.getState();
  const choices = choozy(ctx.getState().node).choice;
  const choiceEl = choices.find((choice) => JSON.parse(choice.dataset.choice)._key === state.choice);
  
  if (choiceEl) {
    serviceMessage = choiceEl;
    showGalleryInServices = choiceEl.getAttribute('data-show-gallery-in-service') === 'true';
  }

  let servicesGalleryActive = 0;
  let servicesGalleryOpen = false;

  let view = 'loading';

  const getServiceByName = (name) => {
    const { services } = ctx.getState();
    if (!name || !services?.length) return {};
    const service = services.find((service) => service.name.toLowerCase() === name.toLowerCase());

    return service || {};
  };

  const initCart = async () => {
    const { location, cartId, services } = ctx.getState();

    // Cart already exists in state (e.g user gets back from week)
    if (cartId && services?.length) {
      return { cartId, services };
    }

    const cart = await buildLocationCartServices(location?.id);
    if (!cart || !cart?.services?.length) return null;
    const selectableServices = cart.services.filter(service => getService(ctx.getState(), service?.name));
    const servicesNode = choozy(state.node).service;

    selectableServices.sort((a, b) => {
      return servicesNode.findIndex(el => el.getAttribute('data-name') === a.name) - servicesNode.findIndex(el => el.getAttribute('data-name') === b.name);
    });

    ctx.hydrate({
      services: selectableServices,
      cartId: cart.cartId,
    });

    return cart;
  };

  const updateCart = async (serviceID) => {
    const { cartId } = ctx.getState();
    let cart = await getCart(cartId);
    const cartHasServices = cart?.data?.cart && cart?.data?.cart?.selectedItems && cart?.data?.cart?.selectedItems?.length;

    if (cartHasServices) {
      if (cart?.data.cart.selectedItems.length > 1) {
        cart?.data.cart.selectedItems.forEach(async ({ id }) => {
          if (id) cart = await removeServiceInCart(cartId, id);
        });
        cart = await addToCart(cartId, serviceID);
      } else if (cart?.data?.cart?.selectedItems[0]?.id) {
        cart = await removeAndAddServiceInCart(cartId, cart?.data?.cart?.selectedItems[0]?.id, serviceID);
      }

    } else {
      cart = await addToCart(cartId, serviceID);
    }
    ctx.hydrate({
      cartServiceId: cart?.data?.addCartSelectedBookableItem?.cart?.selectedItems[0]?.id
    });
  };

  const updateService = async (e) => {
    e.preventDefault();
    const state = ctx.getState();
    const serviceName = e.target.getAttribute('data-service');
    const service = getService(state, serviceName);
    const serviceID = e.target.value;
    state.service = { name: serviceName, title: service.title, ID: serviceID, price: service.price, ctaText: service.ctaText, ctaUrl: service.ctaUrl };
    state.time = null;
    state.bookableDates = null;
    state.week = undefined;
    state.day = undefined;
    state.view = 'week';
    trackEvent('booking', `${state.view}`, `click service ${state.service.name}`);
    trackHeapEvent(`Clicked - Book-Now - Product ${state.service.name}`);

    view = 'loading';
    ctx.emit('bookingServiceUpdate');

    await updateCart(serviceID);
    ctx.emit('bookingUpdateView', state);
  };

  const updateServicesGallery = (e, i = -1) => {
    const { servicesGallery, serviceGallery } = choozy(node);
    const state = ctx.getState();
    let index = e?.target?.value || i;
    if (index === -1) {
      serviceGallery.forEach((service, i) => {
        if (service?.dataset?.default === 'true') {
          index = i;
        }
      });
    }
    if (index === -1) {
      index = 0;
    }

    servicesGallery.classList.remove(`is-open--${servicesGalleryActive}`);
    servicesGalleryActive = parseInt(index);
    servicesGallery.classList.add(`is-open--${servicesGalleryActive}`);

    let activeServiceGallery;
    serviceGallery.forEach((service, i) => {
      if (servicesGalleryActive === i) {
        activeServiceGallery = choozy(service).servicesGalleryImages;
      }
    });

    if (activeServiceGallery) {
      const flkty = new flickity(activeServiceGallery, {
        pageDots: true,
        prevNextButtons: false,
        resize: true,
        contain: false,
        cellAlign: 'center',
        percentPosition: '100%',
        on: {
          change: function (index) {
            trackHeapEvent(`Clicked - Book-Now - Product ${state.services[servicesGalleryActive].name} Slide ${(index+1)}`);
          }
        }
      });
      triggerScroll();
    }
    servicesGallery.style.top = getServicesGalleryHeight();
  };

  const getServicesGalleryHeight = e => {
    const { servicesGallery } = choozy(node);
    return `calc(100% - ${servicesGallery.getBoundingClientRect().height}px + 7rem`;
  };

  const toggleServicesGallery = e => {
    const { servicesGallery } = choozy(node);
    if (servicesGalleryOpen) {
      servicesGalleryOpen = false;
      servicesGallery.style.top = ``;
      servicesGallery.classList.remove('is-open');
    } else {
      servicesGalleryOpen = true;
      servicesGallery.classList.add('is-open');
      updateServicesGallery();
      servicesGallery.style.top = getServicesGalleryHeight();
    }
  };
  
  const triggerScroll = () => {
    var resizeEvent = window.document.createEvent('UIEvents');
    resizeEvent.initUIEvent('scroll', true, false, window, 0);
    window.dispatchEvent(resizeEvent);
  };

  const onMount = () => {
    const { serviceSelector, selectServicesGallery, servicesGalleryOpenBtn } = choozy(node);

    if (!serviceSelector) return;

    on(serviceSelector, 'click', updateService);

    if (showGalleryInServices) {
      on(selectServicesGallery, 'click', updateServicesGallery);
      on(servicesGalleryOpenBtn, 'click', toggleServicesGallery);
    }
  };

  const render = (state) => {
    if (!state.services || view === 'loading') {
      node.innerHTML = loader;
      return;
    }
    
    // Filter show services to options matching group
    const choiceServices = state.choiceServices;
    const services = !choiceServices ? state.services : state.services.filter(service => choiceServices.includes(service.name));

    const closeBtn = `<span class="vam pt5">Close</span><img src="/assets/icons/close.svg" class="vam" width="25" />`;
    node.innerHTML = `
      <div class="df mha y x">
        <div class="pt40 pb80 max-w400 mha m:w50 y x">
          ${choiceServices?.length > 0 ? `
            <h1 class="f24 light pb20">${header}</h1>
          ` : ``}
          <div class="ph10 max-w400 mha y">
            <div class="df fc">
              ${services.map(({ name, id }) => {
                const service = getService(state, name);
                return service?.show ? `
                  <div class="service-block mb10" style="order: ${service.index};">
                    <button id="${`service${id}`}" name="serviceID" value="${id}" data-service="${name}" class="df jcb aic x mb10 tl bg-bud bc-broccolini ba bw1 br5px js-serviceSelector">
                      <div class="w40 df ais br bw1 bc-broccolini pen">
                        <div class="lazy oh r x square" data-id="${`serviceimage${id}`}" data-component="lazy">
                          <span class="sold-out-message f32 m:f58 fw700">Sold Out</span>
                          <div class="lazy__lqip ro js-lqip" aria-hidden="true">
                            <img class="x y o-cover" src="${service.imageLqip}">
                          </div>
                          <div class="ro">
                            <img class="lazy__img x y o-cover bg-gray o0 js-img" data-srcset="${service.imageUrl}" data-src="${service.imageUrl}" alt="">
                          </div>
                        </div>
                      </div>
                      <div class="pv10 ph15 lh140 w60 pen service-info">
                        <div class="f18 fw700">${service.title}</div>
                        <div class="f14 c-broccolini">${service.description}</div>
                      </div>
                      <img src="/assets/icons/arrow-next.svg" width="15" class="mh15 pen" />
                    </button>
                  </div>
                ` : '';
              }).join('')}
            </div>

            ${serviceMessage?.innerText?.length ? `
              <div class="df aic f14 tl bg-white ba bw1 p10 mt30">
                <img src="/assets/icons/smile.svg" class="mr10 vas" width="25"/>
                <div class="rte">${serviceMessage.innerHTML}</div>
              </div>
            ` : ``}
          </div>
        </div>
        <div class="dn m:df fw m:w50 x rel">
          ${servicesImageURLs.map(({url, lqip}) => {
            return `
              <div class="rel w50 bl bb bc-broccolini bw3">
                <div class="abs zn1 x y top left bottom right bg-black" style="background: url('${lqip}') center center / cover no-repeat;"></div>
                <div class="abs z0 x y top left bottom right bg-black" style="background: url('${url}') center center / cover no-repeat;"></div>
              </div>
            `;
          }).join('')}
        </div>
      </div>

      ${showGalleryInServices && servicesGallery?.length ? `
        <div class="js-servicesGallery services-gallery mh10 m:mr0 m:ml0 m:w50">
          <div class="services-gallery-inner bg-clay pb80 br30 tc f16 max-w400 mha">
            <button class="mono ttu ph30 pv10 js-servicesGalleryOpenBtn"><span class="services-gallery-btn services-gallery-btn--close">${closeBtn}</span><span class="services-gallery-btn services-gallery-btn--open">${servicesGalleryTabText}</span></button>
            ${servicesGallery.map((el, i) => {
              const servicesGalleryDefault = el.dataset.default;
              const servicesGalleryDescription = choozy(el).servicesGalleryDescription;
              const servicesGalleryCaption = choozy(el).servicesGalleryCaption;
              const servicesGalleryImages = choozy(el).servicesGalleryImages;
              const images = servicesGalleryImages?.dataset?.images?.length ? JSON.parse(servicesGalleryImages.dataset.images) : [];
              
              return `
                <div class="ph30 pv10 services-gallery-service services-gallery-service--${i} js-serviceGallery" data-default="${servicesGalleryDefault}">
                  <div class="rte mb15">${servicesGalleryDescription.innerHTML}</div>
                  <div class="mb15 rel oh js-servicesGalleryImages">
                    ${images.map(image => {
                      return `
                      <div class="x df ais ba bw1 bc-black">
                        <div class="lazy oh r x square" data-component="lazy">
                            <div class="lazy__lqip ro js-lqip" aria-hidden="true">
                              <img class="x y o-cover" src="${image.lqip}">
                            </div>
                            <div class="ro">
                              <img class="lazy__img x y o-cover bg-gray o0 js-img" data-srcset="${image.url}" data-src="${image.url}" alt="">
                            </div>
                        </div>
                      </div>
                      `;
                    }).join('')}
                  </div>
                  <div class="mb15">
                    ${servicesGallery.map((el, ii) => {
                      const title = el.dataset.title;
                      return `
                        <button class="dib mono ttu tdu ${ii > 0 ? 'pl10' : ''} ${ii < servicesGallery.length-1 ? 'pr10' : ''} fw600 services-gallery-service-link services-gallery-service-link--${ii} js-selectServicesGallery" value="${ii}">${title}</button>
                      `;
                    }).join('')}
                  </div>
                  <div class="rte">${servicesGalleryCaption.innerHTML}</div>
                </div>
                `;
            }).join('')}
          </div>
        </div>
      ` : ``}
    `;
    onMount();
  };

  ctx.on('bookingServiceUpdate', (state) => {
    render(state);
    ctx.emit('bookingMountComponents');
    app.emit('appUpdate'); // required to use lazy loader since including in this bundle creates unexpected bugs
    ctx.emit('bookingResize');
  });

  const init = async () => {
    ctx.emit('bookingServiceUpdate');
    const { initalized } = ctx.getState();
    const cart = await initCart();
    const URLParams = new URLSearchParams(window.location.search);
    const serviceParam = URLParams.get('s')?.length ? decodeURI(URLParams.get('s')) : null;
    const serviceBlvd = serviceParam ? getServiceByName(serviceParam) : {};
    const serviceSanity = serviceParam ? getService(state, serviceParam) : {};
    const service = {
      ...serviceBlvd,
      ...serviceSanity
    };

    // Skip view during initalization for services in params, or always for hidden services 
    if ((service?.id && !initalized) || (service?.id && !service.show)) {
      await updateCart(service.id);

      ctx.hydrate({
        service: {
          ID: service.id,
          name: service.name,
          title: service.title,
          price: service.price,
          ctaUrl: service.ctaUrl,
          ctaText: service.ctaText,
          fromURL: true,
        },
      });
      const { view } = ctx.getState();
      trackEvent('booking', `${view}`, `url param redirect`, serviceParam);

      ctx.emit('bookingUpdateView', { view: 'week' });
      return;
    }

    // Typical flow without a service param
    if (!cart) {
      ctx.emit('bookingUpdateView', { view: 'error' });
      return;
    }

    view = 'loaded';
    ctx.emit('bookingServiceUpdate');
  };

  init();
});
